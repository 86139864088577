.slider {
    position: relative;
    padding: 0 50px;

    &__wrapper {
        position: relative;
        width: 100%;
        overflow: hidden;
    }

    &__items {
        display: flex;
        
        &_animated {
            transition: transform .7s;
        }
    }

    &__item {
        flex: 0 0 33.333333%;
        height: 100%;

        @include when(tablet) {
            flex: 0 0 50%;
        }

        @include when(mobile) {
            flex: 0 0 100%;
        }
    }

    &__control {
        position: absolute;
        opacity: 0.75;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        margin-top: -25px;
        top: 50%;
        line-height: 50px;
        text-align: center;

        &:hover {
            background-color: $LightGrey;
        }

        &_prev {
            left: 0;
            padding-right: 3px;
        }

        &_next {
            right: 0;
            padding-left: 3px;
        }

        &_inactive {
            opacity: 0.2;
            pointer-events: none;
            background-color: $White !important;
        }

        .fa, .fa::before {
            display: block;
            line-height: 50px;
            vertical-align: middle;
        }
    }

    &__imageWrapper {
        display: block;
        margin: 0 10px;
    }

    &__image {
        display: block;
        width: 100%;
    }
}

// .slider {
//     width: 100%;
//     position: relative;

//     &_banner {
//         @extend .slider;
//         max-width: 1280px;
//         margin: auto;
//     }
//     &_tab {
//         @extend .slider;
//         background: $White;
//         border: 1px solid $LightGrey;
//         height: 400px;
//         @include breakpoint($RWD-A) {
//             height: 212px;
//         }
//     }
//     &_banner-wrapper{
//         @extend .slider;
//         // background: rgba(0, 0, 0, 0.6);
//         padding: 5px 0px;
//     }
//     &__center {
//         overflow: hidden;
//         white-space: nowrap;
//         width: 100%;
//         // max-width: 972px;
//         padding: 0 70px;
//         margin: auto;
//     }

//     &__left {
//         float: left;
//         overflow-y: scroll;
//         width: 20%;
//         height: 100%;
//         @include breakpoint($RWD-A) {
//             display: none;
//         }
//     }
//     &__right {
//         overflow: hidden;
//         position: relative;
//         white-space: nowrap;
//         float: left;
//         width: 80%;
//         border-left: 1px solid #E6E7E7;
//         height: 100%;
//         @include breakpoint($RWD-A) {
//             width: 100%;
//         }
//     }
//     &__wrapper {
//         transition-duration: 0.5s;
//         transform: translateX(0);
//         font-size: 0;
//         line-height: 0;
//         height: 100%;
//     }
//     &__item{
//         position: relative;
//         display: inline-block;
//         vertical-align: top;
//         width: 100%;
//         height: 100%;
//     }

//     &__inner-item{
//         &_tab{
//             top: 50%;
//             left: 50%;
//             transform: translate(-50%, -50%);
//             position: absolute;
//         }
//         &_banner{
//             margin: auto;
//             // max-width: 978px;
//         }
//     }

//     &__controls{
//         position: absolute;
//         top: 0;
//         height: 100%;
//         width: 100px;
//         &:hover{
//             background: rgba(230,231,231,0.15);
//             height: 100%;
//             border-radius: 50%;
//         }
//         &_next{
//             @extend .slider__controls;
//             right: 0;
//             right: -50px;
//         }
//         &_prev{
//             @extend .slider__controls;
//             left: 0;
//             left: -50px;
//         }
//     }
//     &__controls-banner{
//         position: absolute;
//         top: 0;
//         opacity: 0.75;
//         // color: $White;
//         border-radius: 50%;
//         // background: rgba(255, 255, 255, 0.4);
//         width: 2.5em;
//         height: 2.5em;
//         position: absolute;
//         top: 50%;
//         transform: translateY(-50%);
//         &:hover {
//             background: rgba(0, 0, 0, 0.1);
//         }
//         &_next{
//             @extend .slider__controls-banner;
//             right: 16px;
//             & i {
//                 padding-left: 0.29em;
//                 position: absolute;
//                 top: 50%;
//                 transform: translateY(-50%);
//             }
//         }
//         &_prev{
//             @extend .slider__controls-banner;
//             left: 10px;
//             & i {
//                 padding-left: 0.2em;
//                 position: absolute;
//                 top: 50%;
//                 transform: translateY(-50%);
//             }
//         }
//     }
//     &__arrow{
//         color: $color-secondary;
//         opacity: 0.75;
//         position: absolute;
//         top: 50%;
//         margin-top: -16px;
//         &_next{
//             @extend .slider__arrow;
//             right: 65%
//         }
//         &_prev{
//             @extend .slider__arrow;
//             left: 65%
//         }
//     }
//     &__thumbnail{
//         border-bottom: 1px solid $LightGrey;
//         border-left: 0px solid $MiddleGrey;
//         transition: border-left 0.2s;
//         cursor: pointer;
//         padding: 6px 12px;
//         position: relative;
//         width: 100%;
//         height: 80px;
//     }
//     &__thumbnail.slider__active{
//         border-left: 4px solid $MiddleGrey;
//     }
//     &__overlay.slider__active{
//         background: rgba(230,231,231,0.15);
//     }
//     &__overlay{
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 100%;
//         &:hover{
//             background: rgba(230,231,231,0.15);
//         }
//     }
//     &__image{
//         max-width: 100%;
//         max-height: 100%;
//         &_tab{
//             @extend .slider__image;
//             top: 50%;
//             left: 50%;
//             transform: translate(-50%, -50%);
//             position: absolute;
//         }
//         &_banner{
//             @extend .slider__image;
//             max-width: 33.3333%;
//             padding: 0 10px;

//             @include when(tablet) {
//                 max-width: 50%;
//             }

//             @include when(mobile) {
//                 max-width: 100%;
//             }
//         }
//     }
//     &__imageContainer{
//         position: fixed;
//         left: 0;
//         right: 0;
//         top: 50%;
//         transform: translateY(-50%);
//         z-index: 10;
//     }
//     &__imageFull{
//         display: block;
//         margin: 0 auto;
//         background: $White;
//         border: 3px solid $White;
//     }
// }
