.paging_controls {
    margin-top: 15px;

    .left_arrow,
    .right_arrow {
        float: left;
        display: block;
        padding: 0 10px;
    }

    .pageControlMenu {
        float: left;
    }

    .pageControl {
        a {
            display: inline-block;
            padding: 0 3px;

            &.selected {
                font-weight: 600;
                text-decoration: underline;
            }
        }
    }

}