.ui-datepicker{
    @include border-radius(3px);
    background: $White;
    border: 1px solid $standard-input-border;
    position: absolute;
    font-size: 1em;
    // top: -21px !important;
    // left: 0 !important;
    z-index: 9 !important;
    width: 285px;
    padding: 8px 12px 6px 12px;
}
.ui-datepicker-header {
    border-bottom: 1px solid $color-border-primary;
    color: #333333;
    font-weight: bold;
    padding: 0 0 .3em 0;
    margin: 0 -.7em;
}

.ui-datepicker-title {
    margin: 0 2.3em;
    line-height: 1.8em;
    text-align: center;
}

.ui-datepicker-calendar{
    width: 100%;
    font-size: .9em;
    border-collapse: collapse;
    margin: 0 0 .4em;
}

.ui-state-default {
    display: block;
    height: 100%;
    text-align: center;
    padding-top: 8px;
}

.ui-datepicker td {
    margin-top: 1px;
    margin-right: 1px;
    height: 37px;
    width: 36px;
    font-weight: normal;
    //color: #454545;
    border: 1px solid $color-border-primary;
    cursor: pointer;
}

.ui-datepicker-other-month  {
    background: darken($White, 3%);
    color: lighten($Black, 75%);
    line-height: 36px;
}

.ui-datepicker-current-day {
    position: relative;
    background: darken($White, 3%);
}

.ui-state-active {
    background: $color-button-secondary;
    border-radius: 50%;
    color: $White;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 75%;
    width: 75%;
    padding-top: 3px;
}

.ui-datepicker-calendar thead > tr > th {
    height: 2.3em;
    vertical-align: middle;
}

.ui-datepicker table {
    font-size: .9em;
    border-collapse: collapse;
}

.ui-datepicker th {
    text-align: center;
    font-weight: bold;
}

.ui-datepicker-prev {
    font-size: 0.8em;
    float: left;
    color: $color-icon-secondary;
    margin-top: 0.2em;
    margin-left: 1em;
}

.ui-datepicker-next {
    font-size: 0.8em;
    float: right;
    color: $color-icon-secondary;
    margin-top: 0.2em;
    margin-right: 1em;
}
.ui-datepicker-week-end {
    color: $ErrorRed;
}

.ui-datepicker-unselectable{
    cursor: default !important;
    color: $ErrorRed;
}
