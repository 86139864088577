.catentry-table{
    &__main{
        position: relative;
        display: flex;
        background: $White;
        flex-flow: column nowrap;
        justify-content:space-between;
        border: 1px solid $color-border-primary;
        margin: -10px -16px;
    }
    &__row {
        display: flex;
        align-items: center;
        &:nth-child(even){
            background: $White;
        }
        &:nth-child(odd){
            background: $color-container-bg;
        }
        &_article{
            @extend .catentry-table__row;
            padding: 4px 0;
            border-bottom: 1px solid $color-border-primary;
            &:last-child{
                border-bottom: none;
            }
        }
    }
    &__item{
        padding-left: 12px;
        flex: 1;
        &_art-nr{
            @extend .catentry-table__item;
            flex: 0 1 95px;
        }
    }
}
