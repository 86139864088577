.marketing-content-grid {
    margin-top: 15px;
    margin-bottom: 15px;

    &__item {
        padding-top: 15px;
        padding-bottom: 15px;

        &__content-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 15% 25px;
            background-size: cover;
            background-repeat: no-repeat;
        }

        &__content {            
            text-align: center;

            h3 {
                color: $White;
                margin-bottom: 10px;
                text-shadow: 0px 2px 6px rgba(0,0,0,.6);
            }

            p {
                color: $White;
                font-size: 18px;
                font-weight: 300;
                margin-bottom: 20px;
                text-shadow: 0px 2px 2px rgba(0,0,0,.6);
            }
        }
    }
}
