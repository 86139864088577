.nav-list {
    &__item {
        margin-bottom: 20px;
    }

    &__title {
        font-size: 14px;
        font-weight: bold;
        text-transform: none;
    }

    &__inner-item {
        margin-bottom: 5px;
        padding-left: 20px;
        font-size: 13px;
    }

    &__text {
        font-size: 13px;
    }
}