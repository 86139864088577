.cms {
	&_assets {
        color: $Orange;

        & a {
            color: $Orange;
            &:hover,
            &:active {
                color: $LightOrange;
            }
        }
    }

    &_body {
        a {
            color: $Orange !important;

            &:hover,
            &:active {
                color: $LightOrange !important;
            }
        }
    }

	&__largeGridItem {
	    border-radius: 10px;
		.h1 {
			color: $White;
		}
	}

	&__largeGridText {
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 0 0 10px 10px;
    }

    &__largeGridReadMore {
        position: absolute;
        bottom: 13px;
        left: 0px;
    }

    &__sideMenuLink {
        &.selected {
            font-weight: bold;
        }
    }

    &__sideMenuItem {

        &_large {
            text-transform: none;
            font-size: 14px;
        }

        &_small {
            margin-bottom: 5px;
            font-size: 13px;
        }
    }

    &__sideMenuGroup_item{
        margin-top: 0px;
    }

    &__devider {
        @include border-dotts(9px 0 0 0);

        &_small {
            @include border-dotts(0 0 7px 0);
        }
    }

    &__generic_body {
        h1, h2, h3, h4 {
            line-height: 1.2;
        }

        p, ul, ol {
            font-weight: $base__font-weight-regular;
        }

        a {
            color: $color-secondary !important;
            font-weight: 400;

            &:hover,
            &:active {
                color: lighten($color-secondary, 20%) !important;
            }
        }
    }

		&__hero-carousel-body {
        h2 {
		        font-size: 45px;
				    line-height: 100%;
            padding-top: 0.5em;
        }
        p {
            font-size: 18px;
            padding-top: 0.55em;
        }
    }

    &__generic_topImage {
        margin-bottom: 20px;
    }

		&__pre-title {
				padding: 0;
				background-color: transparent;
				color: $color-secondary;
				text-shadow: 0px 2px 9px rgba(0,0,0,.3);
				line-height: 0.95em;
		    font-weight: 600;
    }
}
