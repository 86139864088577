.contact-section {
    // width: 50%;
    // float: left;
    // padding: 0 10px;
    margin-top: 20px;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.3;

    a {
        transition: .15s;
        text-decoration: underline;

        &:hover {
            opacity: 0.7;
        }
    }

    .input {
        display: block;
        width: 100%;
        font-weight: 500;
        margin-bottom: 8px;
    }

    select,
    textarea {
        padding: 10px 15px;
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: '';

        &::-ms-expand {
            display: none;
        }
    }

    textarea {
        min-height: 120px;
        resize: vertical;
    }

    &__value {
        position: relative;
        padding-left: 28px;
        margin-bottom: 3px;

        .fa {
            position: absolute;
            left: 0;
            top: 3px;
            color: $color-primary;
        }
    }

    &__address {
        &__title {
            text-transform: uppercase;
            color: $color-primary;
            font-weight: 600;
            margin-bottom: 10px;
            margin-top: 15px;
            line-height: 100%;
        }
    }

    &__info {
        &__title {
            text-transform: uppercase;
            color: $color-primary;
            font-weight: 600;
            font-size: 22px;
            margin-bottom: 2px;
        }
    }
}