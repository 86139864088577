.product{
    width: 100%;
    display: inline-block;
    margin-top: 10px;

    &__header {
        overflow: hidden;
        margin-left: 376px;
        margin-bottom: 5px;
        @include breakpoint($RWD-C) {
            margin: 0 10px 0 10px;
        }
    }
    &__image-container {
        height: 356px;
        display:table-cell;
        vertical-align:middle;
        text-align: center;
    }
    &__list-wrapper {

        margin-right: 25px;
        @include breakpoint($RWD-A) {
            margin-top: 0px;
            margin-right: 0px;
        }
    }
    &__list-item{
        position: relative;
        display: inline-block;
        text-align: left;
        padding-right: 13px;
        &_border {
            @extend .product__list-item;
            border-left: 1px solid $LightGrey;
            padding-left: 14px;
        }
    }
    &__list-image {
        max-height: 100%;
        @include when(mobile) {
            max-height: 150px;
        }
        @include when(tablet) {
            pointer-events: none;
        }
        &_icon{
          @extend .product__list-image;
          max-height: 24px;
          max-width: 28px;
          vertical-align: middle;
        }
    }
    &__image-slider {
        background-color: $LightGrey;
        margin: 0 -10px 10px -10px;

        &_viewer {
            position: fixed;
            top: -100%;
            opacity: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: white;
            z-index: 13000;
            transition: all .4s;
            overflow: hidden;
            cursor: move;

            &.show-viewer {
                top: 0;
                opacity: 1;
            }

            &_zoom {
                position: absolute;
                touch-action: pan-y pan-x;

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    touch-action: pan-y pan-x;
                }
            }

            &_close {
                position: absolute;
                top: 10px;
                right: 10px;
                height: 50px;
                width: 50px;
                border-radius: 100%;
                text-align: center;
                line-height: 50px;
                font-size: 22px;
                background-color: rgba(0,0,0,.4);
                color: white;
                cursor: pointer;
            }
        }
        &_pan {
            touch-action: pan-y;
            user-select: none;
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            overflow: hidden;

            ul {
                display: flex;
                margin-left: calc(50% - 125px);
                padding-top: 10px;
                padding-bottom: 10px;
            }

            &.slider-inactive ul {
                transition: transform .3s ease-out;
            }

            li {
                flex: 0 0 250px;
                text-align: center;
            }

            &_item {
                position: relative;
                margin: 0 5px;
                background-color: white;
                height: 270px;
                line-height: 240px;
                padding: 15px;

                img {
                    display: inline-block;
                    vertical-align: middle;
                    max-height: 100%;
                    max-width: 100%;
                }

                &_spinner {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    margin-left: -20px;
                    margin-top: -20px;
                    width: 40px;
                    height: 40px;
                    padding: 5px;
                    background-color: rgba(0, 0, 0, 0.5);
                    border-radius: 5px;
                    opacity: 0;
                    transition: opacity .2s;
                    pointer-events: none;

                    &.show-spinner {
                        opacity: 1;
                    }

                    img {
                        display: block;
                    }
                }
            }
        }

    }
    &__image-zoom-container {
        position: absolute;
        top: 0;
        left: 105%;
    }
    &__image-wrapper {
        position: relative;
        margin-bottom: 10px;
        height: 300px;
        line-height: 300px;
        text-align: center;
        cursor: pointer;
        img {
            vertical-align: middle;
        }
        .fa {
            position: absolute;
            display: none;
            bottom: 10px;
            right: 10px;
            font-size: 20px;
            opacity: .6;
            transition: .2s opacity;
            pointer-events: none;
        }
        &.has-zoom .fa {
            display: block;
        }
    }
    &__desktop-big-gallery {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 12010;
        top: -1000%;
        opacity: 0;
        transition: opacity .3s 0s, top 0s .3s;

        &.show {
            transition: opacity .3s 0s, top 0s 0s;
            top: 0;
            opacity: 1;
        }

        &_aspect-ratio {
            height: 100%;
            width: auto;
        }

        &_close {
            position: absolute;
            top: 15px;
            right: 15px;
            height: 40px;
            width: 40px;
            border-radius: 100%;
            text-align: center;
            line-height: 40px;
            font-size: 18px;
            background-color: rgba(0,0,0,.4);
            color: white;
            cursor: pointer;
            z-index: 10;
        }

        &_backdrop {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: rgba(0,0,0,0.6);
            -webkit-backdrop-filter: blur(2px);
        }

        &_wrapper {
            position: relative;
            flex: 0 1 auto;
            height: calc(100% - 100px);
            width: calc(100% - 100px);
            max-height: 1000px;
            max-width: 1200px;
            min-height: 500px;
            min-width: 500px;
            background-color: $White;
            box-shadow: 0 7px 30px 4px rgba(0,0,0,.7);
        }

        &_list {
            position: absolute;
            top: 0;
            left: 0;
            width: 200px;
            height: 100%;
            background-color: $LightGrey;
            overflow-y: scroll;

            li {
                background-color: $White;
                margin: 10px;
                height: 200px;
            }

            a {
                position: relative;
                display: block;
                height: 100%;
                width: 100%;
                padding: 10px;
                line-height: 180px;
                text-align: center;

                &:after {
                    content: '';
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    background-color: $Black;
                    opacity: 0;
                    transition: opacity .2s;
                }

                &:hover:after {
                    opacity: .2;
                }

                img {
                    display: inline-block;
                    vertical-align: middle;
                    margin: auto;
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }

        &_image-wrapper {
            height: 100%;
            background-color: $White;
            padding: 30px 30px 30px 230px;
            position: relative;

            div {
                display: flex;
                justify-content: center;
                height: 100%;
                width: 100%;
            }

            img {
                position: relative;
                align-self: center;

                max-height: 100%;
                max-width: 100%;
                height: auto;
            }
        }

        &_image-container {
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            max-width: 100%;
        }

        &_image-link{
            position: absolute;
            margin-top: -20px;
            color: $color-primary;
            text-transform: uppercase;
        }
    }
    &__image-gallery {
        $productImageGalleryHeight: 57px;

        position: relative;
        height: $productImageGalleryHeight;
        margin-bottom: 15px;

        &_next,
        &_prev {
            float: left;
            width: 25px;
            height: 100%;
            line-height: $productImageGalleryHeight;
            text-align: center;
            transition: all .3s;
            cursor: pointer;
            opacity: .6;
            margin-right: 5px;

            @include border-radius(3px);

            &:hover {
                opacity: 1;
                background-color: $LightGrey;
            }

            &.disabled {
                opacity: .2 !important;
                pointer-events: none;
            }
        }

        &_next {
            float: right;
            margin-left: 5px;
        }

        &_content {
            overflow: hidden;
            position: relative;
            height: $productImageGalleryHeight;

            &_items {
                position: absolute;
                display: flex;
                top: 0;
                left: 0;
                height: $productImageGalleryHeight;
                width: 100%;

                a {
                    position: relative;
                    flex: 0 0 25%;
                    display: block;
                    max-width: 25%;
                    height: $productImageGalleryHeight;
                    line-height: $productImageGalleryHeight;
                    text-align: center;
                    padding: 5px;
                    border: 2px solid transparent;
                    transition: margin-left .3s;
                    @include border-radius(3px);

                    &.active {
                        border: 2px solid $LightGrey;
                    }

                    img {
                        max-height: 100%;
                    }
                }
            }
        }
    }
    &__list-container{
        padding: 12px 0;
        border-top:    1px solid  $LightGrey;

        @include breakpoint($RWD-A) {
            margin-right: 0 !important;
        }
    }
    &__list-icon{
        display: flex;
        align-items: center;
        justify-content: center;
        //flex: 0 0 34px;
        margin-bottom: 2px;
        padding-right: 4px;
        padding-left: 8px;
        /*margin-right: 2px;*/
        & img{
            max-height: 100%;
            max-width: 100%;
        }
        &_small{
            height: 32px;
            display: flex;
            justify-content: center;
            //flex: 0 0 34px;
            margin-bottom: 2px;
            padding-right: 4px;
            padding-left: 8px;
            /*margin-right: 2px;*/
            & img {
                height: 32px;
            }
        }
        &_full{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 2px;
            padding-right: 4px;
            padding-left: 8px;
            max-height: none;
        }
        &_half{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 2px;
            padding-right: 4px;
            padding-left: 8px;
            max-height: none;
            max-width: 50%;
        }
        &_EN {
            @extend .product__list-icon;
            height: 50px;
            & img {
                max-height: none;
                max-width: none;
                height:50px;
            }
        }
    }
    &__social-icons {
        margin-right: 25px;
    }
    &__social-icon{
        font-size: 24px;
        &_fb {
            @extend .product__social-icon;
            color: #305891;
        }
        &_linkedin {
            @extend .product__social-icon;
            color: #0077b5;
        }
        &_mail{
            @extend .product__social-icon;
            color: #738a8d;
        }
        &_print{
            @extend .product__social-icon;
            color: #fc6d4c;
        }
    }
    &__brand{
        width: 140px;
        float:right;
        text-align: right;
        /*
        position: absolute;
        top: 2px;
        right: 0px;
        */
        @include breakpoint($RWD-A) {
            float: none;
            text-align: left;
        }
    }
    &__title{
        float:left;

        @include breakpoint($RWD-A) {
            font-size: 22px;
            margin-top: 10px;
        }
    }
    &__breadcrumb{
        display: inline-block;
        vertical-align: top;
        width: calc(100% - 140px);

        @include breakpoint($RWD-A) {
            width: auto;
        }
    }

    &__compare-table {
        overflow: scroll;
        border: 1px solid rgba(0,0,0,.2);

        &__content {
            width: auto !important;

            td, th {
                padding: 5px 15px;

                &:first-child(),
                &:last-child() {
                    border-right: 1px solid rgba(0,0,0,.2);
                }

                > span {
                    white-space: nowrap;
                }
            }

            th {
                font-weight: bold;
            }

            &_column-view {
                td, th {
                    border-bottom: 1px solid rgba(0,0,0,.2);

                    &:first-child() {
                        border-right: 1px solid rgba(0,0,0,.2);
                    }

                    &:nth-child(odd) {
                        background-color: $LightGrey;
                    }
                }
            }

            &_row-view {
                tr {
                    td, th {
                        border-right: 1px solid rgba(0,0,0,.2);
                    }

                    &:nth-child(odd) {
                        td, th {
                            background-color: $LightGrey;
                        }
                    }

                    &:first-child() {
                        td, th {
                            border-bottom: 1px solid rgba(0,0,0,.2);
                        }
                    }
                }
            }
        }
    }

    &__dimensional-sketches {
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;
        margin: 0 -5px;

        &__thumb {
            position: relative;
            padding-bottom: 65%;

            img {
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 100%;
                max-height: 100%;
            }
        }

        &__item-inner {
            transition: opacity .2s;
            position: relative;
            cursor: pointer;
            padding: 10px;
            background-color: #f5f5f5;

            &:hover {
                opacity: 0.8;
            }
        }

        &__item {
            position: relative;
            flex: 1 1 300px;
            max-width: 100%;
            padding: 5px;
        }

        &__item-spacer {
            flex: 1 1 300px;
            max-width: 100%;
            padding: 0;
            order: 99;
        }
    }

    &__dimensional-sketch {
        position: fixed;
        display: flex;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,.7);
        z-index: 13000;
        justify-content: center;

        &__close-overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
        }

        &__modal {
            padding: 20px;
            max-width: 900px;
            max-height: 900px;
            width: 100%;
            height: 100%;
            align-self: center;
        }

        &__view-image {
            position: relative;
            display: flex;
            width: 100%;
            height: 100%;
            padding: 20px;
            justify-content: center;
            background-color: $White;
            box-shadow: 0 10px 30px 5px rgba(0,0,0,.5);

            @keyframes tabImageSpinner {
                100% {
                    transform: rotate(360deg);
                }
            }

            &__close {
                position: absolute;
                width: 40px;
                height: 40px;
                font-size: 20px;
                text-align: center;
                line-height: 36px;
                top: 0;
                right: 0;
                cursor: pointer;
                background-color: $White;
                z-index: 20;
            }

            &__spinner {
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: -30px;
                margin-left: -30px;
                width: 60px;
                height: 60px;
                animation: tabImageSpinner 1s steps(8) infinite;
            }

            &__vertical-align {
                height: 100%;
                vertical-align: middle;
                display: inline-block;
            }

            &__wrapper {
                height: 100%;
                width: 100%;
                align-self: center;
                z-index: 10;
                position: relative;

                > img {
                    max-width: 100%;
                    max-height: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
}

.zoomWindow {
    position: relative;
    border-radius: 5px;
    box-shadow: 0px 0px 0px 6px rgba(0,0,0,.6);
    background-color: white;
}

.zoomLens {
    border-radius: 3px;
    pointer-events: none;
    border: 0 !important;
    box-shadow: 0px 0px 0px 3px rgb(0,0,0);
}

.zoomContainer {
    pointer-events: none;
}

.addonCard {
	max-width: 243px;
	min-width: 243px;
}

.addonMargin {
  margin-top: 5px;
}

.addonMarginTitle {
  margin-top: 10px;
}

.addonArrow {
  font-size: 50px;
  margin-top: 150px;
}

.addonContainer {
	margin-top: 50px;
	margin-bottom: 10px;
}