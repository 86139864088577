.header {
    @include when(desktop) {
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 12000;
        background-color: $White;
        box-shadow: 0px 0 3px 1px rgba(0,0,0,0);
        transition: box-shadow .3s;

        &__has-shadow {
            box-shadow: 0px 0 3px 1px rgba(0,0,0,0.3);
        }
    }

	&__border {
        @include border-dotts(9px 0 0 0);
    }

    &__content {
        @media only screen and (max-width: 1320px) {
            padding: 10px 0;
        }

        @include when(desktop) {
            padding: 10px;
        }

        @include when(tablet, mobile) {
            padding: 0;
            height: 60px;
        }
    }

    &__nav {
        @include when(desktop) {
            height: 33px;
        }

        @include when(mobile) {
            background-color: $color-header-bar;
        }
    }

    &__navItem {
        @include when(desktop) {
            display: block;
            float: left;
            padding: 10px 22px;
            font-size: 13px;

            &:first-child {
                padding-left: 0;
            }
        }

        @include when(tablet, mobile) {
            background-color: $color-header-nav;
            color: $color-font;
            margin-bottom: 1px;
        }
    }

    &__navItemText {
        font-weight: 900;
        text-transform: uppercase;
    }

    &__listLinkIcon {
        &.selected {
            font-weight: bold;
        }
    }

    &__button {
        margin: 0;
    }

    &__buttonLink,
    &__buttonLink_square {
        color: $color-font;
        background-color: transparent;
    }

    &__logo {
        @include when(desktop) {
            position: absolute;
            height: calc(100% - 20px);
            padding: 5px 0;

            > a {
                height: 100%;
                display: block;

                > img {
                    height: initial;
                    max-height: 100%;
                }
            }
        }
        @include when(mobile, tablet) {
            height: 100%;
            padding: 10px;
            position: absolute;
            top: 0px;
            left: 60px;
            width: 126px;
            max-width: calc(100% - 240px);
            display: block;
            margin: 0;

            > a {
                height: 100%;
                line-height: 40px;

                > img {
                    height: initial;
                    vertical-align: middle;
                    max-height: 100%;
                }
            }
        }
    }

    &__searchField {
        border: 1px solid lighten($color-header-icon, 10%);
        padding: 2px 37px 0 10px;
        transition: border .1s;
        border-radius: 0;

        &:focus {
            border: 1px solid $color-header-icon;
            outline: 0;

            + .header__searchFieldSubmit {
                opacity: 0.6;
            }
        }
    }

    &__searchFieldContainer {
        // margin-top: 5px;
    }

    &__sugestSubmit {
        
    }

    &__searchFieldSubmit {
        border: 1px solid rgba(0,0,0,0);
        background: $White;
        opacity: 0.3;
        transition: opacity .1s, background .1s;

        @include when(mobile) {
            top: 11px;
            right: 11px;
        }

        &:hover {
            background: $LightGrey;
        }

        .fa {
            vertical-align: 0;
            font-size: 115%;
        }
    }

    &__right-box {
        @include when(tablet, mobile) {
            position: relative;
            z-index: 10;
            background-color: $color-header-bar;
        }
    }

    &__miniCart {
        font-size: 13px;

        @include when(mobile, tablet) {
            right: 5px;
            top: 5px;
        }
    }


    //Begin Dark theme for skydda.se
    &_dark-theme &__navItemText {
        font-family: 'Barlow', arial, sans-serif;
        font-size: 16px;
        font-weight: bold;
        color: $White;
        text-transform: capitalize;

        &_active {
            color: $color-secondary; 
            font-family: 'Barlow', arial, sans-serif;
            font-size: 16px;
            font-weight: bold;
        }
    }

    &_dark-theme &__navContainer {
        background-color: $color-dark-bg;
        
        @include when(mobile, tablet) {
            top: 92px;
        }      
    }

    &_dark-theme &__container {
        background-color: $color-dark-bg;    
    }

    &_dark-theme &__buttonLink {
        color: $White;
        font-family: 'Barlow Semi Condensed', arial, sans-serif;
        font-size: 12px;
        font-weight: bold;          
    }

    &_dark-theme &__buttonLink.show {           
        @include when(mobile, tablet) {
            background-color: $color-dark-mobile-nav-bg;
        }                
    }

    &_dark-theme &__searchField {
        background-color: $color-dark-search-field;
        color: $color-header-icon;       
        border: none;
        border-radius: 50px;
        padding: 2px 37px 0 36px;
        font-family: 'Barlow', arial, sans-serif;
        font-size: 16px;
        
        @include when(desktop) {
            width: 285px;
        }

        @include when(tablet) {
            padding: 2px 37px 0 38px;
        }

        &::placeholder {
            color: $color-header-icon;
            font-size: 16px;
            font-family: 'Barlow', arial, sans-serif;
        }   

        &::-webkit-search-cancel-button {
            display:none;
        }          
    }

    &_dark-theme &__searchFieldContainer {
        border-top: 0.5px $color-dark-search-field;
        background-color: $color-dark-bg;

        @include when(mobile) {
            background-color: $color-dark-mobile-nav-bg;
        }
    }

    &_dark-theme &__searchFieldSubmit {
        background-color:transparent;
        color: $color-header-icon;
        opacity: 100;
        left: 1px;

        @include when (tablet) {
            left: 4px;
        }   
        
        @include when (mobile) {
            left: 13px;
        }  
    }

    &_dark-theme &__nav {
        @include when(desktop) {
            height: 60px;
            padding-top: 15px;
        }
        
        @include when(mobile, tablet) {
            background-color: $color-dark-mobile-nav-bg;
        }
    }

    &_dark-theme &__navItem {
        background-color: $color-dark-bg;     
        
        @include when(mobile, tablet){
            background-color: $color-dark-mobile-nav-bg; 
            border-bottom: 0.5px solid $color-dark-mobile-nav2;
            margin: 0;
        }     
    } 

    &_dark-theme &__site_menu {        
        background-color: $color-dark-bg; 
        border-bottom: 0.5px solid $color-dark-mobile-nav2; 
    }

    &_dark-theme &__site_menu_content {      
        font-family: 'Barlow Semi Condensed', arial, sans-serif;
        font-size: 12px;        
        letter-spacing: 0.75px;
        padding: 7px 8px 10px 0;
        max-width: 1280px;
        margin: 0 auto;  
        height: 32px;
        
        text-transform: uppercase;
        
        @include when(mobile, tablet) {
            padding-left: 10px;       
        }

        @media only screen and (max-width: 1320px) {
            padding-left: 10px;
        }    
    }

    &_dark-theme &__link_active {       
        color: $color-secondary; 
        float: left; 
        padding-right: 10px;
        font-weight: bold;       
    } 

    &_dark-theme &__link_inactive {       
        color: $White; 
        float: left; 
        padding-right: 20px; 
        font-weight: bold;       
    }

    &_dark-theme &__content {
        padding-left: 0;          
        
        @media only screen and (max-width: 1320px) {
            padding: 10px;
        } 
        
        @include when(mobile, tablet) {
            padding: 0;      
        }  
    }

    &_dark-theme &__logo {
        @include when(desktop) {
            margin-top: 10px;      
        }               
    }

    &_dark-theme__space {
        height: 77px; 
        pointer-events: none;
        
        @include when(mobile, tablet) {
            height: 40px; 
        }
    }
}
