.header {
    @include when(mobile, tablet) {
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 12000;
        background-color: $White;
        box-shadow: 0px 0 3px 1px rgba(0,0,0,0);
        transition: box-shadow .3s;

        &__has-shadow {
            box-shadow: 0px 0 3px 1px rgba(0,0,0,0.3);
        }
    }

    &__container {
        background-color: $color-header-bar;
        position: relative;

        @include when(mobile, tablet) {
            z-index: 12;
            height: 60px;
        }
    }

    &__navContainer {
        background-color: $color-header-nav;

        @include when(mobile, tablet) {
            top: 60px;
            max-height: calc(100% - 60px);
        }
    }

    &__content {
        max-width: 1280px;
        margin: 0 auto;
        padding: 10px 0 4px 0;

        @media only screen and (max-width: 1320px) {
            padding-left: 10px;
            padding-right: 10px;
        }

        @include when(mobile, tablet) {
            z-index: 12;
            padding: 17px 10px 15px 10px;
        }
    }

    &__logo {
        float: left;
        max-width: 212px;

        @include when(mobile, tablet) {
            float: none;
            display: inline-block;
            position: absolute;
            width: calc(100% - 100px);
            top: 12px;
            left: 0;
            max-width: none;
            margin-right: 50px;

            a {
                display: inline-block;
            }

            img {
                height: 44px;
            }
        }

        @include when(mobile) {
            margin-left: 50px;
            text-align: center;
            top: 0;
        }

        @include when(tablet) {
            margin-left: 75px;
            top: 0;
        }
    }

    &__left-box {
        float: left;
    }

    &__right-box {
        @include when(desktop) {
            float: right;
        }
    }

    &__button {
        float: right;
        text-align: center;
        margin: 0;

        &_hide-mobile {
            @extend .header__button;

            @include when(tablet, mobile) {
                display: none;
            }
        }
        &_show-mobile {
            @extend .header__button;

            @include when(tablet, desktop) {
                display: none;
            }
        }
        &.active i{
            color: $color-secondary;
        }
    }

    &__buttonMenu {
        display: none;

        @include when(mobile) {
            display: block;
            margin: 0;
            color: white;
        }

        @include when(tablet) {
            display: block;
        }
    }

    &__buttonLink {
        background: $header-button-black;
        color: $header-button-white;
        text-transform: uppercase;
        min-width: 35px;
        height: 35px;
        text-align: center;
        font-size: 11px;
        font-weight: 400;
        display: inline-block;
        line-height: 35px;
        vertical-align: 1px;
        font-family: $header-font;
        padding: 0 17px;
        position: relative;

        .fa-remove {
            display: none;
        }

        @include when(tablet, mobile) {
            height: 60px;
            width: 60px;
            line-height: 60px;
            padding: 0 10px;

            &.show {
                background-color: $color-header-nav;

                .fa {
                    display: none;
                }

                .fa-remove {
                    display: inline-block;
                }
            }

            .fa {
                font-size: 2em;
                color: $color-header-icon;
                vertical-align: middle;
                line-height: 100%;
            }
        }

        i {
            vertical-align: -2px;
            font-size: 20px;
        }

        &_flag {
            @extend .header__buttonLink;

            font-size: 18px;
            padding: 0;

        }

        &_square {
            @extend .header__buttonLink;

            padding: 0;
        }
    }

    &__miniCart {
        background-color: $color-icon-secondary;
        position: absolute;
        border-radius: 15px;
        text-align: center;
        font-size: 14px;
        line-height: 22px;
        height: 22px;
        width: 22px;
        right: -7px;
        top: -7px;
        color: white;
    }

    &__shopOnBehalfDropDown {
        background-color: $White;
        border-radius: 4px;
        position: absolute;
        padding: 10px;
        z-index: 15;
        width: 300px;
        text-align: left;
        box-shadow: 1px 2px 10px rgba(0,0,0,0.4);
        font-size: 14px;
        @include breakpoint($RWD-A) {
            top: 50px;
            right: 0px;
            width: 100%;
        }
        @include when(mobile) {
            top: 60px;
            border-radius: 0;
            box-shadow: 0px 9px 14px -1px rgba(0, 0, 0, 0.4);
            background-color: $color-header-nav;
        }

        @include when(tablet) {
            top: 0;
            right: 0;
            border-radius: 0;
            box-shadow: 0px 9px 14px -1px rgba(0, 0, 0, 0.4);
            background-color: $color-header-nav;
        }
        
        & .header__sugestionListItem {
            @include when(tablet) {
                line-height: 40px;
                border-bottom: 1px solid $color-border-secondary;
            }
        }

        ul {
        	max-height: 460px;
        	overflow-y: auto;
        }
    }

    &__shopOnBehalfIcon {
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
    }
    
    &__shopOnBehalfInfo {
        float: right;
        background: white;
        padding: 15px;
        margin-right: 30px;
        width: 300px;
        right: 100%; 
        font-size: 11px;
        font-weight: 500;
        box-shadow: 0px 0px 11px 1px rgba(0,0,0,0.6);
        
        @include when(tablet, mobile) {
            display: none;   
        }
        
        & a {
            font-weight: 600;
        }
    }

    &__searchFieldContainer {
        // margin-top: 10px;
        margin-top: 5px;
        position: relative;
        z-index: 8;
        clear: right;
        float: right;
        display: inline-block;

        @include when(mobile) {
            width: 100%;
            // margin-top: 15px;    
        }

        @include when(tablet) {
            // margin-top: 0;
            clear: none;
            width: 34%;
        }

        @include when(tablet) {
            margin-right: 12px;
            margin-top: 12px;
        }

        @include when(mobile) {
            margin: 0;
            padding: 10px;
            width: 100%;
            background-color: $color-header-nav;
        }
    }

    &__searchFieldSubmit {
        height: 33px;
        width: 37px;
        text-align: center;
        font-size: 14px;
        display: inline-block;
        line-height: 33px;
        cursor: pointer;
        position: absolute;
        top: 1px;
        right: 1px;

        @include when(mobile) {
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    &__searchField {
        width: 435px;
        height: 35px;
        display: inline-block;
        font-size: 14px;
        -webkit-appearance: none;

        @include when(mobile, tablet) {
            width: 100%;
        }

        &::-webkit-input-placeholder {
            /* WebKit, Blink, Edge */
            color: $header-button-black;
        }
        &:-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            color: $header-button-black;
            opacity: 1;
        }
        &::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: $header-button-black;
            opacity: 1;
        }
        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $header-button-black;
        }
    }

    &__searchOverlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
    }

    &__sugestSubmit {
        top: 100%;
        position: absolute;
        width: 98%;
        left: 1%;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        background: white;
        z-index: 8;
        box-shadow: $box-shadow-primary;

        @include when(mobile) {
            width: 100%;
            left: 0;
        }
    }

    &__searchHeader {
        padding: 15px 10px 5px;
        border-bottom: 1px solid $color-border-primary;
        font-weight: bold;
    }

    &__sugestionsBox {
        overflow: hidden;
    }

    &__searchSugestions {
        overflow: hidden;
        border-bottom: 1px solid $color-border-primary;
        font-size: 14px;

        &:last-child {
            border-bottom: 0;
        }

        &:hover {
            background: $color-container-bg;
        }
    }

    &__sugestionList li:last-child .header__sugestionListItem{
        @include when(tablet) {
            border-bottom: none;
        }
    }
    
    &__sugestionListItem {
        display: block;
        overflow: hidden;
        border-bottom: 1px solid $color-border-primary;
        font-size: 12px;
        line-height: 20px;

        &:hover {
            background: $color-container-bg;
        }
    }

    &__searchLink {
        display: flex;
        align-items: center;
        padding: 5px 10px;

        &_suggestion {
            @extend .header__searchLink;
            padding: 5px 15px;

            &:focus {
                outline: 0;
                background: $color-container-bg;
            }
        }
    }

    &__searchThumbnail {
        float: left;
        background: $White;
        margin-right: 15px;
        height: 32px;
        width: 32px;
        text-align: center;
        line-height: 32px;

        img {
            max-width: 100%;
            max-height: 100%;
            vertical-align: middle;
        }
    }

    &__searchName {
        font-style: normal;
        font-weight: bold;
    }

    &__border {
        border-top: 1px solid $Black;
        margin: 0 10px 0;
    }


    &__dropMenu {
        &_desktop {
            @include when(desktop) {
                display: none;

                &.show {
                    display: block;
                }
            }
        }

        &_tablet {
            @include when(tablet) {
                display: none;

                &.show {
                    display: block;
                }
            }
        }

        &_mobile {
            @include when(mobile) {
                display: none;

                &.show {
                    display: block;
                }
            }
        }
    }

    &__navContainer {
        position: relative;
        z-index: 7;

        @include when(mobile, tablet) {
            position: fixed;
            left: 0;
            width: 80%;
            max-width: 400px;
            z-index: 13;
            overflow-y: scroll;
            max-height: calc(100% - 60px);
        }
    }

    &__desktopNav {
        transition: box-shadow .3s;
        box-shadow: 0px 0px 3px 2px rgba(0,0,0,0);

        &_fixed {
            position: fixed;
            width: 100%;
            background-color: white;
            box-shadow: 0px 0px 3px 2px rgba(0,0,0,.3);
            transition: top .3s, box-shadow .3s;
        }
    }

    &__nav {
        max-width: 1280px;
        margin: 0 auto;
        line-height: 1;

        @media only screen and (max-width: 1320px) {
            padding-left: 10px;
            padding-right: 10px;
        }

        @include when(tablet, mobile) {
            background: white;
            padding: 0;
            margin: 0;
        }
    }

    &__navItem {
        display: inline-block;
        padding: 12px 25px 5px 25px;
        font-size: 15px;
        overflow: hidden;

        &:last-child {
            padding-right: 0;
        }

        &:first-child {
            @include breakpoint(desktop) {
                padding-left: 0;
            }
        }

        @include when(mobile) {
            background: $MobileMenuGrey;
            color: $White;
            display: block;
            padding: 12px 15px;
            font-size: 14px;
            
            & .rs-link{
                
            }
        }

        @include when(tablet) {
            background: $MobileMenuGrey;
            color: $White;
            display: block;
            padding: 12px 15px;
        }

        &_small {
            @extend .header__navItem;

            padding: 0;
            margin-top: 0;
            margin-bottom: 0;
            background: none;

            @include when(tablet, mobile) {
               background: $MobileMenuGrey;
            }
        }
        
        i {
            @include when(tablet, mobile) {
                font-size: 17px;
            }
        }
    }

    &__navItem {
        @include when(desktop) {
            display: block;
            float: left;
            padding: 10px 22px;
            font-size: 13px;

            &:first-child {
                padding-left: 0;
            }
        }

        @include when(tablet, mobile) {
            background-color: $color-header-nav;
            color: $color-font;
            margin-bottom: 1px;
        }
        
        &_become {
            color: $color-button-secondary !important;
        }
    }

    &__navItemText, .rs-link {
        font-weight: 900;
        text-transform: uppercase;
    }

    &__navItemText, rs-link {
        padding-bottom: 1px;
        font-family: $header-font;

        i {
            float: right;
        }

        &_lvl2 {
            background-color: $DarkGrey;
            color: $White;
            padding: 15px;
            font-weight: normal;
            border-bottom: 1px solid $MiddleGrey;
            display: block;
        }

        &:hover,
        &_selected {
            @extend .header__navItemText;
            border-bottom: 2px solid $MiddleGrey;
        }
    }

    &__maNav {
        position: absolute;
        width: 300%;
        min-width: 230px;
        right: 0;
        z-index: 15;
        text-align: left;
        background: $White;
        border-radius: 3px;
        padding: 6px 3px;
        box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.4);
        margin-top: 10px;

        @include when(mobile) {
            right: -45px;
        }
    }

    &__maNavItem {
        background: $header-button-black;
        color: $White;
        display: block;
        padding: 12px 15px;
        margin: 3px 0;
    }

    &__maNavArrow {
        position: absolute;
        right: 51px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #FFFFFF;
        z-index: 16;
    }

    &__arrowLink {
        width: 50px;
        float: right;
        text-align: right;
        font-size: 22px;
    }

    &__breadcrumb{
        padding: 2px 0;
        max-width: 1280px;
        margin: 0 auto;
        text-align: right;

        @media only screen and (max-width: 1320px) {
            margin-right: 10px;
        }
    }

    &__mobile-spinner {
        background-color: rgba(0,0,0,.3);
        position: fixed;
        top: 200%;
        left: 0;
        height: 100%;
        width: 100%;
        transition: opacity .3s 0s, top 0s .3s;
        opacity: 0;
        z-index: 3001;

        &.show {
            transition: opacity .3s;
            opacity: 1;
            top: 0;
        }

        div {
            position: absolute;
            width: 60px;
            height: 60px;
            left: 50%;
            top: 50%;
            margin-top: -30px;
            margin-left: -30px;
        }
    }

    &__drop {
        background-color: $White;
        box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.4);
        border-radius: 4px;
        position: absolute;
        padding: 5px 10px;
        top: calc(110% + 6px);
        right: 0px;
        z-index: 15;
        width: 220px;

        &::after {
            position: absolute;
            right: 40px;
            top: -8px;
            width: 0;
            content: '';
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid #FFFFFF;
            z-index: 16;
        }

        > li {
            a {
                border-bottom: 1px solid $LightGrey;
                display: flex;
                padding: 5px 10px;
                margin: 0 -10px;
                text-align: left;
                justify-content: space-between;

                &:hover {
                    background-color: rgba(0,0,0,.05);
                }

                .flag-icon {
                    display: block;
                    background-size: cover;
                    border-radius: 2px;
                    width: 26px;
                    height: 20px;
                    margin-top: 2px;
                }
            }

            &:last-child a {
                border-bottom: 0;
            }
        }
    }
}

.ie10 .media-container__border_info,
.ie10 .media-container__border,
.ie10 .header__border,
.ie10 .cms__devider,
.ie10 .cms__devider_small,
.ie10 .media-container__border_box {
    border: 0;
    border-top: 1px solid $DarkGrey;
}

.ie10 .media-container__border_info {
    margin-left:20px;
    flex-basis: calc(100% - 40px) !important;
}

//Preview css reset
.pageinfo_header_top>.header {
    position: unset !important;
    background-color: transparent !important;
}
