.footer {
    margin-top: 40px;

    hr {
        border-top: 1px solid darken($LightGrey, 6%);
    }

    &__main {
        background-color: $color-footer;
        color: $color-font;
    }

    &__secondary {
        background-color: $color-footer-secondary;
    }

    &__content {
        &_main {
            color: $color-font;

            @include when(mobile) {
                .has-content.footer__list-box {
                    border-bottom: 1px solid darken($LightGrey, 6%);

                    &:first-child {
                        border-top: 1px solid darken($LightGrey, 6%);
                    }
                }

                li {
                    margin-top: 4px;
                }
            }
        }
    }

    &__title {
        color: $color-font;
    }

    &__list-box {
        padding-right: 10px;
    }

    &__list {
        &_brand {
            font-weight: 400;

            li {
                color: $color-font;
            }

            &_separator {
                border-right: 2px solid lighten($color-font, 25%);
            }
        }

        &_cert {
            justify-content: center;

            li {
                margin: 0 10px;
            }
        }
    }

    //Begin Dark theme
    &_dark-theme &__content {      
        &_main {
            border-bottom: 1px solid $color-dark-search-field;
            background-color: $color-dark-bg;
            color: $White;
            
            @include when(mobile) {
                padding: 0;
                border-bottom: 0;
            }
        }      
    }

    &_dark-theme &__content_main .has-content.footer__list-box {
        @include when(mobile) {            
            border-top: 0 !important;
            border-bottom: 0.5px solid $color-dark-search-field;
        }       
    }

    &_dark-theme &__main {
        background-color: $color-dark-bg;
    }

    &_dark-theme &__secondary {
        background-color: $color-dark-bg; 

        hr {
            border-top: 0.5px solid $color-dark-search-field;
            border-bottom: 0.5px solid $color-dark-search-field;
        }  
    }

    &_dark-theme &__title {
        color: $White;
        font-family: 'Barlow Semi Condensed', arial, sans-serif;
        font-size: 24px;
        font-weight: bold; 
        text-transform: uppercase;

        @include when (mobile) {
            font-size: 16px;
        }  
    }

    &_dark-theme &__list_brand {
        li {
            color: $White; 
            font-family: 'Barlow', arial, sans-serif;
            font-size: 15px;

            @include when (mobile) {
                font-size: 13px;
                padding: 0 5px; 
            }
        }
    } 

    &_dark-theme &__list__title {
        font-family: 'Barlow Semi Condensed', arial, sans-serif;
        font-size: 24px;
        font-weight: bold;
        text-transform: uppercase;
        color: $White;
        line-height: 1.3em;

        @include when (mobile) {
            font-size: 16px;
        }     
    } 

    &_dark-theme &__list_main {
        font-family: 'Barlow', arial, sans-serif;
        font-size: 14px;
        line-height: 25px;      
    }    

}
