.flex-table {
    font-size: 14px;

    &_ajaxed {
        @extend .flex-table;

        padding: 0 10px;
        box-shadow: $box-shadow-primary;
    }

    &__main {
        position: relative;
        display: flex;
        background: $White;
        flex-flow: column nowrap;
        justify-content: space-between;
        font-size: 12px;

        // TABLE TEST
        // display: table;
        // border-collapse: separate;
        // border-spacing: 0;
        // border-color: gray;
        // width: 100%;

        &_descr {
            @extend .flex-table__main;
        }

        &_descr ul:nth-child(2n) {
            background: #eee;
        }
    }

    &__icon {
        float: right;
        padding-right: 20px;
    }

    &__title {
        font-size: 18px;
        height: 38px;
        width: 100%;
        background: $MiddleGrey;
        padding: 10px 0 0 12px;
        color: $White;
    }

    &__link {
        @extend .flex-table;

        font-size: 1em;
        text-transform: none;
        color: $color-primary;
        font-weight: 500;

        &:hover {
            text-decoration: underline;
            color: lighten($color-primary, 10%);
        }
    }

    &__row {
        display: flex;
        padding-left: 5px;
        align-items: center;

        // TABLE TEST
        // display: table-row;
        // vertical-align: inherit;
        // border-color: inherit;

        @include when(tablet, desktop) {
            min-height: 33px;
        }

        @include when(mobile) {
            display: block;
            margin: 0;
        }

        &_quickOrder-header {
            @extend .flex-table__row;

            display: flex;
            flex: 0 1 650px;
            padding: 6px 12px;
            font-size: 13px;
            font-weight: 900;
            text-transform: uppercase;

            @include when(mobile) {
                display: none;
            }
        }

        &_article-header {
            @extend .flex-table__row;

            padding: 6px 12px;
            font-size: 12px;
            font-family: $header-font;

            @include when(mobile) {
                display: none;
            }
        }

        &_quickOrder {
            display: flex;
        }

        &_article {
            @extend .flex-table__row;

            padding: 3px 12px;
            color: black;
            text-transform: none;
            font-size: 12px;

            @include when(mobile) {
                padding: 0;
                min-height: 33px;
            }

            &:nth-child(even){
                background: $LightGrey;
            }

            &:nth-child(odd){
                background: $White;
            }
        }

        &_gtin {
            @extend .flex-table__row;

            padding: 3px 12px;

            @include when(mobile) {
                padding: 0;
                min-height: 33px;
            }
        }

        &_order-header {
            @extend .flex-table__row;

            padding: 6px 0 6px 0;
            font-weight: bold;
            border-bottom: 1px solid $color-border-primary;

            @include when(mobile) {
                display: none;
            }
        }

        &_order {
            @extend .flex-table__row;

            padding: 6px 0 6px 0;
            border-bottom: 1px solid $color-border-primary;

            &:last-child {
                border-bottom: none;
            }
        }

        &_article-green {
            @extend .flex-table__row;

            padding: 5px 12px;
            outline: 2px solid $color-primary;
            margin: 2px 1px;
            color: black;

            @include when(mobile) {
                outline: 0;
                margin: 0;
                padding: 0;

                .flex-table__mobile-row_closed {
                    font-weight: bold;
                }
            }

            &:nth-child(even) {
                background: $LightGrey;
            }

            &:nth-child(odd) {
                background: $White;
            }
        }
    }

    &__section {
        position: relative;
        display: flex;
        width: 100%;
        align-items: center;

        @include when(mobile) {
            display: block;
            min-height: auto;
            padding: 5px 0;
        }

        &_mob {
            @extend .flex-table__section;

            display: none;
            font-weight: bold;

            @include breakpoint($RWD-A) {
                display: block;
            }

            .flex-table__item {
                &:last-child {
                    border-bottom: 0;
                    height: 36px;
                }
            }
        }
    }

    &__col {
        flex-flow: column;
        overflow: hidden;
        min-height: 33px;
        border-bottom: 1px solid $color-border-primary;

        &:nth-child(even) {
            background: $LightGrey;
        }

        &:nth-child(odd) {
            background: $White;
        }

        &-green {
            @extend .flex-table__col;

            outline: 2px solid $color-primary;
            margin: 2px 1px;
        }
    }

    &__close {
        height: 33px;

        @include when(mobile) {
            height: auto;
        }

        &_mobile {
            @include when(mobile) {
                height: 39px;
            }
        }
    }

    &__attachment {
        flex: 0 0 20px;

        &_content {
            @extend .flex-table__attachment;

            background: $color-secondary;
            text-align: center;
            height:32px;
        }
    }

    &__item {
        flex: 1;

        // TABLE TEST
        // display: table-cell;
        // vertical-align: inherit;

        @include when(mobile) {
            padding: 6px 12px;
            display: block;

            &:before {
                content: attr(data-label);
                display: inline-block;
                font-weight: bold;
                margin-right: 10px;
                min-width: 130px;
            }
        }

        &__number {
            @extend .flex-table__item;

            @include when(tablet, desktop) {
                text-align: right;
            }
        }

        &_returns-date {
            @extend .flex-table__item;

            max-width: 190px;
        }

        &_returns-ref {
            @extend .flex-table__item;

            max-width: 510px;
        }

        &_returns-stat {
            @extend .flex-table__item;

            max-width: 100px;
        }

        &_checkout-mark {
            @extend .flex-table__item;

            flex: none;
            width: 130px;
            padding-left: 2px;
            padding-right: 10px;

            @include when(mobile) {
                width: 100%;

                &:before {
                    content: attr(data-label);
                    font-weight: bold;
                    margin-right: 10px;
                }
            }
        }

        &_checkout-artnr {
            @extend .flex-table__item;

            display: block;
            width: 95px;

            @include when(mobile) {
                display: none;
            }
        }

        &_checkout-desc {
            @extend .flex-table__item;

            flex: 4;

            @include when(mobile) {
                display: none;
            }
        }

        &_checkout-package {
            @extend .flex-table__item;
        }

        &_checkout-content {
            @extend .flex-table__item;
        }

        &_checkout-price {
            @extend .flex-table__item;
        }

        &_checkout-avaliable {
            @extend .flex-table__item;
        }

        &_checkout-quantity {
            @extend .flex-table__item;

            flex: none;
            width: 60px;
            padding-right: 10px;

            input {
                text-align: center;
            }

            @include when(mobile) {
                width: 100%;
            }
        }

        &_checkout-buttonwrapper {
            @extend .flex-table__item;

            flex: none;
            padding-right: 0;
            width: 54px;
            text-align: right;

            @include when(mobile) {
                width: 100%;
                padding-bottom: 7px !important;
            }

            li {
                display: inline-block;
            }
        }

        &_checkout-buttonwrapperLarge {
            @extend .flex-table__item;

            flex: none;
            padding-right: 0;
            width: 180px;
            text-align: right;

            @include when(mobile) {
                width: 100%;
            }

            li {
                display: inline-block;
            }
        }

        &_flex2 {
            @extend .flex-table__item;

            flex: 2 1;
        }

        &_flex3 {
            @extend .flex-table__item;

            flex: 3 1;
        }

        &_flex4 {
            @extend .flex-table__item;

            flex: 4 1;
        }

        &_flex6 {
            @extend .flex-table__item;

            flex: 6 1;
        }

        &_articleCode {
            @extend .flex-table__item;

            color: $Red;
            flex: 0 0 24px;
            padding-left: 3px;
            text-align: center;

            @include when(mobile) {
                text-align: left;
                display: none;
            }
        }

        &_mark {
            @extend .flex-table__item;

            flex: 0 0 70px;
            padding-left: 2px;

            @include when(mobile) {
                text-align: left;
                padding: 6px 12px;

                &:before {
                    display: block;
                }

                .input_table {
                    max-width: 100%;
                    text-align: left;
                }
            }
        }

        &_art-nr {
            @extend .flex-table__item;

            flex: 0 0 85px;
            margin-left: 10px;

            @include when(mobile) {
                display: none;
            }

        }

        &_gtin {
            @extend .flex-table__item;

            flex: 1 1 95px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        &_description {
            @extend .flex-table__item;

            flex: 10 1 500px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            @include when(mobile) {
                display: none;
            }

        }

        &_unit {
            @extend .flex-table__item;

            flex: 0 0 75px;
            line-height: 1;
            text-align: center;

            @include when(mobile) {
                line-height: inherit;
                text-align: left;
                padding: 1px 12px;
            }
        }

        &_packaging {
            @extend .flex-table__item;

            flex: 0 1 180px;
            white-space: nowrap;
            
            @include when(mobile) {
                padding: 1px 12px;
            }
        }

        &_quantity {
            @extend .flex-table__item;

            flex: 0 0 45px;

            input {
                text-align: center;
                padding: 0 3px;
            }

            @include when(mobile) {
                text-align: left;

                &:before {
                    display: block;
                }

                .input_table {
                    max-width: 100%;
                    text-align: left;
                    padding: 0 8px;
                }
            }
        }
        
        &_price {
            @extend .flex-table__item;

            flex: 1 1 120px;
            min-width: 80px;
            text-align: right;
            padding-left: 0;
            padding-right: 12px;

            @include when(mobile) {
                text-align: left;
                padding: 1px 12px;
            }
        }

        &_flag {
            flex: 1 0 60px;
            height: 24px;
            font-size: 11px;
            text-align: right;

            @include when(mobile) {
                display: none;
                padding: 0;
            }

            img {
                max-width: 25px;
            }
        }

        &_flagDeleted {
            flex: 0 0 105px;
            height: 24px;

            @include when(mobile) {
                display: none;
                padding: 0;
            } 
        }

        &_buy {
            margin-left: 10px;
            flex: 0 0 70px;

            @include when(mobile) {
                display: block;
                margin: 0;
                padding: 6px 12px;
            }
        }

        &_description2 {
            @extend .flex-table__item;

            flex: 3 1 auto;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            &:hover {
                text-decoration: underline;
            }

        }

        &_color-picker {
            @extend .flex-table__item;

            flex: 0 0 50px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        &_compare {
            @extend .flex-table__item;

            flex: 0 0 100px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        &_show-article {
            @extend .flex-table__item;

            flex: 0 0 150px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        &_attribute {
            padding-left: 12px;
            width: 20%;
        }

        &_requisitionSettings {
            width: 22px;

            &_top {
                @extend .flex-table__item_requisitionSettings;
            }

            @include when(mobile) {
                position: absolute;
                right: 7px;
                // margin-top: 7px;

                &_top {
                    top: 7px;
                }
            }
        }

        &_requisitionSettings2 {
            width: 42px;

            @include when(mobile) {
                margin:7px 5px;
            }
        }

        &_show-article {
           @extend .flex-table__item;

           flex: 0 0 150px;
           text-overflow: ellipsis;
           overflow: hidden;
           white-space: nowrap;
        }

        &_quickOrder-artnr {
            @extend .flex-table__item;

            flex: 0 1 95px;
        }

        &_descr-title {
            @extend .flex-table__item;
            
            font-size: 11px;
            text-transform: uppercase;
            font-family: $header-font;
            line-height: 13px;
        }
    }

    &__quickOrder {
        display: flex;
        flex: 0 1 650px;
        background: $LightGrey;
        align-items: center;
        height: 33px;
        padding-right: 12px;
    }

    &__quickOrder-button {
        padding: 1px 0 0 12px;
        flex: 1 1 627px;
    }

    &__errorContainer {
        display: none;
        position: relative;
        margin: 0 auto;
        width: 275px;
    }

    &__error {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
        border-radius: 4px;
        background: $White;
        padding: 12px;
        position: fixed;
        top: 50%;
        z-index: 10;
    }

    &__requisitionContainer {
        position: relative;
        float: right;
        text-align: right;

        @include when(mobile) {
            display: none;
        }
    }

    &__actionList {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
        border-radius: 3px;
        background: $White;
        color: $Black;
        font-size: 12px;
        position: absolute;
        text-align: left;
        width: 225px;
        right: 0;
        z-index: 1210;
        overflow: hidden;

        span {
            cursor: pointer;

            &:hover {
                color:$color-button-secondary;
            }
        }

        @include when(mobile) {
            right: inherit;
        }
    }

    &__actionListItem {
        border-bottom: 1px solid #ccc;
        &:last-child {
            border-bottom: none;
        }
        & a {
            display: block;
            padding: 7px 10px;
            &:hover {
                background: darken($White, 3%);
            }
        }
    }

    &__mobile-row {
        display: none;

        @include when(mobile) {
            position: relative;
            cursor: pointer;
            display: block;
            padding: 8px 12px;
            min-height: 33px;
            width: 100%;
            white-space: nowrap;
            line-height: 25px;
            font-size: 12px;

            &_closed {
                display: none;
            }

            &_opened {
                display: inline;

                b {
                    font-weight: bold;
                    margin-right: 10px;
                }
            }

            small {
                font-size: 85%;
                font-weight: bold;
            }
        }
    }

    &__close_mobile {
        @include when(mobile) {
            overflow: hidden;

            .flex-table__mobile-row_closed {
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .flex-table__mobile-row_opened {
                display: none;
            }
        }
    }

    &__mobile-header-text {
        width: calc(100% - 22px);
        overflow: hidden;
        text-overflow: ellipsis;

        &_flag {
            @extend .flex-table__mobile-header-text;

            width: calc(100% - 85px);
        }
    }

    &__mobile-link {
        display: none;

        @include when(mobile) {
            display: block;
            padding: 6px 12px;
        }
    }

    &__mobile-flag {
        position: absolute;
        top: 4px;
        right: 32px;
        width: 106px;

        @include when(mobile) {
            width: auto;
        }
    }

    &__mobile-toggle-icon {
        color: $color-icon-secondary;
        position: absolute;
        right: 12px;
        top: 8px;
        transform: rotate(90deg);
    }

    &__closable &__mobile-toggle-icon {
        transform: rotate(0deg);
    }

    &__articleCode {
        color: $Red;
    }

    &__disabled {
        color: darken($color-border-primary, 12%);
    }

    &__detailed {
        padding-right: 10px;
    }

    &__close-icon {
      font-size: 21px;
      line-height: 14px;
      padding-left: 6px;
    }
}
