.filter {
	&__container {
		border-radius: 9px;
        padding-top: 15px;
	}

	&__border {
		@include border-dotts(9px 0 0 0);
    }

    &__mobile-product-group {

        &_list {
            ul {
                right: 0 !important;
                color: $color-font;
                background-color: $color-header-nav;
            }
        }
    }
    
    &__mobile-filter-actions,
    &__mobile-product-group_button,
    &__settings-mobile_button {
        font-weight: 900;
    }

    &__settings-mobile_sorting_button {
        background-color: $color-header-nav;
        color: $color-font;
    }

    &__settings-mobile_sorting_list {
        background-color: $color-separator-border;

        li {
            border-bottom: 1px solid $color-separator-border;

            a {
                background-color: $color-header-nav;
                color: $color-font;
            }
        }
    }

    &__mobile-filter-actions a.black-button {
        background-color: $color-header-nav;
        color: $color-font;
    }

    &__container__scroll-wrapper {
        @include when(mobile) {
            background-color: $color-header-nav;
        }
    }
}