.button {
    cursor: pointer;
    display: inline-block;
    line-height: 30px;
    padding: 0 30px;
    font-size: 14px;
    text-transform: uppercase;

    &.full-width {
        width: 100%;
        text-align: center;
    }

    &_primary {
        @extend .button;
        @include btn-theme($color-button-primary);
    }
    
    &_secondary {
        @extend .button;

        border-radius: 5px;
        background: $color-button-secondary;
        font-size: 12px;
        font-family:$header-font;
 
        &:active {
            background: $color-button-secondary-active;
        }

        &:hover {
            background: $color-button-secondary-hover;
        }

        &_lower-right {
            @extend .button_secondary;

            position: absolute;
            bottom: 20px;
            right: 20px;
        }
        
        &_next-to {
            @extend .button_secondary;
            
            @include when(desktop) {
                margin-left: 16px;
            }
        }
        
        &_order {
            @extend .button_secondary;

            height: 78px;
            line-height: 78px;

            &_total {
                @extend .button_secondary_order;

                font-size: 100%;
            }
        }

        &[type="submit"] {
            border:none;
        }
    }

    &__toggle{
        display: none;
        line-height: 30px;
        padding: 0 30px;
        font-size: 14px;

        &.inactive {
            display: inline-block;
        }
    }

    &__filter {
        display: inline-block;
        line-height: 30px;
        padding: 0 30px;
        font-size: 14px;

        &_paging {
            @extend .button__filter;

            padding: 0 10px;

            &.selected {
                padding: 0 10px;
            }
        }

        &.selected {
            //@extend .button__filter;

            text-transform: none;
            //font-size: 14px;
            color: $White;
        }
    }

    &__paging {
        @include trans(0.1s ease-in-out);
        background: $color-primary;
        border-radius: 3px;
        color: $White;
        padding: 6px;
        display: flex;
        margin-top: 30px;
        flex-direction: row;
        flex-wrap: nowrap;
        text-transform: uppercase;
        font-family: $header-font;
        font-size: 12px;
        font-weight: 400;

        &:hover {
            background: $color-button-primary-hover;
        }
    }

    &__paging-group {
        &_wide {
            flex: 3 1 auto;
            text-align: left;
            position: relative;
        }

        &_normal {
            flex: 1 1 auto;
            text-align: center;
        }
    }

    &__standard {
        @include trans(0.1s ease-in-out);

        border: 1px solid $Black;
        color: $Black;
        display: inline-block;
        height: 26px;
        line-height: 25px;
        padding: 0 15px;
        font-size: 12px;
        font-family: $header-font;
        text-transform: uppercase;

        &_name {
            font-weight: 900;
        }

        &_compare {
            @extend .button__standard;

            text-align: center;
            width: 100%;
            margin-bottom: 5px;
            height: 30px;
            line-height: 30px;

            &:hover {
                border-color: $color-primary;
                background-color: $color-primary;
                color: $White;
            }

            &.selected {
                //@extend .button__standard;

                &:hover {
                    border-color: $color-primary;
                    background-color: $color-primary;
                    color: $White;
                }
            }
        }
    }

    &__outline {
        @include trans(0.1s ease-in-out);

        border-radius: 5px;
        background: $White;
        display: inline-block;
        font-size: 12px;
        font-family: $header-font;
        padding: 0 30px;
        text-transform: uppercase;
        height: 30px;
        line-height: 26px;

        &_grey {
            @extend .button__outline;

            border: 2px solid $ConcreteGrey;
            color: $ConcreteGrey;

            &:hover {
                background: $ConcreteGrey;
                color: $White;
            }
        }

        &_secondary {
            @extend .button__outline;

            border: 2px solid $color-button-secondary;
            color: $color-button-secondary;

            &:hover {
                background: $color-button-secondary;
                color: $White;
            }
        }

        &_filter {
            @extend .button__outline;

            border: 2px solid $color-button-secondary;
            color: $color-button-secondary;
            height: auto;
            padding: 0;
            line-height: 20px;
            text-align: center;
            width: 100%;

            &:hover {
                background: $color-button-secondary;
                color: $White;
            }
        }
    }

    &__question {
        display: inline-block;
        height: 25px;
        width: 25px;
        border: 1px solid $MiddleGrey;
        border-radius: 3px;
        padding: 3px 0 0 6px;
    }

    &_login {
        @extend .button;
        @include btn-theme($color-button-secondary);

        border-radius: 3px;
        padding: 9px 0;
        text-align: center;
        width: 100%;
    }

    &_table {
        @extend .button;

        border-radius: 5px;
        background: $color-button-secondary;
        line-height: 1;
        padding: 4px 5px;
        font-size: 14px;

        &:hover {
            background: $color-button-secondary-hover;
        }
    }

    &_buy {
        @extend .button;

        border-radius: 3px;
        background: $color-button-secondary;
        height: 26px;
        line-height: 26px;
        padding:0;
        text-align: center;
        width: 100%;
        font-weight: bold;

        @include when(mobile) {
            height: initial;
            display: inline-block;
            padding: 8px 16px;

            text-transform: uppercase;
            text-decoration: none;
        }

        &:hover {
            background: $color-button-secondary-hover;
        }
        &:focus:not(:hover) {
            background: darken($color-button-secondary, 10%);
        }
    }
}

.tooltip {
    position: relative;
    background: $White;
    border: 1px solid $MiddleGrey;
    border-radius: 4px;
    height: 26px;
    width: 26px;
    display: inline-block;
    text-align: center;
    text-transform: none;
    line-height: 26px;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 256px;
    background: $White;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    padding: 6px 12px;
    position: absolute;
    z-index: 1;
    opacity: 0;
    transition: opacity 1s;
    text-align: left;
    font-weight: normal;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 6px;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: $White transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.filter-list__checkbox_disabled:after {
    color: #999;
}

.filter-list__checkbox_disabled {
    color: #aaa;
}


//Preview css reset
.header>.tooltip, .title_container>.tooltip {
    position: unset;
    height: auto;
    width: auto;
    background: transparent;
    border: 0;
}
