.config-header{
	text-align: center;
	padding-bottom: 30px;
	min-height: 135px;

	@include when(mobile) {
		min-height:auto;
	}

& h3{
	margin-top:20px;
	margin-bottom:40px;
	font-weight: 600;
}

& h4{
	font-size:20px;	
	font-weight: 600;
}




}