.flex-table {
    &__row_article-header{
        font-size: 12px;
        font-weight: $base__font-weight-bold;
        text-align: left;
    }

    &__row_article{
        font-size: 12px;
        font-weight: $base__font-weight-regular;
        min-height: 33px;
        
        @include when(tablet, desktop) {
            height: auto;
        }
    }

    &__item {
        padding: 0 2px;

        &_small {
            @extend .flex-table__item;
            flex: 0 1 35px;
        }
        &_shrink {
            @extend .flex-table__item;
            flex: 1 1 120px;
        }

        @include when(mobile) {
            padding: 2px 10px;

            &::before,
            &::after {
                // display: none;
            }
        }
    }
}