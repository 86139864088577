input {
    border-radius: 5px;
    border: 1px solid $MiddleGrey;
    padding: 0 15px;
    height: 40px;
    color: #333;
    font-family: $base__font-family;
    font-size: 14px;
    max-width: 100%;
}

input[type=text].js-cke-input,
input[type=number].js-cke-input,
input[type=date].js-cke-input,
input[type=password].js-cke-input,
input[type=search].js-cke-input,
input[type=tel].js-cke-input,
input[type=url].js-cke-input,
input[type=time].js-cke-input,
input[type=week].js-cke-input,
input[type=email].js-cke-input {
    max-width: 400px;
    width: 100%;
}

textarea {
    border-radius: 5px;
    border: 1px solid $MiddleGrey;
    padding: 10px 15px;
    color: #333;
    font-family: $base__font-family;
    font-size: 14px;
    resize: vertical;
    min-height: 85px;
}

textarea.js-cke-input {
    max-width: 400px;
    width: 100%;
    margin-bottom: 0;
}

input[type=text].js-cke-input,
input[type=number].js-cke-input,
input[type=date].js-cke-input,
input[type=password].js-cke-input,
input[type=search].js-cke-input,
input[type=tel].js-cke-input,
input[type=url].js-cke-input,
input[type=time].js-cke-input,
input[type=week].js-cke-input,
input[type=email].js-cke-input,
textarea.js-cke-input {
    &.form-validation-error {
        border-color: $LightRed;
    }
}

.js-cke-submit:disabled,
.js-cke-input:disabled {
    opacity: 0.5;
    pointer-events: none;
}

.form-validation-error-message {
    font-size: 12px;
    margin-top: 2px; 
    color: $LightRed;

    .fa {
        font-size: 1.2em;
        font-style: normal !important;
        margin-right: 3px;
    }
}

.ua-iOS {
    input {
        -webkit-appearance: none;
    }
}

.loggin {
    width: 100%;
    @include when (tablet, desktop) {
        max-width: 300px; 
    }
}