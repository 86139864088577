.config-orderdone{
	max-width: 650px;
	width:100%;
	padding:5%;
	margin-top:50px;
	border:4px solid #d3d3d3;
	background: #f5f5f5;
	text-align: center;
	box-sizing:border-box;


	@include when(mobile) {
		margin-top:0;
	}

	&_error{
		border:4px solid red;
	}

	&__title{
		font-size:35px;
		font-weight: bold;

		&_error{
			font-size:35px;
			font-weight: bold;
			color:red;		
		}
	}
	&__message{
		margin-bottom:30px;
	}
	&__ordernumber{
		font-size: 25px;
	}
}