.hero-carousel {

    &_full-height {
        height: calc(100vh - 150px);
    }

    &__item {
        &__pre-title {
            display: block;
            padding: 0;
            background-color: transparent;
            color: $color-secondary;
            text-shadow: 0px 2px 9px rgba(0,0,0,.3);
            line-height: 0.95em;
        }

        &__title {
            margin-top: 25px;
            text-shadow: 0px 2px 9px rgba(0,0,0,.3);
            line-height: 0.95em;

            @include when(tablet, desktop) {
                font-size: 45px;
            }
        }

        &__sub-title {
            font-family: $base__font-family;
            font-weight: $base__font-weight-regular;
        }

        &__cta {
            @extend .button_secondary;
            font-size: 16px;
            margin-top: 35px;

            display: inline-block;

            &_border {
                background-color: transparent;
                border: 2px solid $White;

                &:hover {
                    background-color: hsla(0,0%,100%,.2) !important;
                    border: 2px solid $White !important;
                }
            }
        }
    }

    &__nav {
        background-color: rgba(255, 255, 255, 0);

        &:hover {
            background-color: rgba(255, 255, 255, 1);
            color: $color-secondary;
        }
    }


    &__dotts {
        &__dott {
            box-shadow: 0px 2px 10px 1px rgba(0,0,0,0.5);

            &.current {
                background-color: $color-secondary;
            }
        }
    }

    &__video {
        &__close {
            &:hover .fa {
                color: $color-secondary;
            }
        }
    }
}
