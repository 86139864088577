.filter {
    &__container {
        position: relative;
        background: $White;
        padding: 0 15px 15px;
        margin: 0 20px 0 0;

        .filter-mobile {
            display: none;  
        }

        @include when(mobile) {
            margin: 0;
            padding: 0;
            position: absolute;
            z-index: 1005;
            left: -100%;
            top: 0;
            width: 50%;
            height: 100%;
            background-color: $Black;
            border-radius: 0;
            transition: left .3s;
            touch-action: none;

            &__scroll-wrapper {
                margin: 0;
                padding: 0;
                position: fixed;
                z-index: 1005;
                left: -100%;
                top: 0;
                width: 50%;
                height: 100%;
                background-color: $Black;
                border-radius: 0;
                transition: left .3s;
                touch-action: none;

                &__scroll-content {
                    overflow-y: scroll;
                    padding-top: 92px;
                    padding-bottom: 45px;
                    touch-action: pan-y;
                    height: 100%;
                }
            }

            &.show {
                .filter__container__scroll-wrapper {
                    left: 0;
                }
            }

        }
    }

    &__expand {
        width:100%;
        height:24px;
        background:$MiddleGrey;
        text-align: center;
        padding-top: 2px;
        color: #fff;
    }

    &__wrapper {
        position: relative;
    }

    &__main {
        font-size: 14px;
        margin-top:10px;
    }

    &__top {
        overflow:hidden;
        width:100%;
    }

    &__bottom {
        overflow: hidden;
        font-size: 14px;
        padding-bottom: 15px;
    }

    &__results {
        float:left;
        margin-top: 5px;
        color: $Black;
        font-size: 14px;

        @include when(mobile) {
            float: right;
            margin-top: 20px;
        }
    }

    &__border {
        border: 1px solid black;
        margin: 0 2px 0;
    }

    &__settings {
        float:right;
        margin: 0 -6px 0 0;
    }

    &__summary {
        border-radius: 9px;
        background: $White;
        padding: 0 15px 15px;
        margin-bottom: 20px;
    }

    &__content{
        border-radius: 9px;
        background: $White;
        padding: 15px;
        margin-bottom: 50px;
    }

    &__angle {
        float: right;
        color: $SilverGrey;

        i {
            line-height: 15px;
        }

        @include when(tablet, desktop) {
            position: absolute;
            top: 2px;
            right: 0;
        }
    }

    &__search {
        font-size: 14px;
        margin-bottom: 20px;
        color: $Black;

        &_title {
            font-weight: 900;
            text-transform: uppercase;
        }
    }

    &__suggestion {
        font-size: 14px;
        margin-top: 20px;
        color: $Black;

        &_title {
            font-weight: 900;
            font-size: 20px;
            margin-top: 5px;
        }
    }

    &__search-result {
        color: $color-link-hover;

        &:hover {
             color: darken($color-link-hover, 12%);
        }
    }

    &__select-product-group {
        font-size: 12px;
        padding: 8px 10px;
        color: darken($White, 50%);
    }

    &__mobile-no-results {
        font-weight: bold;
        text-align: center;

        small {
            font-weight: normal;
            display: block;
            font-size: 85%;
        }
    }

    &__mobile-filter-button {
        display: block;
        width: 100%;
        line-height: 46px;
        height: 50px;
        padding: 0 15px;
        font-family: $header-font;
        text-transform: uppercase;
        text-align: center;
        color: $White;
        font-size: 16px;
        background-color: $color-primary;
        border-top: 1px solid $White;
    }

    &__mobile-spinner {
        @include when(mobile) {
            background-color: rgba(255,255,255,.7);
            position: fixed;
            top: 200%;
            left: 0;
            height: 100%;
            width: 100%;
            transition: opacity .3s 0s, top 0s .3s;
            opacity: 0;
            z-index: 3000;

            &.show {
                transition: opacity .3s;
                opacity: 1;
                top: 0;
            }

            div {
                position: absolute;
                width: 60px;
                height: 60px;
                left: 50%;
                top: 50%;
                margin-top: -30px;
                margin-left: -30px;
            }
        }
    }

    &__mobile-filter-actions {
        position: fixed;
        bottom: -1000%;
        z-index: 1200;
        width: 100%;
        left: 0;
        height: 47px;

        &.show {
            bottom: 0;
        }

        a {
            @extend .filter__mobile-filter-button;

            width: 50%;
            float: left;

            &.black-button {
                background-color: $Black;
            }
        }
    }

    &__settings-mobile {
        &_backdrop {
            position: fixed;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.6);
            top: -1000%;
            z-index: 5;
            left: 100%;
            transition: opacity .3s 0s, top 0s .3s;
            touch-action: none;
            opacity: 0;

            &.show {
                opacity: 1;
                top: 0;
                left: 0;
                transition: opacity .3s 0s, top 0s 0s;
            }
        }

        &_button {
            @extend .filter__mobile-filter-button;

            position: absolute;
        }

        &_filter-buttons {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 45px;

            a {
                float: left;
                width: 50%;
            }
        }

        &_filters {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 50%;
            height: 47px;
            z-index: 1001;

            &_button {
                background-color: $color-primary;
            }

            &_scrollable {
                position: fixed;
                width: 100%;
                height: 100%;
                top: 0;
                left: 1000%;
                overflow: scroll;
                transition: left 0s .3s;
            }

            &.show &_scrollable {
                transition: left 0s;
                left: 0;
            }

            &_container {
                position: absolute;
                top: 0;
                min-height: 100%;
                width: 50%;
                background-color: $Black;
                padding-top: 60px;
                padding-bottom: 45px;
                margin-left: -100%;
                left: -1000%;
                transition: opacity .3s 0s, margin-left .3s 0s, left 0s .3s;
                touch-action: pan-y;
            }

            &.show &_container {
                transition: opacity .3s, margin-left .3s;
                left: 0;
                opacity: 1;
                margin-left: 0px;
            }
        }

        &_sorting {
            position: fixed;
            bottom: 0;
            right: 0;
            width: 50%;
            height: 47px;
            z-index: 1000;

            &_button {
                background-color: $Black;
            }

            &_list {
                position: absolute;
                bottom: -1000%;
                margin-bottom: -100%;
                opacity: 0;
                transition: opacity .3s 0s, margin-bottom .3s 0s, bottom 0s .3s;
                width: 100%;

                li {
                    border-bottom: 1px solid $White;

                    a {
                        display: block;
                        line-height: 46px;
                        padding: 0 15px;
                        color: $White;
                        background-color: $Black;
                    }
                }
            }

            &.show &_list {
                transition: opacity .3s, margin-bottom .3s;
                bottom: 100%;
                opacity: 1;
                margin-bottom: -1px;
            }

            &.disabled &_button {
                pointer-events: none;
                touch-action: none;
                background-color: lighten($Black, 40%);
                color: darken($White, 50%);
            }
        }
    }

    &__mobile-product-group {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 47px;
        z-index: 1101;

        &_button {
            @extend .filter__mobile-filter-button;

            position: absolute;
            width: 100%;
            background-color: $color-primary;
            z-index: 5;

            .is-open {
                display: none;
            }
        }

        &_list {
            position: fixed;
            width: 100%;
            height: 100%;
            top: 100%;
            transition: top .3s;
            overflow: scroll;

            ul {
                right: 0 !important;
                color: $White;
                background-color: $Black;

                li {
                }
            }
        }

        &.show &_button {
            .is-open {
                display: block;
            }
            .is-closed {
                display: none;
            }
        }

        &.show &_list {
            top: 0;
        }
    }
}
