// Grid system

// Generate the B columns
@mixin make-column($multiplier) { 
    width: percentage($multiplier);
    float: left;
    transition: width .5s ease-in-out;
}

@mixin make-columns($class) {
    @for $i from 1 through $grid-columns {
        $multiplier: $i / $grid-columns;
        .#{$class}#{$i} {  
            @include make-column($multiplier); 
        }
    }
}

@mixin setup-columns($class) {
    @for $i from 1 through $grid-columns {
        .#{$class}#{$i} {
            @extend %column;
        }
    }
}