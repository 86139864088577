
.filter-list{
    padding-top: 10px;
    padding-bottom: 5px;
    padding-left:1em;
    width:100%;
    display: table;
    width: 100%;

    @include breakpoint($RWD-A) {
        padding: 10px;
        display: block;
    }

    &__category{
        text-align: left;
        display: table-cell;
        vertical-align: top;
        padding-left: 28px;
        width: 175px;
        padding-top: 17px;
        font-weight: bold;

        @include breakpoint($RWD-A) {
            display: block;
            vertical-align: initial;
            padding-left: 0;
            width: initial;
            padding-top: 0;
            margin-bottom: 10px;
        }
    }

    &__item{
        float: left;
        margin-right: 7px;
        margin-bottom: 5px;
        font-size: 14px;

        @include breakpoint($RWD-A) {
            float: none;
            margin-right: 0px;
            display: inline-block;
        }
    }

    &__separator {
        display: none;
    }

    &__checkbox {
        font-size: 13px;
        margin-bottom: 10px;

        @include when(tablet, desktop) {
            input[type=checkbox] + label:hover::before  {
                border-color: darken($MiddleGrey, 20%);
            }

            input[type=checkbox]:checked + label  {
                color: $color-font;

                &:hover::before {
                    border-color: transparent;
                }
            }
        }

        &_disabled {
            pointer-events: none;
            touch-action: none;
        }

        input[type=checkbox]:checked + &_disabled {
            pointer-events: auto;
            touch-action: auto;
        }

        &:last-child {
            margin-bottom: 0;
        }

        // REMAKE FOR MOBILE
        @include breakpoint($RWD-A) {
            margin: 0;
            display: block !important;

            label {
                padding: 0 !important;
                position: relative !important;
                font-family: inherit;
                text-transform: none;
                display: block !important;
                padding: 0 15px !important;
                line-height: 46px !important;
                border-bottom: 1px solid $White;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                &:before {
                    display: none !important;
                }
            }

            input[type=checkbox]:checked + label {
                font-weight: bold;
                color: $color-primary;
            }
        }
    }
}

.filter-bar {
    display: block;
    color: $Black;
    background: $White;
    padding: 15px 15px 15px 15px;
    font-size: 14px;
    font-weight: 900;
    text-transform: uppercase;
    line-height:  10px;
}

.filter-container {
    overflow: hidden;
    padding: 10px;
    margin-bottom: 20px;

    &__title {
        font-size: 15px;
        font-family: $header-font;
        text-transform: uppercase;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        position: relative;
        padding-right: 25px;
    }

    &__filter {
        float:left;
        width:88%;
    }

    @include when(mobile) {
        overflow: visible;
        position: relative;
        width: 100%;
        border: 0;
        margin: 0;
        padding: 0;
        border-radius: 0;
        background: $Black;
        color: $White;

        &__title {
            position: relative;
            font-family: inherit;
            text-transform: none;
            display: block;
            padding: 0 15px;
            line-height: 46px;
            border-bottom: 1px solid $White;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            &_sub {
                padding: 0 15px !important;
            }

            .filter-count {
                display: none;
                width: 24px;
                height: 24px;
                line-height: 24px;
                top: 10px;
                right: 10px;
                text-align: center;
                font-weight: bold;
                border-radius: 100%;
                font-size: 11px;
                color: $White;
                background-color: $color-primary;
                position: absolute;
            }

            .fa {
                display: block;
                position: absolute;
                right: 10px;
                top: 0;
                line-height: 42px;
            }
        }

        &__filter {
            position: fixed;
            top: 0;
            width: 50%;
            height: 100%;
            right: 100%;
            transition: right .3s;
            overflow: scroll;
            touch-action: pan-y;
            z-index: 10;

            &_content {
                position: absolute;
                display: block !important;
                width: 100%;
                background-color: lighten($Black, 20%);
                min-height: 100%;
                margin: 0;
                right: 100%;
                top: 0;
                transition: right .3s;
                padding-top: 92px;
                padding-bottom: 45px;
                box-shadow: 3px 0 15px 0px rgba(0, 0, 0, 0.4);

                li a {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                li.active {
                    font-weight: bold;
                    color: $color-primary;

                    a {
                        padding: 0 35px 0 15px;
                    }
                }
            }
        }

        &.show-count .filter-count {
            display: block;

            + .fa {
                display: none;
            }
        }

        &.show-count &__title {
            padding-right: 35px;
        }

        &.show &__title {
            background-color: lighten($Black, 20%);
        }

        &.show &__filter {
            right: 0;
            transition: right 0s 0s;
            z-index: 15;
        }

        &.show &__filter_content {
            right: 0;
        }
    }
    // /REMAKE FOR MOBILE
}
.filter__container.show .filter-container__filter {
    transition: right 0s .3s;
}

.filter__container.show .filter-container.show .filter-container__filter {
    transition: right 0s;
}

.filter-mobile {
    color: $White;

    &__link {
        display: block;
        position: relative;
        padding: 0 15px;
        line-height: 46px;
        border-bottom: 1px solid $White;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &_sub {
            padding: 0 15px !important;
        }

        .filter-count {
            display: none;
            width: 24px;
            height: 24px;
            line-height: 24px;
            top: 10px;
            right: 10px;
            text-align: center;
            font-weight: bold;
            border-radius: 100%;
            font-size: 11px;
            color: $White;
            background-color: $color-primary;
            position: absolute;
        }

        .fa {
            position: absolute;
            right: 10px;
            top: 0;
            line-height: 42px;
        }
    }
    &__list-wrapper {
        position: fixed;
        top: 0;
        width: 50%;
        height: 100%;
        right: 100%;
        transition: right .3s;
        overflow: scroll;
        touch-action: pan-y;

        &_content {
            position: absolute;
            width: 100%;
            background-color: lighten($Black, 20%);
            min-height: 100%;
            right: 100%;
            top: 0;
            transition: right .3s;
            padding-top: 93px;
            padding-bottom: 45px;
            box-shadow: 3px 0 15px 0px rgba(0, 0, 0, 0.4);

            li a {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            li.active {
                font-weight: bold;
                color: $color-primary;

                a {
                    padding: 0 35px 0 15px;
                }
            }
        }
    }

    &.show-count .filter-count {
        display: block;

        + .fa {
            display: none;
        }
    }

    &.show-count &__link {
        padding-right: 35px;
    }

    &.show &__link {
        background-color: lighten($Black, 20%);
    }

    &.show &__list-wrapper {
        right: 0;
        transition: right 0s 0s;
        z-index: 5;
    }

    &.show &__list-wrapper_content {
        right: 0;
    }
}

.filter__settings-mobile_filters.show .filter-mobile__list-wrapper {
    transition: right 0s .3s;
}

.filter__settings-mobile_filters.show .filter-mobile.show .filter-mobile__list-wrapper {
    transition: right 0s;
}

.filter-nav {
    &__item{
        font-size: 13px;
        padding-left: 20px;

        &_title {
            @extend .filter-nav__item;

            font-weight: 900;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    &__expand {
        padding-top: 20px;
        font-size: 13px;
        font-weight: 900;
    }
}
