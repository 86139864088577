.mandatorie{
    /*color:#cd1010;
    font-size: 24px;*/
    &_label{
        @extend .mandatorie;
        display: table-cell;
    }        
    &_order{
        @extend .mandatorie;
        position: absolute;
        left: 1px;
        top: 5px;
    }    
}