.hero-carousel {
    position: absolute;
    height: 45vh;
    min-height: 400px;
    width: calc(100vw - (100vw - 100%));
    left: 0;

    &__outer-wrapper {
        height: 45vh;
        min-height: 400px;
    }

    &_full-height {
        height: calc(100vh - 200px);
        min-height: 540px;
    }

    &__wrapper {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        overflow: hidden;
        background-color: $Black;
    }

    // New img-tag solution
    &__item-background-img {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 0;
        object-fit: cover;
        left: 0;
        top: 0;
    }

    // Old bg image solution - now only wrapper
    &__item-background {
        position: absolute;
        left: -200%;
        top: 0;
        height: 100%;
        width: 100%;

        $slider-animation-duration: 1s;

        &.current {
            left: 0;
        }

        &.next {
            &_in {
                left: 100%;

                &_anim {
                    transition: left $slider-animation-duration;
                    left: 0;
                }
            }
            &_out {
                left: 0;

                &_anim {
                    transition: left $slider-animation-duration;
                    left: -100%;
                }
            }
        }

        &.prev {
            &_in {
                left: -100%;

                &_anim {
                    transition: left $slider-animation-duration;
                    left: 0;
                }
            }
            &_out {
                left: 0;

                &_anim {
                    transition: left $slider-animation-duration;
                    left: 100%;
                }
            }
        }
    }

    &__item {
        height: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        opacity: 0;
        transition: all .3s;
        transform: translateY(-10px);
        max-width: 1280px;
        padding: 0 80px;

        &_placement-left {
            align-items: flex-start;
            text-align: left;
        }

        &_placement-right {
            align-items: flex-end;
            text-align: right;
        }

        &_placement-center {
            align-items: center;
            text-align: center;
        }

        &__pre-title,
        &__title {
            display: block;
            flex: 0 1 auto;
            //max-width: 600px;
            font-size: 60px;
            color: $White;
            padding: 0;
        }

        &__pre-title {
            display: inline-block;
            background-color: $color-primary;
            padding: 7px 10px;
            font-size: 30px;
        }

        &__title {
            margin-top: 10px;

            &_video-only {
                text-shadow: 0px 0px 20px rgba(0,0,0,0.5);

                .fa {
                    vertical-align: middle;
                    font-size: 1.2em;
                    transition: color .3s;
                    margin-top: -6px;

                    @include when(desktop, tablet) {
                        margin-right: 15px;
                        font-size: 1.6em;
                    }
                }

                a:hover .fa {
                    color: $color-primary;
                }
            }
        }

        &__sub-title {
            display: block;
            flex: 0 1 auto;
            color: $White;
            margin-top: 10px;
            font-size: 18px;
            font-weight: 400;
        }

        &__cta {
            margin-top: 25px;
            cursor: pointer;
            font-size: 20px;
            padding: 8px 20px;
            text-transform: uppercase;
            display: block;
            flex: 0 1 auto;

            @include when(desktop, tablet) {
                margin-left: 25px;

                &:first-child {
                    margin-left: 0;
                }
            }

            .fa {
                font-size: 85%;
                margin-left: 10px;
            }

            &_border {
                background-color: transparent;
                border: 2px solid $White;

                &:hover {
                    background-color: rgba(0, 0, 0, 0.1);
                    border: 2px solid $White;
                }
            }
        }

        @include when(mobile) {
            padding-bottom: 60px;

            &__pre-title,
            &__title {
                font-size: 30px;
            }

            &__cta {
                width: 100%;
                text-align: center;
            }
        }

        &_full-width {
            .hero-carousel__item__pre-title,
            .hero-carousel__item__title,
            .hero-carousel__item__sub-title {
                max-width: 100%;
            }
        }
    }

    &__item-background.current &__item {
        opacity: 1;
        transform: translateY(0);
    }

    &__nav {
        position: absolute;
        width: 50px;
        height: 50px;
        border: 2px solid $White;
        border-radius: 100%;
        overflow: hidden;
        color: $White;
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0);
        transition: background-color .3s, opacity .3s;

        &:hover {
            background-color: rgba(255, 255, 255, 1);
            color: $color-primary;
        }

        &.disabled {
            opacity: .3;
            pointer-events: none;
            background-color: rgba(255, 255, 255, 0);
            color: $White;
        }

        .fa {
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            font-size: 35px;
            text-align: center;
            transition: all .3s;

            &:before {
                line-height: 1;
            }
        }

        &_prev {
            top: 50%;
            margin-top: -25px;
            left: 15px;

            .fa {
                line-height: 46px;
                padding-right: 4px;

                &:first-child { left: 0; }
                &:last-child  { left: 100%; }
            }

            &:hover {
                .fa {
                    &:first-child { left: -100%; }
                    &:last-child  { left: 0; }
                }
            }
        }

        &_next {
            top: 50%;
            margin-top: -25px;
            right: 15px;

            .fa {
                line-height: 46px;
                padding-left: 4px;

                &:first-child { right: 0; }
                &:last-child  { right: 100%; }
            }

            &:hover {
                .fa {
                    &:first-child { right: -100%; }
                    &:last-child  { right: 0; }
                }
            }
        }

        &_down {
            left: 50%;
            margin-left: -25px;
            bottom: 15px;

            .fa {
                line-height: 50px;

                &:first-child { bottom: 0; }
                &:last-child  { bottom: 100%; }
            }

            &:hover {
                .fa {
                    &:first-child { bottom: -100%; }
                    &:last-child  { bottom: 0; }
                }
            }
        }
    }

    &__dotts {
        position: absolute;
        bottom: 80px;
        height: 16px;
        width: 100%;
        display: flex;
        justify-content: center;

        &__dott {
            width: 16px;
            height: 16px;
            cursor: pointer;
            border-radius: 100%;
            background-color: $White;
            transform: scale(.7);
            margin: 0 4px;
            transition: all .15s;
            border: 2px solid $White;

            &:hover {
                transform: scale(.85);
            }

            &.current {
                transform: scale(1);
                background-color: $color-primary;
            }
        }
    }

    &_hide-down-arrow {
        .hero-carousel__nav_down {
            display: none;
        }

        .hero-carousel__dotts {
            bottom: 20px;
        }
    }

    &__video {
        display: flex;
        position: fixed;
        z-index: 12000;
        background-color: rgba(0,0,0,.6);
        width: 100%;
        height: 100%;
        justify-content: center;
        top: 0;
        left: 1000%;
        opacity: 0;
        transition: left 0s .3s, opacity .3s 0s;

        &.show {
            left: 0;
            opacity: 1;
            transition: left 0s 0s, opacity .3s 0s;
        }

        &__close {
            position: absolute;
            top: -30px;
            right: -30px;
            font-size: 24px;
            height: 40px;
            width: 40px;
            border-radius: 100%;
            overflow: hidden;
            background-color: rgba(255,255,255,0);
            transition: all .3s;

            .fa {
                line-height: 40px;
                text-align: center;
                width: 100%;
                color: white;
                transition: all .3s;
                border-radius: 100%;
            }

            &:hover {
                cursor: pointer;
                background-color: white;

                .fa {
                    color: $color-primary;
                }
            }
        }

        &__player-wrapper {
            position: relative;
            width: 1080px;
            background-color: rgba(0,0,0,.6);
            border-radius: 10px;
            align-self: center;
        }

        &__player {
            position: relative;
            padding-bottom: 56.481481481%;
            height: 0;
            width: 100%;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                padding: 15px;
            }
        }
    }
}
/*
@include when(desktop) {
      .rowContainer .row > .col6.acol12.ccol6:nth-of-type(1) .hero-carousel__outer-wrapper > .hero-carousel {
            width: 50%;
            left: 0;
            right: auto;
          }

         .rowContainer .row > .col6.acol12.ccol6:nth-of-type(2) .hero-carousel__outer-wrapper > .hero-carousel {
            width: 50%;
            left: auto;
            right: 0;

         }
}

@include when(tablet) {
      .rowContainer .row > .col6.acol12.ccol6:nth-of-type(1) .hero-carousel__outer-wrapper > .hero-carousel {

            width: 100%;
            //display: none;
            clear: both;
            //right: calc(100% - 50vw)!important;
            //right: auto;
          }

         .rowContainer .row > .col6.acol12.ccol6:nth-of-type(2) .hero-carousel__outer-wrapper > .hero-carousel {
            width: 100%;
            //left: auto;
            //display: none;
            clear: both;
            //right: calc(100% - 50vw)!important;

         }
}
*/
