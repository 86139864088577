.interactive-map {

    &__header {
        color: $color-primary;
        margin-bottom: 25px;
    }

    &__zone {
        fill: lighten($color-primary, 50%);
        stroke: lighten($color-primary, 33%);
        stroke-width: 1px;
        cursor: pointer;
        transition: .3s;

        &:hover {
            fill: lighten($color-primary, 17%);
            stroke: lighten($color-primary, 17%);
        }

        &.selected {
            fill: $color-primary;
            stroke: $color-primary;
        }
    }

    &__area-label {
        display: none;
        text-align: center;
        padding: 4px 8px;
        line-height: 1;
        font-size: 15px;
        border-radius: 3px;
        background-color: rgba(0,0,0,.6);
        color: white;
        position: fixed;
        line-height: 1.4;
        pointer-events: none;

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            margin-top: -5px;
            left: -5px;
            width: 0;
            height: 0;
            border-bottom: 5px solid transparent;
            border-top: 5px solid transparent;
            border-right: 5px solid rgba(0,0,0,.6);
        }
    }

    &__canvas {
        height: 500px;
    }

    &__canvas-wrapper {
        float: left;
        margin-right: 45px;
        position: relative;

        @include when(mobile, tablet) {
            display: none;
        }

        &::after {
            text-align: center;
            content: attr(data-label);
            z-index: 10;
            padding: 10px;
            line-height: 1;
            font-size: 20px;
            top: 40%;
            left: 50%;
            width: 150px;
            margin-left: -85px;
            border-radius: 25px;
            background-color: rgba(0,0,0,.6);
            color: white;
            position: absolute;
            opacity: 1;
            transition: .2s;
            pointer-events: none;
        }

        &:hover::after {
            opacity: 0;
        }
    }

    &__personnel-wrapper {
        padding-top: 10px;

        @include when(desktop) {
            display: none;

            &_show {
                display: block;
            }
        }
    }

    &__personnel-lists {
        overflow: hidden;
    }

    &__counties {
        margin-bottom: 20px;

        @include when(mobile) {
            font-size: 1.5rem;
        }        
    }

    &__personnel {
        margin-bottom: 25px;
        font-weight: normal;

        &_show {
            display: block;
        }
    
        &__title {
            font-size: 18px;
            color: $color-primary;
            text-transform: uppercase;
            margin-bottom: 3px;
            padding-top: 1px;
            font-weight: bold;

        }

        &__name {
            // margin-bottom: 4px;
        }

        &__attr {
            display: block;
            position: relative;
            padding-left: 27px;
            word-wrap: break-word;
            // margin-bottom: 4px;

            .fa {
                position: absolute;
                width: 17px;
                text-align: center;
                left: 0;
                top: 4px;
                color: $color-primary;
            }
        }

        a.interactive-map__personnel__attr {
            &:hover {
                color: $color-primary;
            }
        }

        &__info {
            overflow: hidden;
        }

        &__image {
            float: left;
            height: 100px;
            width: 100px;
            margin-right: 15px;
            border-radius: 5px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}
