/**
 * Grid system
 *
 * Content:
 *   1. Examples
 *   2. Generic input mixins
 *   3. Generic input classes
 *   4. Legacy
 */


// ------------------------------
// 1. Examples
// ------------------------------

/*
<!-- HTML element examples: -->

    <div class="bb-row bb-row_no-gutter">
        <div class="bb-col bb-col_4 bb-col_sm-12"></div>
        <div class="bb-col bb-col_4 bb-col_sm-12"></div>
        <div class="bb-col bb-col_4 bb-col_sm-12"></div>
    </div>

<!-- Visual examples: -->

    ╔═══════════════════════════════════════╗
    ║               bb-col_12               ║
    ╠═══════════════════╦═══════════════════╣
    ║     bb-col_6      ║      bb-col_6     ║
    ╠════════════╦══════╩══════╦════════════╣
    ║  bb-col_3  ║  bb-col_3   ║  bb-col_3  ║
    ╠════════╦═══╩═════════════╩════════════╣
    ║bb-col_2║          bb-col_10           ║
    ╚════════╩══════════════════════════════╝
*/


// ------------------------------
// 2. Grid mixins
// ------------------------------

/**
 * Grid row
 */
@mixin fw-row {
    position: relative;
    margin: 0 #{-$fw-grid-gutter-lg};

    @include breakpoint($fw-breakpoint-md) {
        margin: 0 #{-$fw-grid-gutter-md};
    }

    @include breakpoint($fw-breakpoint-sm) {
        margin: 0 #{-$fw-grid-gutter-sm};
    }

    @include breakpoint($fw-breakpoint-xs) {
        margin: 0 #{-$fw-grid-gutter-xs};
    }

    &::before,
    &::after {
        content: '';
        display: block;
        clear: both;
    }
}

@mixin fw-row-no-gutter {
    margin: 0;

    > .#{$fw-class-prefix}-col {
        padding: 0;
    }
}

/**
 * Grid column
 */
@mixin fw-column-base {
    float: left;
    padding: 0 $fw-grid-gutter-lg;
    width: 100%;

    @include breakpoint($fw-breakpoint-md) {
        padding: 0 $fw-grid-gutter-md;
    }

    @include breakpoint($fw-breakpoint-sm) {
        padding: 0 $fw-grid-gutter-sm;
    }

    @include breakpoint($fw-breakpoint-xs) {
        padding: 0 $fw-grid-gutter-xs;
    }
}

/**
 * Grid column width
 *
 * Generates X columns with given post-fix.
 */
@mixin fw-generate-columns() {
    @for $i from 1 through $fw-grid-column-count {
        &#{$i} {
            width: percentage($i / $fw-grid-column-count);
        }
    }
}

// ------------------------------
// 3. Grid classes
// ------------------------------

.#{$fw-class-prefix}-row {
    @include fw-row;

    &_no-gutter {
        @include fw-row-no-gutter;
    }
}

.#{$fw-class-prefix}-col {
    @include fw-column-base;

    &_, &_lg- {
        @include fw-generate-columns;
    }

    @include breakpoint($fw-breakpoint-md) {
        &_md- {
            @include fw-generate-columns;
        }
    }

    @include breakpoint($fw-breakpoint-sm) {
        &_sm- {
            @include fw-generate-columns;
        }
    }

    @include breakpoint($fw-breakpoint-xs) {
        &_xs- {
            @include fw-generate-columns;
        }
    }
}


// ------------------------------
// 4. Legacy
// ------------------------------
