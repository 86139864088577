.media-container {
    &__left-box {
        &_border {
            @include when(desktop) {
                @include border-dotts(0 7px 0 0);
            }
        }
    }
    &__right-box {
        &_info {
            @include border-dotts(0 0 0 7px);
        }
    }
    &__border {
        @include border-dotts(9px 0 0 0);

        &_info,
        &_box {
            @include border-dotts(7px 0 0 0);
        }
    }

    &__title {
        font-weight: 900;
    }

    &__long-desc {
        p, ul, ol {
            font-weight: $base__font-weight-regular;
        }

        strong, b {
            font-weight: $base__font-weight-bold;
        }

        table {
            width: 100%;
        }
    }
    
    &__help-text{
        font-size: 12px;
        line-height: 8px;
        margin-top: 4px;
        font-style: italic;
    }

    &__attrList_value,
    &__attrList_name {
        padding: 7px 5px;
        font-size: 12px;
        text-transform: none;
    }

    &__attrList_name {
        @include when(desktop) {
            width: auto;
            max-width: 70%;
        }
        font-weight: $base__font-weight-bold;
    }

    &__box-title {
        font-weight: $base__font-weight-bold;
        text-transform: none;
        font-size: 16px;
    }
    
    @include when(mobile) {   
        &__box-title {
            text-transform: uppercase;
            font-weight: 900;
        }
    }
}