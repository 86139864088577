@mixin border-dotts($width) {
    // Breaks in Safari on Mac
    // border-color: transparent;
    border-style: solid;
    border-width: 0;
    border-width: $width;
    border-image-source: url("../../skydda/images/black-dotted-border.svg");
    border-image-repeat: repeat;
    border-image-slice: 25% 25%;
}
