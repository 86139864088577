/* - Heebo font */

$font__path: '#{$font__root}/heebo';
$font__name: 'Heebo';

$font__faces: (
    Thin: (
        weight: 100,
        style: normal
    ),
    Light: (
        weight: 300,
        style: normal
    ),
    Regular: (
        weight: normal,
        style: normal
    ),
    Medium: (
        weight: 500,
        style: normal
    ),
    Bold: (
        weight: bold,
        style: normal
    ),
    ExtraBold: (
        weight: 800,
        style: normal
    ),
    Black: (
        weight: 900,
        style: normal
    )
);

@each $font__style, $font__properties in $font__faces {
    @font-face {
        font-family: $font__name;
        font-weight: map-get($font__properties, weight);
        font-style: map-get($font__properties, style);

        src: url('#{$font__path}/#{$font__name}-#{$font__style}.eot');
        src: url('#{$font__path}/#{$font__name}-#{$font__style}.eot?#iefix') format('embedded-opentype'),
             url('#{$font__path}/#{$font__name}-#{$font__style}.woff2') format('woff2'),
             url('#{$font__path}/#{$font__name}-#{$font__style}.woff') format('woff'),
             url('#{$font__path}/#{$font__name}-#{$font__style}.ttf') format('truetype'),
             url('#{$font__path}/#{$font__name}-#{$font__style}.svg##{$font__name}-#{$font__style}') format('svg');
    }
}
