.compare{
    &__wrapper{
        margin: 20px auto;
        &_2 {
            @extend .compare__wrapper;
            width: 60%;
        }
        &_3 {
            @extend .compare__wrapper;
            width: 80%;
        }
        &_4 {
            @extend .compare__wrapper;
            width: 100%;
        }
    }
    &__row{
        display: flex;
        align-items: center;
        padding: 3px 0;
        min-height: 33px;
        &:nth-child(odd){
            background: $LightGrey;
        }
        &:nth-child(even){
            background: $White;
        }
    }

    &__cell{
        flex: 1;
        font-size: 13px;
        padding: 0 20px;
        text-align: center;

        &:first-child{
            font-weight: 900;
            text-align: left;
        }
        &_product{
            @extend .compare__cell;
            padding: 20px;
            border: 1px solid transparent;
            background: $White;
            text-align: left;
            @include breakpoint($RWD-A) {
                padding-bottom: 0px;
                overflow:hidden;
            }
        }
        &_functions{
            @extend .compare__cell;
            position: relative;
            padding: 5px 10px 5px 5px;
        }
        &_img{
          max-height: 26px;
          vertical-align: middle;
        }
    }
    &__flag-container{
        position: absolute;
        top: 0;
        left: -10px;
    }
    &__image{
        position: relative;
        height: 170px;
        overflow: hidden;
        text-align: center;
        padding: 10px 10px 0 10px;
        & .image{
            height:100%;
        }
    }
    &__functions{
        position: relative;
        bottom: 0;
        padding: 5px 10px 0 5px;
        width: 100%;display: block;
    }

    &__title{
        padding-top: 10px;
        font-size: 18px;

        font-family: $header-font;
    }

    &__container{
        position: absolute;
        left: 0;
        margin-top: -4px;
        z-index: 10;
        width: 100%;
    }
}
