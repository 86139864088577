.filter-container {
    @include when(tablet, desktop) {
        border: 1px solid $SilverGrey;
        border-radius: 9px;
        background: $White;

        &__title {
            text-transform: none;
            font-weight: 600;
            margin-bottom: 4px;
            font-size: 14px;
            display: block;
        }
    }

    @include when(mobile) {
        background-color: $color-header-nav;
        color: $color-font;

        &__title {
            border-bottom: 1px solid $color-separator-border;
        }

        &.show .filter-container__title {
            background-color: $color-header-bar;
        }

        &__filter_content {
            background-color: $color-header-bar;
        }
    }
}

.filter-list {
    @include when(mobile) {
        &__checkbox label {
            border-bottom: 1px solid $color-separator-border;
        }
    }
}

.filter-nav {
    &__item_title {
        @include when(tablet, desktop) {
            font-weight: 600;
        }
    }

    &__item {
        margin-bottom: 5px;
    }

    &__expand {
        @include when(tablet, desktop) {
            font-weight: 600;
            font-size: 11px;

            a {
                margin-left: 2px;
            }
        }
    }

    &__category{
        & .filter-nav__item.hide:nth-child(-n + 9) {
            @include when(tablet, desktop) {
                display: block !important;
            }
        }
        & li:nth-child(-n + 11) .filter-nav__expand {
            @include when(tablet, desktop) {
                display: none;
            }
        }
    }

    &__subcategory li:nth-child(-n + 2) .filter-nav__expand {
        @include when(tablet, desktop) {
            display: none;
        }
    }
}

.filter-mobile {
    color: $White;

    &__list-wrapper_content {
        li:first-child {
            border-top: 1px solid $color-separator-border;
        }
    }

    &__link {
        border-bottom: 1px solid $color-separator-border;
    }
}