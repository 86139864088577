.login{
    position: relative;
    @include breakpoint($RWD-A) {
        position: static;
    }
    &__menu{
        background-color: $White;
        border-radius: 4px;
        position: absolute;
        padding: 10px;
        top: 10px;
        right: -30px;
        z-index: 15;
        width: 275px;
        text-align: left;
        box-shadow: 1px 2px 10px rgba(0,0,0,0.4);
        font-size: 14px;
        @include breakpoint($RWD-A) {
            top: 50px;
            right: 0px;
            width: 100%;
        }
    }
    &__links {
        display: block;
        cursor: pointer;
        margin-top: 6px;
        &:hover {
            text-decoration: underline;
        }
    }
    &__arrow {
        position: absolute;
        right: 12px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid $White;
        z-index: 16;
        @include breakpoint($RWD-A) {
            display: none;
        }
    }
    &__container{ /*Borde antagligen ligga någon annan stans*/
        background-color: $White;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
        border-radius: 4px;
        padding: 10px;
        width: 275px;
        text-align: left;
        &_big {
            @extend .login__container;
            width: 100%;
            padding: 20px;
        }
    }
}
.login__container{

    & .input_full-width{
        @include breakpoint($RWD-A) {
            height:46px;
            font-size: 20px;
        }        
        option{
            padding: 20px 0;
            /*background:red !important;*/
        }
    }
    & .input_full-width_select_languages{
        @include breakpoint($RWD-A) {
            height:46px;
            font-size: 14px;
        }        
    }
    
}
