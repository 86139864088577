/*!
 * jquery-confirm v2.5.1 (http://craftpip.github.io/jquery-confirm/)
 * Author: boniface pereira
 * Website: www.craftpip.com
 * Contact: hey@craftpip.com
 *
 * Copyright 2013-2016 jquery-confirm
 * Licensed under MIT (https://github.com/craftpip/jquery-confirm/blob/master/LICENSE)
 */
@-webkit-keyframes "jconfirm-rotate" {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes "jconfirm-rotate" {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-webkit-keyframes "hilight" {
    10%,90% {
        -webkit-transform: translate3d(-2px, 0, 0);
        transform: translate3d(-2px, 0, 0);
    }
    20%,80% {
        -webkit-transform: translate3d(4px, 0, 0);
        transform: translate3d(4px, 0, 0);
    }
    30%,50%,70% {
        -webkit-transform: translate3d(-8px, 0, 0);
        transform: translate3d(-8px, 0, 0);
    }
    40%,60% {
        -webkit-transform: translate3d(8px, 0, 0);
        transform: translate3d(8px, 0, 0);
    }
}
@keyframes "hilight" {
    10%,90% {
        -webkit-transform: translate3d(-2px, 0, 0);
        transform: translate3d(-2px, 0, 0);
    }
    20%,80% {
        -webkit-transform: translate3d(4px, 0, 0);
        transform: translate3d(4px, 0, 0);
    }
    30%,50%,70% {
        -webkit-transform: translate3d(-8px, 0, 0);
        transform: translate3d(-8px, 0, 0);
    }
    40%,60% {
        -webkit-transform: translate3d(8px, 0, 0);
        transform: translate3d(8px, 0, 0);
    }
}
.jconfirm {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999999;
    font-family: inherit;
    overflow: hidden;
    -webkit-perspective: 400px;
    perspective: 400px;
    .jconfirm-bg {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        -webkit-transition: all .4s;
        transition: all .4s;
    }
    .jconfirm-bg.seen {
        opacity: 1;
    }
    .jconfirm-scrollpane {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow-y: auto;
        -webkit-perspective: 500px;
        perspective: 500px;
        -webkit-perspective-origin: center;
        perspective-origin: center;
    }
    .jconfirm-box {
        background: #fff;
        border-radius: 4px;
        position: relative;
        outline: none;
        padding: 15px 15px 0;
        opacity: 1;
        -webkit-transition-property: -webkit-transform,opacity,box-shadow;
        transition-property: transform,opacity,box-shadow;
        div.closeIcon {
            height: 20px;
            width: 20px;
            position: absolute;
            top: 5px;
            right: 5px;
            cursor: pointer;
            opacity: .6;
            text-align: center;
            -webkit-transition: opacity .1s ease-in;
            transition: opacity .1s ease-in;
            display: none;
            font-size: 27px;
            line-height: 14px;
            .fa {
                font-size: 16px;
            }
            .glyphicon {
                font-size: 16px;
            }
            .zmdi {
                font-size: 16px;
            }
            &:hover {
                opacity: 1;
            }
        }
        div.title-c {
            display: block;
            font-size: 22px;
            line-height: 20px;
            .icon-c {
                font-size: inherit;
                padding-bottom: 15px;
                display: inline-block;
                margin-right: 8px;
                vertical-align: middle;
                i {
                    vertical-align: middle;
                }
                &:empty {
                    display: none;
                }
            }
            .title {
                font-size: inherit;
                font-family: inherit;
                display: inline-block;
                vertical-align: middle;
                padding-bottom: 15px;
                &:empty {
                    display: none;
                }
            }
        }
        div.content-pane {
            margin-bottom: 15px;
            height: auto;
            -webkit-transition: height .4s ease-in;
            transition: height .4s ease-in;
            display: inline-block;
            width: 100%;
            position: relative;
            .content {
                position: absolute;
                top: 0;
                left: 0;
                -webkit-transition: all .2s ease-in;
                transition: all .2s ease-in;
                right: 0;
                img {
                    width: 100%;
                    height: auto;
                }
                &:empty {
                    display: none;
                }
                &:empty.loading {
                    height: 40px;
                    position: relative;
                    opacity: .6;
                    display: block;
                    &:before {
                        content: '';
                        height: 20px;
                        width: 20px;
                        border: solid 2px transparent;
                        position: absolute;
                        left: 50%;
                        margin-left: -10px;
                        border-radius: 50%;
                        -webkit-animation: jconfirm-rotate 1s infinite linear;
                        animation: jconfirm-rotate 1s infinite linear;
                        border-bottom-color: #aaa;
                        top: 50%;
                        margin-top: -10px;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        left: 50%;
                        margin-left: -15px;
                    }
                }
            }
        }
        .buttons {
            padding-bottom: 15px;
            button+button {
                margin-left: 5px;
            }
        }
    }
    .jquery-clear {
        clear: both;
    }
    .jconfirm-box.hilight {
        -webkit-animation: hilight .82s cubic-bezier(.36, .07, .19, .97) both;
        animation: hilight .82s cubic-bezier(.36, .07, .19, .97) both;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    .jconfirm-box.anim-top {
        opacity: 0;
        -webkit-transform: translate(0, -100px);
        -ms-transform: translate(0, -100px);
        transform: translate(0, -100px);
    }
    .jconfirm-box.anim-left {
        opacity: 0;
        -webkit-transform: translate(-100px, 0);
        -ms-transform: translate(-100px, 0);
        transform: translate(-100px, 0);
    }
    .jconfirm-box.anim-right {
        opacity: 0;
        -webkit-transform: translate(100px, 0);
        -ms-transform: translate(100px, 0);
        transform: translate(100px, 0);
    }
    .jconfirm-box.anim-bottom {
        opacity: 0;
        -webkit-transform: translate(0, 100px);
        -ms-transform: translate(0, 100px);
        transform: translate(0, 100px);
    }
    .jconfirm-box.anim-opacity {
        opacity: 0;
    }
    .jconfirm-box.anim-zoom {
        opacity: 0;
        -webkit-transform: scale(1.2);
        -ms-transform: scale(1.2);
        transform: scale(1.2);
    }
    .jconfirm-box.anim-scale {
        opacity: 0;
        -webkit-transform: scale(.5);
        -ms-transform: scale(.5);
        transform: scale(.5);
    }
    .jconfirm-box.anim-none {
        opacity: 0;
        display: none;
    }
    .jconfirm-box.anim-rotate {
        opacity: 0;
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }
    .jconfirm-box.anim-rotatex {
        opacity: 0;
        -webkit-transform: rotateX(90deg);
        transform: rotateX(90deg);
        -webkit-transform-origin: center;
        -ms-transform-origin: center;
        transform-origin: center;
    }
    .jconfirm-box.anim-rotatey {
        opacity: 0;
        -webkit-transform: rotatey(90deg);
        -ms-transform: rotatey(90deg);
        transform: rotatey(90deg);
        -webkit-transform-origin: center;
        -ms-transform-origin: center;
        transform-origin: center;
    }
    .jconfirm-box.anim-scaley {
        opacity: 0;
        -webkit-transform: scaley(1.5);
        -ms-transform: scaley(1.5);
        transform: scaley(1.5);
        -webkit-transform-origin: center;
        -ms-transform-origin: center;
        transform-origin: center;
    }
    .jconfirm-box.anim-scalex {
        opacity: 0;
        -webkit-transform: scalex(1.5);
        -ms-transform: scalex(1.5);
        transform: scalex(1.5);
        -webkit-transform-origin: center;
        -ms-transform-origin: center;
        transform-origin: center;
    }
    .jconfirm-box.anim-rotatexr {
        -webkit-transform: rotateX(-90deg);
        transform: rotateX(-90deg);
        -webkit-transform-origin: center;
        -ms-transform-origin: center;
        transform-origin: center;
    }
    .jconfirm-box.anim-rotateyr {
        -webkit-transform: rotatey(-90deg);
        -ms-transform: rotatey(-90deg);
        transform: rotatey(-90deg);
        -webkit-transform-origin: center;
        -ms-transform-origin: center;
        transform-origin: center;
    }
}
.jconfirm.rtl {
    direction: rtl;
    div.closeIcon {
        left: 12px;
        right: auto;
    }
}
.jconfirm.jconfirm-white {
    .jconfirm-bg {
        background-color: rgba(0,0,0,0.2);
    }
    .jconfirm-box {
        box-shadow: 0 2px 6px rgba(0,0,0,0.2);
        border-radius: 5px;
        .buttons {
            float: right;
            button {
                border: none;
                background-image: none;
                text-transform: uppercase;
                font-size: 14px;
                font-weight: bold;
                text-shadow: none;
                -webkit-transition: background .1s;
                transition: background .1s;
                color: #fff;
            }
            button.btn-default {
                box-shadow: none;
                color: #333;
                &:hover {
                    background: #ddd;
                }
            }
        }
    }
}
.jconfirm.jconfirm-black {
    .jconfirm-bg {
        background-color: rgba(0,0,0,0.5);
    }
    .jconfirm-box {
        box-shadow: 0 2px 6px rgba(0,0,0,0.2);
        background: #444;
        border-radius: 5px;
        color: #fff;
        .buttons {
            float: right;
            button {
                border: none;
                background-image: none;
                text-transform: uppercase;
                font-size: 14px;
                font-weight: bold;
                text-shadow: none;
                -webkit-transition: background .1s;
                transition: background .1s;
                color: #fff;
            }
            button.btn-default {
                box-shadow: none;
                color: #fff;
                background: none;
                &:hover {
                    background: #666;
                }
            }
        }
    }
}
.jconfirm.jconfirm-supervan {
    .jconfirm-bg {
        background-color: rgba(54,70,93,0.95);
    }
    .jconfirm-box {
        background-color: transparent;
        div.closeIcon {
            color: #fff;
        }
        div.title-c {
            text-align: center;
            color: #fff;
            font-size: 28px;
            font-weight: normal;
            >* {
                padding-bottom: 25px;
            }
        }
        div.content-pane {
            margin-bottom: 25px;
        }
        div.content {
            text-align: center;
            color: #fff;
        }
        .buttons {
            text-align: center;
            button {
                font-size: 16px;
                border-radius: 2px;
                background: #303f53;
                text-shadow: none;
                border: none;
                color: #fff;
                padding: 10px;
                min-width: 100px;
            }
        }
    }
}
.jconfirm.jconfirm-material {
    .jconfirm-bg {
        background-color: rgba(0,0,0,0.67);
    }
    .jconfirm-box {
        background-color: #fff;
        box-shadow: 0 7px 8px -4px rgba(0,0,0,0.2),0 13px 19px 2px rgba(0,0,0,0.14),0 5px 24px 4px rgba(0,0,0,0.12);
        padding: 30px 25px 10px 25px;
        div.closeIcon {
            color: rgba(0,0,0,0.87);
        }
        div.title-c {
            color: rgba(0,0,0,0.87);
            font-size: 22px;
            font-weight: bold;
        }
        div.content {
            text-align: left;
            color: rgba(0,0,0,0.87);
        }
        .buttons {
            text-align: right;
            button {
                text-transform: uppercase;
                font-weight: 500;
            }
        }
    }
}
.jconfirm.jconfirm-bootstrap {
    .jconfirm-bg {
        background-color: rgba(0,0,0,0.21);
    }
    .jconfirm-box {
        background-color: #fff;
        box-shadow: 0 3px 8px 0 rgba(0,0,0,0.2);
        border: solid 1px rgba(0,0,0,0.4);
        padding: 15px 0 0;
        div.closeIcon {
            color: rgba(0,0,0,0.87);
        }
        div.title-c {
            color: rgba(0,0,0,0.87);
            font-size: 22px;
            font-weight: bold;
            padding-left: 15px;
            padding-right: 15px;
        }
        div.content {
            text-align: left;
            color: rgba(0,0,0,0.87);
            padding: 0 15px;
        }
        .buttons {
            text-align: right;
            padding: 0 0 0;
            margin: -5px 0 0;
            border-top: solid 1px #ddd;
            overflow: hidden;
            border-radius: 0 0 4px 4px;
            button {
                font-weight: 500;
                border-radius: 0;
                margin: 0;
                border-left: solid 1px #ddd;
            }
        }
    }
}
