.mega-menu-mobile.mega-menu,
.mega-menu-mobile .mega-menu {
    @include when(desktop) {
        .content__main .mega-menu__extra,
        .content__main #js-mega-menu-categories {
            //height: 610px;
            //overflow-y: auto;
        }    
        
        .mega-menu__main .col4.acol12.ccol3 {
            height: 610px;
            background: #e6e7e7;
            overflow-y: auto;
        }
        
        .mega-menu__main .mega-menu__extra-categories {
             padding: 15px 5px 0 10px;
         }
        
    }

    @include when(mobile, tablet) {
        &__groupHeader {
            font-weight: bold;
            background: lighten($color-header-nav, 2%);
            border-bottom: 1px solid #e6e7e7;
        }

        &__groupItem {
            font-weight: normal;
            background: $color-header-bar;
            border-bottom: 1px solid #e6e7e7;
        }

        &__group-wider {
            border: 0;
        }
    }  
}

//Dark theme 
.header_dark-theme .mega-menu-mobile.mega-menu,
.header_dark-theme .mega-menu-mobile .mega-menu {
    @include when(mobile, tablet) {
        &__groupHeader {
            background:  #454748;
            border-bottom: 1px solid #6A6D71;
            color: $White;
            font-family: 'Barlow', arial, sans-serif;
            font-weight: normal;
            font-size: 15px;
        }

        &__groupItem {
            background:  $color-dark-mobile-nav2;
            border-bottom: 1px solid #6E7073;            
            color: $White;
            font-family: 'Barlow', arial, sans-serif;
            font-weight: normal;
            font-size: 13px;
        }

        &__group-wider {
            border: 0;
        }      
    }   
    
}
