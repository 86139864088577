.input {
    @include border-radius(5px);
    border: 1px solid $MiddleGrey;
    padding: 0 15px;
    height: 40px;
    margin-bottom: 20px;
    color: #333;
    font-family: $base__font-family;
    font-size: 14px;
    max-width: 100%;
    &_select {
        @extend .input;
        padding: 0 0 0 15px;
    }
    &_full-width {
        @extend .input;
        width: 100%;
        &_select {
            @extend .input_full-width;
            padding: 0 0 0 15px;
            &_languages {
                @extend .input_full-width_select;
            }
        }
    }
    &_table {
        @extend .input;
        height: 26px;
        width: 100%;
        padding: 0 8px;
        margin-bottom: 0;
        @include breakpoint($RWD-A) {
            max-width: 256px;
        }
    }
    &_mark{
        min-width:100px;
    } 
    &_quantity{
        min-width:52px;
    }
    &_pack{
        min-width:100px;
    }
}

.input-reset {
    position: relative;
    max-wodth: 240px;

    input {
        width: 100%;
        display: block;
    }

    input::-ms-clear {
        display: none;
    }
    button {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 40px;
        background: Transparent no-repeat;
        border: none;
        outline: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
    }
    button:hover {
        cursor:pointer;
    }
    button:focus {
        border: none;
    }
}

.media-container .input,
.media-container .input_full-width,
.media-container__half .input,
.media-container__half .input_full-width,
.media-container__box-border .input,
.media-container__box-border .input_full-width {
    margin-bottom: 5px;
    width: 100%;

    &_myPages {
        @extend .input;
        margin-bottom: 5px;

        &_select {
            @extend .input_myPages;
            padding: 0 0 0 15px;
        }
    }
}
.textarea,
textarea {
    @include border-radius(3px);
    border: 1px solid $standard-input-border;
    padding: 10px 15px;
    margin-bottom: 20px;
    color: #333;
    font-family: $base__font-family;
    font-size: 14px;
    max-width: 100%;
    resize: none;
    &.full-width {
        width: 100%;
    }
}

input.form-input-text {
    width: 100%;

    &.form-validation-error {
        border-color: $LightRed;
    }
}

input.form-input-submit {
    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}

textarea.form-input-textarea {
    width: 100%;
    margin-bottom: 0;

    &.form-validation-error {
        border-color: $LightRed !important;
    }
}

input.form-validation-error,
select.form-validation-error,
textarea.form-validation-error {
    border: 1px solid $LightRed !important;
}

.form-submit-container {
    @include when(mobile) {
        padding-top: 0;

        input[type=submit] {
            margin-top: 20px;
        }
    }
}

.input-icon {
    position: relative;

    .fa {
        position: absolute;
        top: 12px;
        width: 44px;
        text-align: center;
        color: $color-primary;
        pointer-events: none;
    }

    &.left {
        input {
            padding-left: 44px;
        }

        .fa {
            left: 0;
        }
    }

    &.right {
        input {
            padding-right: 44px;
        }

        .fa {
            right: 0;
        }
    }
}

.input-inlabel {
    position: relative;

    .inlabel {
        position: absolute;
        pointer-events: none;
        height: 40px;
        line-height: 40px;
    }

    &.left {
        .inlabel {
            left: 0;
            padding-left: 15px;
        }
    }

    &.right {
        .inlabel {
            right: 0;
            padding-right: 15px;
        }
    }
}

.form-validation-error-message {
    font-size: 12px;
    margin-top: 2px;
    color: $LightRed;

    .fa {
        font-size: 1.2em;
        font-style: normal !important;
        margin-right: 3px;
        min-width: 0 !important;
        color: $LightRed !important;
    }
}

.punchoutspecial {
    width: 50%;
    max-width: 100%;
    min-width: 0;
}

// select {
//     -webkit-appearance: none;
//     -moz-appearance: none;

//     background-image: svg-wrapper('<polygon fill="#{$standard-input-active-border}" points="10,10 0,0 20,0"/>', '0 0 20 10');
//     background-repeat: no-repeat;
//     background-position: right 13px center;
//     background-size: 12px;
// }

