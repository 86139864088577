.configcardbig{
	display:flex;
	align-items: center;
	width:100%;
	max-width:930px;
	border-radius: 7px;
	background:#f5f5f5;
	border: 4px solid #006c94;
	padding:18px 20px;
	margin-bottom: 10px;


	@include when(mobile) {
		padding:25px 15px;
	}	

	&_disabled{
		@extend .configcardbig;
		border: 4px solid lightgrey;
	}

	&_active{
		@extend .configcardbig;
		border: 4px solid #006c94;
	}

	&__container{
		padding: 0 15px;
		@include when(mobile) {
			padding: 0 10px;
		}		
	}
	&__checkbox{
	  height: 50px;
	  width: 50px;
	  background-color: #fff;
	  border-radius: 50%;
	  border: 3px solid #006c94;
	  display: inline-block;

		@include when(mobile) {
				height: 35px;
		 		width: 35px;
		}	  
	}

	&__checkbox-dott{
		  height: 45px;
		  width: 45px;
		  background-color: #006c94;
		  border-radius: 50%;
		  border: 3px solid #fff;
		  display: inline-block;

		@include when(mobile) {
				height: 30px;
		 		width: 30px;
		}


		  &_disabled{
		  	@extend .configcardbig__checkbox-dott;
		  	display:none; 
		  }	
	}

	&__title{
		font-size: 30px;
		font-weight: bold;
		margin-bottom:5px;

		@include when(mobile) {
				font-size: 25px;	 		
		}		
	}
	&__description{

	}
}
.configcardbig-title{
	text-align: center;
	padding-bottom: 30px;
	font-size:20px;
	font-weight: 600;
}