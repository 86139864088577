.catentry-list__item {
    display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;   /* TWEENER - IE 10 */
    display: -webkit-flex;  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex;          /* NEW, Spec - Firefox, Chrome, Opera */
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;

    &__image {
        display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
        display: -ms-flexbox;   /* TWEENER - IE 10 */
        display: -webkit-flex;  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
        display: flex;          /* NEW, Spec - Firefox, Chrome, Opera */
        -webkit-flex: 0 0 56px;
        -ms-flex: 0 0 56px;
        flex: 0 0 56px;
        padding-left: 12px;

        & > img {
            max-height: 80px;
            max-width: 105px;
        }
    }

    &__description {
        display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
        display: -ms-flexbox;   /* TWEENER - IE 10 */
        display: -webkit-flex;  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
        display: flex;          /* NEW, Spec - Firefox, Chrome, Opera */
        -webkit-flex: 1 1 0px;
        -ms-flex: 1 1 0px;
        flex: 1 1 0px;
    }

    &__name {
        display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
        display: -ms-flexbox;   /* TWEENER - IE 10 */
        display: -webkit-flex;  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
        display: flex;          /* NEW, Spec - Firefox, Chrome, Opera */
        -webkit-flex: 1 1 0px;
        -ms-flex: 1 1 0px;
        flex: 1 1 0px;
        overflow: hidden;
        font-size: 14px;
        text-decoration: none;
        color: $Black;
    }

    &__name-footer{
        display: none;
    }

    &__left {
        display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
        display: -ms-flexbox;   /* TWEENER - IE 10 */
        display: -webkit-flex;  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
        display: flex;          /* NEW, Spec - Firefox, Chrome, Opera */
        -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        width: 60%;
        @include breakpoint($RWD-A) {
            width: 100%;
            padding-left: 6px;
        }
    }

    &__right {
        display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
        display: -ms-flexbox;   /* TWEENER - IE 10 */
        display: -webkit-flex;  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
        display: flex;          /* NEW, Spec - Firefox, Chrome, Opera */
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        width: 40%;
        text-align: right;

        & > * {
            margin-left: 10px;
        }
    }

    &__flags {
        display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
        display: -ms-flexbox;   /* TWEENER - IE 10 */
        display: -webkit-flex;  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
        display: flex;          /* NEW, Spec - Firefox, Chrome, Opera */
        -webkit-align-items: flex-start;
        -ms-flex-align: flex-start;
        align-items: flex-start;
        -webkit-flex-direction: column; /* Safari 6.1+ */
        flex-direction: column;
        -webkit-flex: 0 0 105px;
        -ms-flex: 0 0 105px;
        flex: 0 0 105px;
        margin-right: 10px;

        @include breakpoint($RWD-A) {
            -webkit-flex: 0 0 62px;
            -ms-flex: 0 0 62px;
            flex: 0 0 62px;
        }

        & > span {
            margin: 0;
        }
    }

    &__price {
        display: inline-block;
        margin-right: 0px;
    }

    &__product-button-wrapper {
        width: 170px;
        margin-right: 12px;
    }
}
.js-hook-catEntry {
    width: 100%;
    height: 100%;
}
.tab {
    /**& .flex-table{

        &__main{
            border: 2px solid $MiddleGrey;
            margin: -10px -16px;
        }
        &__row{
            background: white;
            &:nth-child(odd){
                background: $color-container-bg;
            }
            &_article{
                padding:0;
            }
        }
        &__closable{
            @include breakpoint($RWD-A) {
                height: 45px !important;
            }
        }
    }**/
    & .catentry-list{
        &__item{
            &__product-button-wrapper{
                display:none;
            }
            &__image{
                display:none;
            }
            &__flags{
                display:none;
            }
            &__name{
                padding:5px 0 5px 10px;
                 @include breakpoint($RWD-A) {
                    padding:9px 0 5px 10px;
                }
            }
            & .button__standard{
                display:none;
            }
        }

    }
}
