.catentry {

    &_product {
        height: 100%;
        padding: 15px;
        position: relative;

        @include when(mobile) {
            overflow: hidden;
            padding: 10px;
        }

        &_recommendation {
            @extend .catentry_product;

            border: 1px solid rgba(0, 0, 0, .25);
        }

        &__overlay-link {
            display: none;
        }
    }

    &__recommendation {
        &_side-bar-left {
            padding-right: 40px;
        }
        &_side-bar-right {
            padding-left: 40px;
        }
    }

    &__image-wrapper {
        height: 170px;
        line-height: 160px;
        overflow: hidden;
        padding: 10px 10px 0 10px;
        position: relative;

        @include when(mobile) {
            padding: 0;
        }

        .image {
            height: 100%;
        }
    }

    &__image {
        max-height: 100%;
        vertical-align: middle;

        @include when(mobile) {
            max-height: 100%;
            max-width: 75%;
            border: none;
        }
    }

    &__name {
        line-height: 14px;
        padding: 10px;
        font-size: 14px;
        font-weight: 700;
        overflow: hidden;
        height: 64px;
        position: relative;
        word-break: break-word;

        @include when(mobile) {
            line-height: 20px;
            height: auto;
            min-height: 60px;
        }

        & a {
            position: relative;
            top: 50%;
            transform: translateY(-50%);

            @include when(mobile) {
                position: static;
                transform: translateY(0);
            }
        }
    }

    &__sku {
        font-weight: normal;
        font-size: 16px;
        margin-top: 10px;
    }

    &__qty {
        padding: 0 10px 5px 10px;
    }

    &__availability {
        padding: 0 10px 5px 10px;
    }

    &__remove {
        display: block;
        float: right;
        border: 1px solid $MiddleGrey;
        height: 50px;
        width: 50px;
        margin: 2px;
        text-align: center;
        padding-top: 7px;
        color: $MiddleGrey;
    }

    &__price {
        line-height: 25px;
        padding: 0 10px;
        font-size: 14px;

        &_total {
            @extend .catentry__price;
            font-weight: bold;
        }
    }

    &__artiklar {
        display: inline-block;
        text-transform: uppercase;
        line-height: 30px;
        // padding: 0 12px;
        font-size: 12px;
        text-align: center;
        width: 100%;

        &_compProducts {
            @extend .catentry__artiklar;
            background-color: $color-primary;
            border-color: $color-primary;
            color: $White;
        }
        &_showProducts {
            @extend .catentry__artiklar;
            background-color: $color-primary;
            border-color: $color-primary;
            color: $White;
        }
    }

    &__compare-button-icon {
        display: none;
    }

    &__functions {
        font-size: 14px;
        padding: 10px 0 0px 0;

        &_checkout{
            @extend .catentry__functions;
            padding: 0;
        }

        @include when(mobile) {
            display: none;
        }
    }

    &__compare {
        display: none;
        width: 0;
        height: 0;
        border-bottom: 20px solid #F5AD6D;
        border-left: 20px solid transparent;
        position: absolute;
        right: -1px;
        bottom: 0;
        bottom: -1px;
    }

    &__flag-container {
        position: absolute;
        left: 5px;
        top: 5px;
    }

    &__flag {
        display: block;
        padding: 5px 12px;
        border-radius: 4px;
        font-size: 11px;
        line-height: 150%;
        font-family: $header-font;
        color: $White;
        margin-top: 10px;
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;


        &_new {
            @extend .catentry__flag;
            background: $LightGreen;
            @include when(desktop) {
            float: right;
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
        }
        
        &_replacement {
            @extend .catentry__flag;
            background: $LightGreen;
            @include when(desktop) {
            float: right;
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
        }

        &_deleted {
            @extend .catentry__flag;
            background: $Black;
            @include when(desktop) {
            float: right;
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
        }

        &_outgoing {
            @extend .catentry__flag;
            background: $MiddleGrey;
            @include when(desktop) {
            float: right;
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
        }

        &_expired {
            @extend .catentry__flag;
            background:$MiddleGrey;
            @include when(desktop) {
            float: right;
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
        }
        
        &_assortment {
            
            color:$color-secondary;
            @include when(desktop) {
            float: right;
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
        }

        &_square {
            width: 26px;
            font-size: 15px;
            font-weight: bold;
            text-transform: uppercase;
        }

        &_img {
            img {
                height: 26px;
            }
        }

        .catentry__container & {
            @include when(mobile) {
                margin-top:0;
                padding:0;
                font-size: 3.5vw;
            }
        }

        .standard-table &,
        .flex-table & {
            padding: 0;
            margin-top: 0;
            height: 26px;
            line-height: 26px;

            @include when(tablet) {
                font-size: 12px;
            }

            @include when(mobile) {
                margin-top: 5px;
                font-size: 10px;
                height: 22px;
                line-height: 22px;
                padding: 0 6px;
            }
        }
    }

    &__color-picker {
        height: 25px;
        width: 25px;
        max-width: 100%;
        max-height: 100%;
        display: block;
    }

    &__cust-assort-sign{
      padding-bottom: 22px;

      i{
        color: $color-secondary;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 20px;
        padding-left: 4px;
      }
    }

    &__paging {
        @include btn-theme($color-primary);
        @include trans(0.1s ease-in-out);

        color: $White;
        padding: 6px;
        display: flex;
        margin-top: 30px;
        flex-direction: row;
        flex-wrap: nowrap;
    }

    &__paging-group {
        &_spinner {
            margin-top: 7px;
            font-size: 20px;
            position: absolute;
            top: 0;
            left: 0;
        }

        &_wide {
            flex: 3 1 auto;
            text-align: left;
            position: relative;
        }

        &_normal {
            flex: 1 1 auto;
            text-align: center;
        }
    }

    &__paging-text {
        text-transform: uppercase;
        font-family: $header-font;
        font-size: 15px;
    }

    &__paging-hits {
        font-size:14px;
    }

    &__hidden {
        display: none;
    }

    &__link {
        height:100%;

        & img {
            max-height:100%;
        }
    }

    &__tab {
      border-top: 3px solid $ButtonOrangeTriangle;
      cursor: pointer;
      top: -1px;
      right: 0px;
      position: absolute;
      z-index: 7;
    }

    &__tab-button {
      background: white;
      border-left: 1px solid $MiddleGrey;
      padding: 6px 30px 4px;
      position: relative;
      line-height: 40px;
    }

    &__triangle {
      background: $White;
      position: absolute;
      transform: rotate(45deg);
      bottom: -8px;
      left: 46%;
      width: 15px;
      height: 15px;
      z-index: 8;
    }

    &__espot {
        margin: auto;
    }
    &__espot-text{
        width: 100%;
        padding: 30px;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}
