.mega-menu{
    position: absolute;
    top: 100%;
    height: 640px;
    max-width: 1280px; /* behöver ha samma bredd som topmenyn */
    margin: 0 auto;
    line-height: 1;
    z-index: 5;

    @include when(mobile, tablet) {
        position: relative;
        top: 0;
        height: auto;
    }

    &__wrapper{
        width: 1000px;
        max-height: 612px;
        height: calc(100vh - 210px);
        text-align: left;
        margin-top: 17px;
        overflow: auto;
        border: 1px solid #ccc;
        box-shadow: 0px 10px 14px -2px rgba(10, 10, 0, 0.5);
        background: white;

        @include when(mobile, tablet) {
            border: 0;
            width: auto;
            background: $MobileMenuGrey;
            height: auto !important;
            margin-top: 0;
            max-height: 100%;
        }
    }

    &__arrow-wrapper{
        width: 1000px;
        height: 0px;
        margin-top: 17px;
        margin-left: 30px;
        text-align: left;
        background:white;
        display: block;
        z-index: 9999;

        &_arrow {
            width: 0px;
            height: 0px;margin-top: 3px;
            background-color: #ccc;
            position: relative;
            top: 1px; /* gör att den överlappar nästa block 1 px */
            border: 0px solid #333;
        }
        &_arrow:after, &_arrow:before {
            border: solid transparent;
            content: ' ';
            height: 0;
            bottom: 100%;
            position: absolute;
            width: 0;
        }
        &_arrow:after {
            border-width: 12px;
            border-bottom-color: #fff;
            left: 12px;
        }
        &_arrow:before {
            border-width: 14px;
            border-bottom-color: #ccc;
            left: 10px;
        }
        @include breakpoint($RWD-A) {
            display: none;
        }
        @include breakpoint($RWD-B) {
            display: none;
        }
    }

    &__main{
        margin: 0 auto;
        max-width: 1280px;
        display: flex;
        position: relative;
        & a:hover {
            text-decoration: underline;
        }

        .content {
            width: 100%;
        }
    }
    &__extra{
        flex: 0 0 200px;
        background: #e6e7e7;
    }
    &__extra-categories{
        padding: 15px 25px 0 10px;
    }
    &__extra-item {
        padding: 3px 0;
        font-size: 13px;
    }
    &__extra-content {
        padding: 50px 4px 25px;
    }
    &__categories{
        padding-left: 25px;
        padding-top: 30px;
        width: 100%;
        //max-width: 1280px;
        //margin: 0 auto;
        @include breakpoint($RWD-A) {
            padding: 0px;
            border: none;
            width: 100%;
        }
        @include breakpoint($RWD-B) {
            padding: 0px;
            border: none;
            width: 100%;
        }
    }
    &__group{
        float: left;
        padding-right: 12px;
        padding-bottom: 36px;

        @include breakpoint($RWD-A) {
            float: none;
            padding: 0;
            width: 100%;
            border-bottom: 2px solid #e6e7e7;
            &:last-child{
                border-bottom: 0;
            }
        }
        @include breakpoint($RWD-B) {
            float: none;
            padding: 0;
            width: 100%;
            border-bottom: 2px solid #e6e7e7;
            &:last-child{
                border-bottom: 0;
            }
        }
        @include breakpoint($RWD-C) {
         width:25%;
            &:nth-child(4n + 4){
                clear: right;
            }
            &:nth-child(4n + 5){
                clear: left;
            }
        }
    }
    &__group-wider {
        flex: 1 0 160px;
        max-width: 100%;
        padding-right: 12px;
        padding-bottom: 30px;
    }
    &__flex {
        display: flex;
        flex-flow: row wrap;
    }
    &__groupHeader {
        padding: 0 0 7px 0;
        font-size: 13px;
        font-weight: 600;
        font-family: $header-font;
        position: relative;
        // text-transform: uppercase;
        // & li {
        //     @include breakpoint($RWD-A) {
        //         display: inline-block;
        //     }
        // }
        &_icon {
            position: absolute;
            top: 6px;
            right: 15px;
            font-size: 22px;
            @include breakpoint($RWD-C) {
                display: none;
            }
        }
    }
    &__groupItem {
        padding: 3px 0;
        font-size: 13px;

        &_moreLink {
            @extend .mega-menu__groupItem;
            font-size: 11px;
            font-weight: 600;
            font-family: $header-font;
            cursor: pointer;
            padding: 6px 0;

            a {
                margin-left: 2px;
            }
        }
    }

    &__hidden {
        display: none;
    }

    &__show-hidden {
        display: block;
    }

    &__show-mobile {
        display: none;
    }
}
/* These styles are used for mega menu in AjaxMegaMenu */
.mega-menu-mobile {
    @include when(mobile, tablet) {
        .mega-menu {

            &__flex {
                display: block;
            }

            &__groupHeader {
                background: $header-button-grey;
                color: $DarkGrey;
                display: block;
                padding: 12px 45px 12px 15px;
                font-size: 12px;
            }

            &__groupItem {
                font-size: 12px;
                font-family: $header-font;
                background: $header-button-white;
                color: $DarkGrey;
                display: block;
                padding: 12px 15px;
                border-bottom: 1px solid #b2b2b2;

                &:last-child{
                    border-bottom: 0;
                }
            }

            &__group-wider {
                display: block;
                padding: 0;
                border-bottom: 2px solid #e6e7e7;
                max-width: 100%;

                &:last-child{
                    border-bottom: 0;
                }

                &_dummy {
                    display: none;
                }
            }

            &__hidden {
                display: block;
            }

            &__hidden-mobile {
                display: none;
            }

            &__show-mobile {
                display: block;
            }

            &__extra {
                display: none;
            }
        }
    }
}
