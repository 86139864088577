.footer {
    /*position: relative;*/

    /*To fix DOM issue during generic errors*/
    position: absolute;
    width: 100%;
    left: 0;

    &__main {
        background-color: $color-footer;
        color: $White;
    }

    &__main-bottom {
        font-size: 13px;

        @include when(mobile) {
            text-align: center;
            margin-top: 20px;
        }

        &__social-icons {
            margin-top: 15px;
            position: relative;

            a {
                display: inline-block;
                margin: 0 5px;

                &:first-child {
                    margin-left: 0;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    &__secondary {
        background-color: #c2c2c2;
    }

    &__content {
        padding: 19px 0;
        margin: 0 auto;
        max-width: 1280px;

        @include when(mobile) {
            padding: 20px;
        }

        &_main {
            @extend .footer__content;

            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: space-between;

            @include when(mobile) {
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .has-content.footer__list-box {
                    padding: 10px;
                    width: 100%;
                    border-bottom: 1px solid $White;

                    &:first-child {
                        border-top: 1px solid $White;
                    }
                }
            }

            @media only screen and (max-width: 1320px) {
                padding-left: 10px;
                padding-right: 10px;
            }
        }

        &_secondary {
            @extend .footer__content;

            text-align: center;
        }
    }

    &__list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        &_main {
            line-height: 1.4;
            font-size: 13px;
        }

        &_brand {
            display: block;
            text-align: justify;
            text-align-last: center;
                font-weight: 400;

            li {
                display: inline-block;
                color: $Black;
                padding: 0 10px;
                margin-bottom: 20px;
                text-transform: uppercase;
                font-size: 13px;
                font-family: $header-font;
                line-height: 15px;
                text-align: center;
                vertical-align: top;

                @include when(mobile) {
                    font-size: 10px;
                    margin-bottom: 5px;
                }
            }

            &_separator {
                border-right: 2px solid $Black;
                width: 2px;
                min-height: 15px;
                padding: 0 !important;
                margin-left: -5px;
                margin-right: -5px;
            }
        }

        &_cert {
            @extend .footer__list;
        }

        &__title {
            font-size: 15px;
            text-transform: uppercase;
            font-family: $header-font;
            font-weight: 900;
            margin-bottom: 5px;

            .fa {
                display: none;
                float: right;
                line-height: 19px;
            }

            @include when(mobile) {
                margin-bottom: 0px;

                .fa-angle-down {
                    display: block;
                }

                .fa-angle-up {
                    display: none;
                }

                + ul {
                    display: none;
                }

                &.show {
                    margin-bottom: 5px;

                    .fa-angle-down {
                        display: none;
                    }

                    .fa-angle-up {
                        display: block;
                    }

                    + ul {
                        display: block;
                    }
                }
            }
        }
    }

    &__title {
        font-weight: 600;
        color: $Black;
        margin-bottom: 20px;
        text-align: center;

        @include when(mobile) {
            font-size: 22px;
        }
    }

    &__text {
        line-height: 20px;
        font-size: 13px;
        font-weight: 600;

        @include when(mobile) {
            font-size: 10px;
        }
    }

    hr {
        border-top: 1px solid $Black;
        margin: 0 auto;
        max-width: 1280px;
    }

    a:hover {
        text-decoration: underline;
    }
}
