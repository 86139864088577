.cms {
    &__intro{
        font-weight: 300;
    }
    &__news-list {
        border-bottom: 1px solid $standard-button-border;
        overflow: hidden;
        position: relative;
        height: 120px;
        padding: 20px 0;
        &_img{
            float:left;
            width:40%;
            height:100px;
        }
        &_link{
            max-height:100px;
            display:block;
        }
        &_image {
            margin-top:-10px;
            float:right;
            height: 100px;
        }
        &_desc {
            float: left;
            margin-top: 10px;
            overflow:hidden;
            width:60%;
            @include breakpoint($RWD-A) {
                margin-top: 0;
            }
        }
    }
    &__generic {
        $cmsGenericContentPadding: 40px;

        &_padding-left {
            padding: 0 0 0 $cmsGenericContentPadding;
        }

        &_padding-right {
            padding: 0 $cmsGenericContentPadding 0 0;
        }

        &_padding-both {
            padding: 0 $cmsGenericContentPadding;
        }

        &_header {
            margin-bottom: 20px;
        }
        &_intro {
            margin: 15px 0 15px 0;
            // font-family: $heading__font-family;
            font-size: 16px;
            font-weight: bold;
        }
        &_topImage {
            width: 100%;
        }
        &_body {
            margin-bottom: 20px;
            line-height: 18px;
            font-size: 14px;
            h2, h3, h4 {
                margin-top: 20px;
                margin-bottom: 10px;
                font-family: $header-font;
                font-weight: 600;
            }
            h2 {
                margin-bottom: 7px;
            }
            ul {
               list-style-type: disc;
               list-style-position: inside;
            }
            ol {
               list-style-type: decimal;
               list-style-position: inside;
            }
            ul ul, ol ul {
               list-style-type: circle;
               list-style-position: inside;
               margin-left: 1em;
            }
            ol ol, ul ol {
               list-style-type: lower-latin;
               list-style-position: inside;
               margin-left: 1em;
            }
            li {
                display: list-item;
                list-style-position: outside;
                margin-left: 1.5em;
                line-height: 1.7em;
            }
            b, strong {
                font-weight: bold;
            }
            i, em {
                font-style: italic;
            }
            p {
                display: block;
                margin: 0.5em 0;
                line-height: 1.7em;
            }
            sup {
                // - Default
                // vertical-align: super;
                // font-size: smaller;

                font-size: 70%;
                vertical-align: top;
                position: relative;
                top: -0.5em;
            }
            sub {
                // - Default
                // vertical-align: sub;
                // font-size: smaller;

                font-size: 70%;
                vertical-align: bottom;
                position: relative;
                top: 0.5em;
            }
            hr {
                border: 0;
                border-top: 1px solid $MiddleGrey;
            }
            table {
                width: auto !important;

                td {
                    padding: 0.3em 0.5em;
                    &:first-child {
                        padding-left: 0;
                    }
                    &:last-child {
                        padding-right: 0;
                    }
                }
                thead {
                    font-weight: bold;
                }
            }
            table[border] {
                border-left: 1px solid $MiddleGrey;
                border-top: 1px solid $MiddleGrey;
                tr {
                    border-bottom: 1px solid $MiddleGrey;
                }
                td {
                    border-right: 1px solid $MiddleGrey;
                    padding: 0.3em 0.5em;
                }
            }
            button,
            input[type="button"],
            input[type="submit"] {
                -webkit-appearance: none;
                -webkit-border-radius: 0;
            }

            input[type="text"],
            input[type="email"],
            input[type="number"],
            input[type="search"] {
                -webkit-appearance: none;
                -webkit-border-radius: 0;
            }
        }
    }

    &__hero-carousel-body h1,
    &__hero-carousel-body h2,
    &__hero-carousel-body h3,
    &__hero-carousel-body h4,
    &__hero-carousel-body h5,
    &__hero-carousel-body h5,
    &__hero-carousel-body p, {
        color: $White;
    }

    &__header {
        &_wrapper {
            position: relative;
            margin-bottom: 30px;
        }
        &_textArea {
            overflow: hidden;
        }
        &_imageArea {
            width: 33%;
            float: right;
            text-align: center;
            max-height: 200px;

            img {
                max-height: 200px;
            }
            @include breakpoint($RWD-A) {
                display: none;
            }
        }
        &_image {
            border: none;
            max-width: 100%;
        }
        &_desc {
            margin-top: 20px;
        }
    }
    &__devider {
        border-top: 1px solid $Black;
        margin: 0px 0px 15px 0px;

        &_small {
            border-bottom: 1px solid $Black;
            margin: 15px 30px 35px 30px;
        }
    }
    &__navigationItem {
        display: inline-block;
        margin: 0 7px 15px 7px;
        font-weight: 900;
        text-transform: uppercase;

        &:first-child {
            margin-left: 0;
        }

        a {
            display: block;
            font-family: $heading__font-family;
            line-height: 38px;
            padding: 0 20px;
            font-size: 14px;
        }

        @include breakpoint($RWD-A) {
            margin: 7px 0;
            width: 100%;
            text-align: center;
        }

        @mixin _cms__navigationItem($color) {
            @extend .cms__navigationItem;

            a {
                background-color: $MiddleGrey;
                color: $White;

                &:hover {
                    background-color: darken($MiddleGrey, 10%);
                }
            }

            &-sel {
                @extend .cms__navigationItem;

                a {
                    background-color: $color;
                    color: $White;

                    &:hover {
                        background-color: darken($color, 10%);
                    }
                }
            }
        }

        &_education {
            @include _cms__navigationItem($LightOrange);
        }
        &_hottopics {
            @include _cms__navigationItem($LightGreen);
        }
        &_generic {
            @include _cms__navigationItem($MiddleGrey);
        }

    }
    &__largeGridWrapper {
        margin: -10px;
        margin-bottom: 10px;
        display: flex;
        flex-wrap: wrap;
    }
    &__greyBackground {
        border-radius: 10px;
        background-color: $LightGrey;
    }
    &__largeGridItem {
        //min-width: 300px;
        //flex: 1 1 300px;
        height: 280px;
        width: calc(25% - 20px);
        background-color: $White;
        //border-radius: 10px;
        float: left;
        margin: 10px;
        background-size: cover;
        background-position: center center;
        //background-image: url('../../../images/customer/site-bkg-dark.jpg');
        &:hover .cms__largeGridIcon {
            background-color: rgba(0, 0, 0, 0.2);
        }
        &:hover .cms__largeGridText {
            background-color: rgba(0, 0, 0, 0.6);
        }
        @include breakpoint($RWD-A) {
            width: calc(100% - 0px);
        }
        @include breakpoint($RWD-B) {
            width: calc(33% - 20px);
        }

        &_full-width {
            margin: 0;
            width: 100%;
        }
    }
    &__largeGridIcon {
        text-align: center;
        font-size: 9em;
            height: 195px;
        color: $White;
        border-radius: 10px 10px 0 0;
        //background-color: rgba(0, 0, 0, 0.3);

    }
    &__largeGridText {
        text-align: center;
        height: 85px;
        color: $White;
        //background-color: rgba(0, 0, 0, 0.5);
        //border-radius: 0 0 10px 10px;
        padding-top: 10px;
        position: relative;

        .h2 {
            color: $White;
        }

    }
    &__largeGridDesc {
        height: 0px;
        overflow: hidden;
        width: 0px;
    }
    &__largeGridReadMore {
        text-transform: uppercase;
        font-family: $header-font;
        //position: absolute;
        //bottom: 13px;
        width: 100%;
        font-size: 13px;
        //left: 0px;
    }
    &__contentListItem {
        position: relative;
        border-top: 1px solid $LightGrey;
        padding: 5px;
        &:nth-child(odd) {
            background-color: $Pampas;
        }
        &:nth-child(even) {
            background-color: white;
        }
        &:last-child {
            border-bottom: 1px solid $LightGrey;
        }
        &_left {
            float: left;
            margin-right: 10px;
        }
        &_right {
            float: right;
            margin-left: 10px;
        }
        &_middle {
            overflow: hidden;
        }
        h3 {
            font-family: $heading__font-family;
            font-size: 18px;
            line-height: 18px;
            margin-bottom: 3px;
            font-weight: bold;
        }
        p {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        a.read-more {
            display: inline-block;
            padding: 5px 12px;
            border-radius: 3px;
            background-color: $Orange;
            color: white;
            font-family: $header-font;
            font-size: 14px;
            margin-top: 7px;
        }
    }

    &__sideMenu {
        & .cms__sideMenuItem_small,
          .cms__sideMenuItem_large {
            & i.fa-caret-right, i.fa-caret-down {
                display: none;
            }
        }
        &_toggle{
            & .cms__sideMenuItem_small.active,
              .cms__sideMenuItem_large.active {
                & i.fa-caret-down {
                    display: inline-block;
                }
                & i.fa-caret-right {
                    display: none;
                }
            }
            & .cms__sideMenuItem_small,
              .cms__sideMenuItem_large {
                & i.fa {
                    width: 0.75em;
                }
                & i.fa-caret-right {
                    padding-left: 2px;
                }
                & i.fa-caret-down {
                    display: none;
                }
            }

            & .cms__sideMenuHide.active {
                display: block;
            }
            & .cms__sideMenuHide {
                display: none;
            }
            & .cms__sideMenuItem_large {
                font-weight: bold;
                color: $color-secondary;
            }
        }
    }

    &__sideMenuItem {
        margin-bottom: 15px;
        font-size: 15px;
        &_small {
            @extend .cms__sideMenuItem;
            margin-bottom: 10px;
            font-size: 14px;

        }
        &_large {
            @extend .cms__sideMenuItem;
            font-family: $header-font;
            text-transform: uppercase;
        }
    }
    &__sideMenuGroup {
        margin-bottom: 15px;
        margin-left: 20px;
        margin-top: -10px;
    }
    &__sideMenuLink {
        &:hover {
            text-decoration: underline;
        }
    }
    &__sideMenuItem3 {
        &:hover .cms__sideMenuItem3TextArea {
            @include breakpoint($RWD-C) {
                background-color: darken(#FFF, 10%);
                border-color: darken($SilverGrey, 10%);
            }
            @include breakpoint($RWD-B) {
                background-color: darken(#FFF, 10%);
                border-color: darken($SilverGrey, 10%);
            }
        }
        &:hover .cms__sideMenuItem3ImageArea:after {
            @include breakpoint($RWD-C) {
                background-color: rgba(0,0,0,0.2);
            }
            @include breakpoint($RWD-B) {
                background-color: rgba(0,0,0,0.2);
            }
        }

        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;

        @include breakpoint($RWD-A) {
            position: relative;
            border-bottom: 1px solid $LightGrey;
            display: flex;
            padding: 0 5px 15px 5px;
            margin-top: -5px;
            border-radius: 0;
        }
    }
    &__sideMenuItem3ImageLink {
        position: relative;
        border: 0;
        flex: 0 0 120px;
        order: 2;
    }
    &__sideMenuItem3TextArea {
        display: block;
        height: 195px;
        border: 1px solid $SilverGrey;
        border-top: none;
        border-radius: 0px 0px 20px 20px;
        font-size: 13px;
        padding: 25px 15px 10px 15px;
        position: relative;
        overflow: hidden;

        h3 {
            font-family: $heading__font-family;
            line-height: 22px;
            margin-bottom: 10px;
            font-weight: bold;
        }

        @include breakpoint($RWD-A) {
            border: 0;
            flex: 1 1 auto;
            order: 1;
            height: auto;
            min-height: 80px;
            min-width: 0;
            padding: 20px 10px 20px 0px;
            border-radius: 0;

            $sideMenuItem3Title_fontSize: 16px;
            $sideMenuItem3Title_lineHeight: 1.4;
            $sideMenuItem3Title_lines: 2;

            $sideMenuItem3TextArea_fontSize: 13px;
            $sideMenuItem3TextArea_lineHeight: 1.4;
            $sideMenuItem3TextArea_lines: 3;

            & h3 {
                margin-bottom: 2px;
                display: block;
                display: -webkit-box;
                -webkit-line-clamp: $sideMenuItem3Title_lines;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: $sideMenuItem3Title_fontSize;
                line-height: $sideMenuItem3Title_lineHeight;
                max-height: $sideMenuItem3Title_fontSize * $sideMenuItem3Title_lineHeight * $sideMenuItem3Title_lines;
            }

            p {
                display: block;
                display: -webkit-box;
                -webkit-line-clamp: $sideMenuItem3TextArea_lines;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: $sideMenuItem3TextArea_fontSize;
                line-height: $sideMenuItem3TextArea_lineHeight;
                max-height: $sideMenuItem3TextArea_fontSize * $sideMenuItem3TextArea_lineHeight * $sideMenuItem3TextArea_lines;
            }
        }
        &_list{
            @extend .cms__sideMenuItem3TextArea;
            border-radius: 0;
            border: none;
            height: auto;
            padding-left: 0;
        }
    }
    &__sideMenuItem3ImageArea {
        position: relative;
        width: 100%;
        padding-bottom: 62.5%;
        background-size: cover;
        background-position: center center;
        background-color: $LightGrey;

        &:after {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            padding-bottom: 62.5%;
        }

        @include breakpoint($RWD-A) {
            height: 100px;
        }
    }
    &__sideMenuItem3ReadMore {
        text-transform: uppercase;
        color: $Orange;
        position: absolute;
        bottom: 10px;
        right: 15px;
        font-weight: 900;
        font-family: $header-font;

        @include breakpoint($RWD-A) {
            bottom: 0;
            font-size: 11px;
            left: 0;
        }
    }
    &__sideMenuItem3Category {
        position: absolute;
        text-transform: uppercase;
        font-size: 11px;
        top: 5px;
        left: 0;
        padding: 0 15px;
        overflow: hidden;
        white-space: nowrap;
        max-width: 100%;
        text-overflow: ellipsis;
        font-weight: bold;

        @include breakpoint($RWD-A) {
            top: 0px;
            bottom: 0;
            height: 16px;
            padding: 0;
            max-width: 100%;
            text-align: right;
        }

        &_generic {
            @extend .cms__sideMenuItem3Category;
            color: $MiddleGrey;
        }

        &_education {
            @extend .cms__sideMenuItem3Category;
            color: $LightOrange;
        }

        &_hottopics {
            @extend .cms__sideMenuItem3Category;
            color: $LightGreen;
        }
        & .cms__sideMenuItem3TextArea_list {
            padding-left: 0;
        }
    }
    &__listWrapper {
        margin-bottom: 50px;
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
    }

    /* News item */

    &__sideMenuItem2 {

    }

    &__sideMenuItem2Category {
        @extend .cms__sideMenuItem3Category;

        padding: 0;

        &_generic {
            @extend .cms__sideMenuItem2Category;
            color: $MiddleGrey;
        }

        &_education {
            @extend .cms__sideMenuItem2Category;
            color: $LightOrange;
        }

        &_hottopics {
            @extend .cms__sideMenuItem2Category;
            color: $LightGreen;
        }
    }

    &__sideMenuItem3Text {
        font-weight: 300;
    }

    /* Image widget*/
    &__image{
        clear: both;
        height: 600px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        @include when(mobile) {height: 200px;}
        @include when(tablet) {height: 400px;}
    }

    &__publishDate {
        margin: -20px 0 10px;
        font-weight: bold;
        color: $color-secondary;
    }

    &__pre-title {
        font-weight: 900;
        background-color: $color-primary;
        padding: 2px 10px;
        font-size: 30px;
        color: $White;
    }

    /* Layout for Grid Widget style 4 */
    &__gridw4-container {
        border-radius: 0;
        border: 1px solid #bdbdbd !important;
        margin: 0;
        padding: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        box-sizing: border-box;

        &:hover .cms__gridw4-sec-link {
            @include breakpoint($RWD-C) {
                background-color: darken(#FFF, 10%);
                border-color: darken($SilverGrey, 10%);
            }
            @include breakpoint($RWD-B) {
                background-color: darken(#FFF, 10%);
                border-color: darken($SilverGrey, 10%);
            }
        }
        &:hover .cms__gridw4-image:after {
            opacity: 0.2;
            @include breakpoint($RWD-C) {
                background-color: rgba(0,0,0,0.2) !important;
            }
            @include breakpoint($RWD-B) {
                background-color: rgba(0,0,0,0.2) !important;
            }
        }
    }
    &__gridw4-first-link {
        position: relative;
        border: 0;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 120px;
        flex: 0 0 120px;
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;

        color: inherit;
        text-decoration: none;
        cursor: pointer;

        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }
    &__gridw4-image {
        position: relative;
        background-color: #e6e7e7;
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
        padding-bottom: 62.5%;
        background-size: cover;
        background-position: 50%;
        width: 100%;
        display: block;

        &:after {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            padding-bottom: 62.5%;
        }

        @include breakpoint($RWD-A) {
            height: 100px;
        }
    }
    &__gridw4-hidden-span {
        display: none;
    }
    &__gridw4-sec-link {
        border-radius: 0;
        border: 0;
        padding: 20px 15px 10px;
        height: auto;
        display: block;
        font-size: 13px;
        position: relative;
        overflow: hidden;
    }
    &__gridw4-title {
        padding-top: .4em;
        font-size: 20px;
        line-height: 22px;
        margin-bottom: 10px;
        font-weight: 700;
        color: #2f2f2f;
        text-transform: uppercase;
    }
    &__gridw4-body-text {
        border-radius: 0;
        border: 0;
        padding: 0 0 25px;
        margin-bottom: 20px;
        font-size: 14px;
        font-weight: 300;
    }
    &__gridw4-button-more {
        color: $SilverGrey;
        text-transform: uppercase;
        position: absolute;
        bottom: 10px;
        right: 15px;
        font-weight: 900;
    }
    &__gridw4-category-name {
        color: #bdbdbd;
        position: absolute;
        text-transform: uppercase;
        font-size: 11px;
        top: 5px;
        left: 0;
        padding: 0 15px;
        overflow: hidden;
        white-space: nowrap;
        max-width: 100%;
        text-overflow: ellipsis;
        font-weight: 700;
    }
}

.js-cms-video .cms__videoIcon {
    @extend .fa;
    @extend .fa-play;
    margin-left: 5px;
}

.cms__fontColor div,
.cms__fontColor h1,
.cms__fontColor h2,
.cms__fontColor h3,
.cms__fontColor h4,
.cms__fontColor h5,
.cms__fontColor h6,
.cms__fontColor p,
.cms__fontColor .cms_grid-text {
    color: $Black !important;
}
