.flag{
    padding:0 8px;
    color:#fff;
    display:inline-block;
    line-height:25px;
    &_in{
        @extend .flag;
        background:green;
    }
    &_out{
        @extend .flag;
        background:black;
    }
    &_back{
        @extend .flag;
        background:red;
    }
}

.flag-table{
    padding: 5px 0px;
    color:#fff;
    text-align: center; 
    width: 70px;
    @include breakpoint($RWD-A) {
        display:inline-block;
    }     
    &_new{
        @extend .flag-table;
        background: $LightGreen;
    }
    &_deleted{
        @extend .flag-table;
        background: $MiddleGrey;
    }
    &_outgoing{
        @extend .flag-table;
        background: $Black;
        font-style: italic;
    }
}