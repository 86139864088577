.login {
    @include when(mobile) {
        position: fixed;
        left: 0;
        width: 100%;
        top: 0;
    }

    &__arrow {
        @include when(tablet, mobile) {
            display: none;
        }
    }

    &__menu {
        @include when(mobile) {
            top: 60px;
            border-radius: 0;
            box-shadow: 0px 9px 14px -1px rgba(0, 0, 0, 0.4);
            background-color: $color-header-nav;
        }

        @include when(tablet) {
            top: 0;
            right: 0;
            border-radius: 0;
            box-shadow: 0px 9px 14px -1px rgba(0, 0, 0, 0.4);
            background-color: $color-header-nav;
        }
    }

    &__menu &__input-label {
        display: none;
    }

    &__menu &__input input {
        @include when(tablet, mobile) {
            border-radius: 0;
            border: 1px solid lighten($color-header-icon, 10%);
            transition: border .1s;

            &:focus {
                border: 1px solid $color-header-icon;
                outline: 0;
            }
        }
    }

    //Begin Dark Theme
    .header_dark-theme &__menu {
        background-color: $color-dark-mobile-nav-bg;
        @include when(mobile) {
            top: 92px;           
        }    
    }

    .header_dark-theme &__arrow {
        border-bottom: 10px solid $color-dark-mobile-nav-bg; 
    }

    .header_dark-theme &__links {
        color: $color-header-icon;
    }
   
}