/**
 *  Det finns även en annan, inte lika kaotisk, print css fil:
 *
 *  Stores\WebContent\Aurora\assets\common\static\print-template.css
 */

@media print {
    .header__nav,
    .header__left-box,
    .msg__wrapper,
    .header__right-box,
    .header__searchFieldContainer,
    .footer,
    .js-overflow-fade-out,
    .js-show-more,
    .footer__extended {
        display: none;
    }
    
    .media-container__border.orders-d-print{
       clear: both;
    }
    
    .standard-table__item.o-list-print { 
        width: auto !important;
    }
    
    .standard-table__item.o-list-print.min-w-print-85px { 
        min-width: 85px !important;
    }
    
    .standard-table__collapse-tablet .standard-table__item {
        width: auto!important;
        padding: 8px 3px 8px!important;
    }
             
    .header {
        height: 100px;
        overflow: hidden;
    }

    .media-container__left-box_info {
        margin: 0 !important;

        ul {
            margin-bottom: 0 !important;
        }
    }

    .header__container {
        background-image: none;
        border: 0;
    }

    .media-container__long-desc {
        max-height: none;
    }

    .product__image-wrapper {
        max-height: 300px;
    }

    .print-hide{
        display: none;
    }

    .flex-table__row_gtin {
        display: flex !important;
        // height: 20px !important;
    }

    .flex-table__item:before {
        display: none !important;
    }

    .pcol12,
    .col4,
    .acol12,
    .ccol3{
        width: 100% !important;
        float: none !important;
    }

    .tab__title{
        font-size: 18px;
        font-style: normal;
        font-weight: bold;
        color: #000;
        padding: 8px 12px 0 12px;
        border-left: 1px solid #E6E7E7;
        border-right: 1px solid #E6E7E7;
        border-top: 1px solid #E6E7E7;
        text-transform: initial;
        background: #F5F2EF;
    }

    .tab__hidden-content {
        display: block;
        position: relative;
        top: 0px;
        padding: 8px 12px;
        border-left: 1px solid #E6E7E7;
        border-right: 1px solid #E6E7E7;
        border-bottom: 6px solid #B2B2B2;
    }

    .tab__content {
        width: 100%;
    }

    .product__list-wrapper {
        width: 100%;
    }

    .product__list-varning {
        width: 100%;
    }

    .fa-plus {
        display: none !important;    
    }

    .button_buy,
    .fa-chevron-right,
    .flex-table__mobile-link {
        display: none !important; 
    }

    .flex-table__close {
        height: initial;
        border-top: 1px solid $LightGrey;
    }

    .flex-table__item_unit {
        display: block;
    }

    .js-show-more {
        display: none;
    }

    .cookie-bar {
        display: none;
    }

    .product__list-wrapper {
        margin-right: 0;
    }

    .media-container__title {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        border: 1px solid $color-title-bg;
    }

    .product__brand {
    	&.hide_mobile {
			@media print {
				display: inline !important;
			}
    	}

        width: 140px;
        position: initial;
        top: initial;
        right: initial;
        float: right;
    }

    .product__title {
    	&.hide_mobile {
			@media print {
				display: inline !important;
			}
    	}
    }

    .rowContainer h1 {
        float: left;
    }

    .rowContainer {
        & .p-rel {
            overflow: hidden;
        }
    }

    @each $label, $breakpoint in $when-mixin-config {
        .#{$label} {
            @for $i from 1 through ($rm-max-pixel-width / 5) {
                &_w-#{$i*5}px   { width: #{($i*5)+'px'} !important; }
            }

            @for $i from 1 through ($rm-max-percentage-width / 5) {
                &_w-#{$i*5}perc { width: #{($i*5)+'%'} !important; }
            }
        }
    }
}
