@import "./reset";
@import "./breakpoint";

// SCSS CONFIGURATION  - SITEWIDE VARIABLES

// CONTENTS ------------
//  - BASE VARIABLES
//      - SIZES
//      - FONTS
//      - SIZING
//  - MODULE CONFIGURATION
//      - LAYOUT
//      - LISTS
//      - MEDIA
// ---------------------

/**  1. Base Variables */

/** 1.1 @Positioning */

/**
    VARIABLES RELATED TO POSITIONING GO HERE
    example: position, top, z-index
**/

/** 1.2 @Box behavior and properties */

/**
    VARIABLES RELATED TO BOX BEHAVIOR GO HERE
    example: float, display, overflow
**/

/** 1.3 @Sizing */

/**
    VARIABLES RELATED TO SIZING GO HERE
    example: margin, padding
**/

/** 1.4 @Color appearance */

/**
    VARIABLES RELATED TO COLOR APPEARANCE  GO HERE
    example: border, background, color
**/

/** DESCRIPTIVE VARIABLES */

    $Hampton:             #E6E2AF;
    $Hillary:             #A7A37E;
    $TahunaSands:         #EFECCA;
    $VeniceBlue:          #046380;
    $DeepTeal:            #002F2F;
    $Black:               #000000;
    $DarkGrey:            #1b1b1b;
    $White:               #FFFFFF;
    $LightGrey:           #E6E7E7;
    $LightGreyTransp:     rgba(230, 231, 231, 0);
    $MiddleGrey:          #B2B2B2;
    $ConcreteGrey:        #6f6c67;
    $SilverGrey:          #CCCAC8;
    $ButtonOrange:        #f7dec8;
    $ButtonOrangeBorder:  #E6CCB4;
    $ButtonOrangeTriangle:#F5AD6D;
    $Red:                 #CD1010;
    $LightRed:            #E26861;
    $ErrorRed:            #BF360C !default;
    $NoticeYellow:        #FFD600 !default;
    $ConfirmGreen:        #388E3C !default;
    $MobileMenuGrey:      #332F30;
    $PropertiesZebra:     #EFF0F0;

/** FUNCTIONAL VARIABLES */

    $color-primary:           $VeniceBlue !default;
    $color-secondary:         $LightRed !default;

    $color-font:              $Black !default;

    $color-footer-top:        $Hillary !default;
    $color-footer-bottom:     $Hampton !default;

    $color-link-hover:        $VeniceBlue !default;
    $color-underline-hover:   $DeepTeal !default;
    $color-link-icon:         $DeepTeal !default;

    $color-title-bg:          $VeniceBlue !default;

    $color-button-primary:    $VeniceBlue !default;
    $color-button-secondary:  $DeepTeal !default;

    // DISCUSS
    $standard-button-border:    $MiddleGrey;
    $standard-button-active:    $ButtonOrange;
    $standard-button-active-border: $ButtonOrangeBorder;
    $standard-input-border:     $SilverGrey;
    $standard-input-active-border: $MiddleGrey;

    // SHOULD NOT BE PLACED IN THIS FILE
    $header-button-black:     $DarkGrey !default;
    $header-button-grey:      $MiddleGrey !default;
    $header-button-white:     $LightGrey !default;

    $color-icon-primary:      $VeniceBlue !default;
    $color-icon-secondary:    $DeepTeal !default;

    $color-border-primary:     $LightGrey !default;
    $color-border-secondary:   $MiddleGrey !default;

    $color-container-bg:       $TahunaSands !default;

    /*$box-shadow-primary:       1px 5px 10px rgba(0, 0, 0, 0.5);*/
    $box-shadow-primary:       0px 8px 10px -4px rgba(10, 10, 0, 0.5);
    $box-shadow-nav:           0px 3px 10px rgba(0,0,0,0.4);
    
    $transition-duration:       0.3s;

/** 1.5 @Special content types */

/**
    VARIABLES RELATED TO SPECIAL CONTENT TYPES  GO HERE
    example: list-style, table-layout
**/

/** 1.6 @Text */

/**
    VARIABLES RELATED TO TEXT GO HERE
    example: text-align, text-shadow, font
**/

    $heading__font-family: Helvetica, Arial, sans-serif !default;
    $heading__font-weight: normal !default;
    $heading__font-color: $color-font !default;

    $base__font-family: Helvetica, Arial, sans-serif !default;
    $base__font-weight: normal !default;
    $base__font-size: 16px !default;
    $base__line-height: 1.5 !default;
    $base__font-color: $color-font !default;

    $header-font: $heading__font-family !default;
    $standard-font: $base__font-family !default;

/** 1.7 @Visual properties */

/**
    VARIABLES RELATED TO VISUAL PROPERTIES GO HERE
    example: opacity, cursor
**/

/**  2. MODULE CONFIGURATION */

/** MODULE CONFIGURATION GO HERE */


/**  3. BREAKPOINTS */

/** BREAKPOINTS GO HERE */

$RWD-A-small: max-width 480px;

/** @ibm breakpoints */
$RWD-A: max-width 767px;
$RWD-B: 768px 1149px;
$RWD-C: 1150px;

// assume min-width (by default) if only a number
$high-tide: 600px;
// set min-width/max-width if both values are numbers
$ex-presidents: 601px 1199px;
// if one value is a string, assume a feature/value pair
$surfboard-width: max-width 1200x;

/** 4. COLUMNS */
$grid-columns: 12 !default;
$grid-gutter:  25px !default;
