.list{
    &__wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        margin: -10px -20px 20px -20px;
        &_col {
            @extend .list__wrapper;
            flex-direction: column;
        }
    }
    &__item{
        padding: 7px 0;
        font-size: 14px;
        &_header{
            @extend .list__item;
            padding: 0 0 5px 0;
            font-size: 18px;
            font-weight: bold;
        }
        &_extra{
            @extend .list__item;
            padding: 10px 0 0 0;
            font-weight: bold;
        }
        &_brand {
            width: 12.5%;
            padding: 20px;
            /*min-height: 163px;*/
            height: 163px;
            display: flex;
            justify-content: center;
            align-items: center;

            & > a {
                display: block;
                // IE fix
                max-width: 100%;
                & img{
                    width: 100%;
                }
            }
            @include breakpoint($RWD-A) {
                width: 30%;                            
            }
            @include breakpoint($RWD-B) {
                width: 16%;
            }
        }
        &_brand3 {
            -moz-column-count:8;
            -webkit-column-count:8;
            -moz-column-gap: 100px;
            -webkit-column-gap: 100px;
            columns: 100px 8;
            @include breakpoint($RWD-C) {
                -moz-column-gap: 40px;
                -webkit-column-gap: 40px;
                column-gap: 40px;
            }
            @include breakpoint($RWD-B) {
                -moz-column-count: 6;
                -webkit-column-count: 6;
                column-count: 6;
            }
        }
    }
    &__sitemap {
        -moz-column-count: 4;
        -moz-column-gap: 20px;
        -webkit-column-count: 4;
        -webkit-column-gap: 20px;
        column-count: 4;
        column-gap: 20px;
        line-height: 20px;
    }
}
