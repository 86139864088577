.breadcrumb {

    &__wrapper {
        margin: 12px 0;
        @include breakpoint($RWD-A) {
            margin: 0;
        }
    }

}
.breadcrumb-list{
    font-size: 12px;
    &__item{
        display:inline-block;
        &_last{
            @extend .breadcrumb-list__item;
            font-weight:bold;
        }
    }
}
