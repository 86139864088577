.configbasketlist{
	display: block;
    max-width: 930px;
    width:100%;
    display: flex;
	flex-direction: column;
	flex-flow: column;
	margin-bottom: 50px;

}
.configbasketlist-item{
	width:100%;
	height:50px;
	display:flex;
	justify-content: space-between;
	align-items: center;
	background:#f5f5f5;
	border-top: 2px solid #e6e7e7;
	border-left: 2px solid #e6e7e7;
	border-right: 2px solid #e6e7e7;

	&:last-child{
		border-bottom: 2px solid #e6e7e7;
	}

	@include when(mobile) {
		height:auto;
		flex-direction: column;
	}

	&_header{
		width:100%;
		display:flex;
		justify-content: space-between;
		align-items: center;
		font-weight: bold;	

		@include when(mobile) {
			display:none;
		}

		&__text-container{
			display:flex;
			width:61%;	
		}

		&__image{
			max-width: 55px;
			width:100%;
		}

		&__name{
					
		}  	 



		&__quantity{
			width:15%;

		}

		&__a-price{
			width:17%;	
		}

		&__price{
			width:18%;	
		}

		&__remove{
			display: flex;
   			justify-content: center;
			width:10%;
			
		}


	}

	&__text-container{
		width:50%;
		display:flex;
		align-items: center;

		@include when(mobile) {
			width:100%;
			align-items: unset;
			justify-content: space-between;
			padding-bottom:10px;
		}			
	}

	&__text-box{
		display:flex;
		align-items: center;
		

		@include when(mobile) {
			flex-direction:column;
			justify-content: flex-start;
			align-items: unset;
			width:100%;
			border-bottom: 1px solid #e6e7e7;
		}			
	}

	&__image{
		padding-right:10px;
		max-width: 55px;
		width:13%;

		@include when(mobile) {
			width:20%;
		}	

		& img{
			max-height:45px;
			padding:3px;
		}

	}

	&__name{
		padding-right:10px;
		max-width: 210px;
		overflow: hidden;
		text-overflow: ellipsis;

		@include when(mobile) {
			max-width: unset;
			width: 100%;
		}			
	}  	 

	&__atribute{
		padding-right:10px;
	}

	&__atribute-box{
		display: flex;

		@include when(mobile) {
			justify-content: space-between;
		}		
	}

	&__secound-atribut{
		padding-right:10px;
	}

	&__quantity{
		width:25%;

		@include when(mobile) {
			width: auto;
			padding-left: 10px;
		}

		& input{
			max-width: 65px;
			padding-left: 5%;
	    	text-align: center;
		}

	}

	&__a-price{
		width:29%;
		padding-right: 10px;

		@include when(mobile) {
			width: auto;
		}	
	}

	&__price{
		width:30%;
		padding-right: 10px;
		
		@include when(mobile) {
			width: auto;
		}		
	}

	&__remove{
		display: flex;
    	justify-content: center;
		width:16%;
		padding-right: 5px;
		text-align: right;

		@include when(mobile) {
			width: auto;
		}		

		& div{
			height:25px;
			width:25px;
			display:flex;
			align-items: center;
			justify-content: center;
			background:grey;
			color: white;
			cursor: pointer;

			@include when(mobile) {
				width:50px;
			}	

		}
	}
	&__mobile-header{
		display:none;
		width:100%;
		font-weight: bold;
		padding-bottom:5px;

		@include when(mobile) {
			display:flex;
		}			

		&__quantity{
			width:32%;
		    padding-left: 10px;

		}

		&__a-price{
			width:29%;
		    
		}

		&__price{
			width:30%;
		    	
		}

		&__remove{
			width:16%;
		    		
		}		
	}

}