/* ----- FLEX-GRID ----- */

.flex-grid {
    $flexGrid_size: 12;
    $flexGrid_colSpacing: 20px;

    display: flex;
    flex-wrap: wrap;
    margin-left: -#{$flexGrid_colSpacing / 2};
    margin-right: -#{$flexGrid_colSpacing / 2};

    &_col {
        margin-bottom: #{$flexGrid_colSpacing};
        padding: 0 #{$flexGrid_colSpacing / 2};
        flex: 0 0 auto;

        @for $i from 1 through 12 {
            &-#{$i} {
                width: #{(100 / $i) + '%'};
            }
        }

        @for $i from 1 through 12 {
            @include breakpoint($RWD-B) {
                &-md-#{$i} {
                    width: #{(100 / $i) + '%'};
                }
            }
        }

        @for $i from 1 through 12 {
            @include breakpoint($RWD-A) {
                &-sm-#{$i} {
                    width: #{(100 / $i) + '%'};
                }
            }
        }
    }
}

/* ----- TEXT ----- */

body {
    font-family: $base__font-family;
    font-size: $base__font-size;
    font-weight: $base__font-weight;
    line-height: $base__line-height;
    color: $base__font-color;
}

/* ----- HEADINGS ----- */

.h1, .h2, .h3, .h4, .h5, .h6 {
    color: $heading__font-color;
}

.h1 {
    font-size: 36px;
}

.h2 {
    font-size: 22px;
}

.h3 {
    font-size: 18px;
}

h1, .h1 { @extend .h1; }
h2, .h2 { @extend .h2; }
h3, .h3 { @extend .h3; }
h4, .h4 { @extend .h4; }
h5, .h5 { @extend .h5; }
h6, .h6 { @extend .h6; }

/* ----- LINKS ----- */

a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}

.default-anchor {
    font-size: 1em;
    text-transform: none;
    color: $color-primary;
    font-weight: 500;

    &:hover {
        text-decoration: underline;
        color: lighten($color-primary, 10%);
    }
}

/* ----- MEDIA ----- */

img, object, audio, video, svg {
    max-width: 100%;
}

/* ----- CUSTOM SETTINGS ----- */
*, *:before, *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

/* ----- OVERRIDES ----- */
.store_preview_dialog_window,
.dijitDialog {
    z-index: 20000 !important;
}
