.button {

	@include trans(0.1s ease-in-out);
    border: 0;

	color: $White;

    &:disabled {
        opacity: .5;
        cursor: default;
    }

	&_secondary {
        @extend .button;

        border-radius: 5px;
        background: $color-button-secondary;
        font-size: 12px;
        font-family: $header-font;
        border: 0;

        &:active {
            background: $color-button-secondary-active;
        }

        &:hover {
            background: $color-button-secondary-hover;
        }

        &:disabled {
            opacity: .5;
            cursor: default;

            &:active,
            &:hover {
                background: $color-button-secondary;
            }
        }

        &_lower-right {
            @extend .button_secondary;

            position: absolute;
            bottom: 20px;
            right: 20px;
        }

        &_order {
            @extend .button_secondary;
            float: right;
            height: 78px;
            line-height: 78px;

            &_total {
                @extend .button_secondary_order;

                font-size: 100%;
            }
        }
    }

    &__primary {
        @extend .button_secondary;
        border: 0;
    }

    // &_buy_added {
    //     background-color: $ConfirmGreen;

    //     &:focus {
    //         background-color: $ConfirmGreen;
    //     }

    //     &:hover {
    //         background-color: lighten($ConfirmGreen, 5%);
    //     }
    // }

		&__toggle {
				@include trans(0.1s ease-in-out);
				@include btn-theme($White, $Black);

				border-radius: 5px;
				color: $Black;
		}

    &__filter {
        @include trans(0.1s ease-in-out);
        @include btn-theme($White, $Black);

        border-radius: 5px;
        color: $Black;

        &.selected {
            //@extend .button__filter;

            border-color: $ConcreteGrey;
            background: $ConcreteGrey;
            text-transform: none;
            font-size: 14px;
            color: $White;

            &:hover {
                border-color: $ConcreteGrey;
                background: darken($ConcreteGrey, 3%);
            }
        }

        &__icon_sort-asc {
            @extend .fa-long-arrow-up;
            margin-right: 3px;
        }

        &__icon_sort-desc {
            @extend .fa-long-arrow-down;
            margin-right: 3px;
        }
    }

    &__standard {
        @include trans(0.1s ease-in-out);

        border-radius: 5px;
        border: 1px solid $Black;
        background-color: $White;
        color: $Black;
        text-transform: uppercase;
        font-weight: bold;
        line-height: 30px;
        height: 32px;

        &:hover {
            border-color: $color-primary;
            background-color: $color-primary;
            color: $White;
        }

        &_name {
            font-weight: 900;
        }

        &.selected {
            //@extend .button__standard;

            border-color: $ConcreteGrey;
            background: $ConcreteGrey;
            color: $White;
        }

        &_compare {
            @extend .button__standard;

            text-align: center;
            margin-bottom: 5px;

            &:hover {
                border-color: $color-primary;
                background-color: $color-primary;
                color: $White;
            }

            &.selected {
                //@extend .button__standard;

                border-color: $ConcreteGrey;
                background: $ConcreteGrey;
                color: $White;
            }
        }

        &_show-more {
            @extend .button__standard;

            font-size: 11px;
            background-color: transparent;
            line-height: 28px;
            height: 28px;
            font-size: 11px;
        }
    }

    &__outline {
        &_filter {
            margin-bottom: 10px;
        }
    }

    &__form {
        @include when(desktop) {
            margin-top: 23px;
        }
    }

}

input[type=button] {
    border: 0;
    color: $White;
    border-radius: 5px;
    background: $color-button-secondary;
    font-size: 12px;
    font-family: $header-font;
    cursor: pointer;
    display: inline-block;
    line-height: 30px;
    padding: 0 30px;
    font-size: 14px;
    text-transform: uppercase;

    &:hover {
        background: $color-button-secondary-hover;
    }
}

/* -------- CHECKBOX STYLE -------- */
/* Base for label styling */
[type="checkbox"]:checked,
[type="checkbox"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

[aria-checked="false"] p,
[aria-checked="true"] p,
[type="checkbox"]:checked + label,
[type="checkbox"]:not(:checked) + label {
    display: block;
    position: relative;
    padding-left: 29px;
    cursor: pointer;
    line-height: 1.4;
}

.checkbox-right[type="checkbox"]:checked + label,
.checkbox-right[type="checkbox"]:not(:checked) + label {
    padding-right: 29px;
}

/* checkbox aspect */
/*[aria-checked="false"] p:before,
[aria-checked="true"] p:before,
[type="checkbox"]:checked + label:before,
[type="checkbox"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    width: 19px;
    height: 19px;
    left: 0;
    top: 0;
    border: 1px solid #b6b6b6;
    border-radius: 3px;
    background: #fff;
}*/
[aria-checked="false"] p:before,
[aria-checked="true"] p:before,
[type="checkbox"]:checked + label:before,
[type="checkbox"]:not(:checked) + label:before {
  position: absolute;
  left: 0;
  top: 0;
  border: 1px solid #b6b6b6;
  border-radius: 3px;
  background: $White;
  display: inline-block;
  width: 19px;
  height: 19px;
  content: '';
}

.checkbox-right[type="checkbox"]:checked + label:before,
.checkbox-right[type="checkbox"]:not(:checked) + label:before {
    right: 0;
    top: 0;
    left: auto;
}

/* checked mark aspect */
/*[aria-checked="false"] p:after,
[aria-checked="true"] p:after,
[type="checkbox"]:checked + label:after,
[type="checkbox"]:not(:checked) + label:after {
    content: '\2713';
    position: absolute;
    top: 3px;
    left: 3px;
    font-size: 18px;
    line-height: 0.8;
    color: #2ecc71;
    transition: all 0.2s;
}*/
[type="checkbox"]:checked + label:before {
  border: 1px solid $LightOrange;
  background-color: $LightOrange;
}

/* checked mark aspect changes */
[aria-checked="false"] p:after,
[type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
}

[aria-checked="true"] p:after,
[type="checkbox"]:checked + label:after {
    opacity: 1;
    transform: scale(1);
}

/* disabled checkbox */
[type="checkbox"]:disabled:checked + label:before,
[type="checkbox"]:disabled:not(:checked) + label:before {
    box-shadow: none;
    border-color: #bbb;
    background-color: #ddd;
}

[type="checkbox"]:disabled:checked + label:after {
    color: #999;
}

[type="checkbox"]:disabled + label {
    color: #aaa;
}

/* accessibility */
[aria-checked="false"]:active p:before,
[aria-checked="true"]:active p:before,
[type="checkbox"]:checked:active + label:before,
[type="checkbox"]:not(:checked):active + label:before {
    border: 1px solid #b6b6b6;
    background-color: #f6f6f6;
}

/* filter */
[type="checkbox"]:not(:checked) + .filter-list__checkbox_disabled:before {
    box-shadow: none;
    border-color: #bbb;
    background-color: #ddd;
}