.quick-order {
    position: relative;

    &__headers {
        font-family: $header-font;
        font-size: 11px;
        text-transform: uppercase;
        height: 33px;
        line-height: 33px;
        vertical-align: middle;

        @include when(mobile) {
            float: left;
            width: 140px;
            height: auto;
        }
    }

    &__inputs {
        background-color: $LightGrey;
        height: 34px;
        line-height: 34px;

        @include when(mobile) {
            float: left;
            width: calc(100% - 140px);
            height: auto;
            background-color: transparent;
        }
    }

    &__cell {
        float: left;
        height: 100%;
        padding: 0 10px;


        @include when(mobile) {
            float: none;
            width: 100% !important;
            height: 34px;

            &:nth-child(even) {
                background-color: $LightGrey;
            }
        }

        input {
            width: 100%;
        }

        &_mark {
            @extend .quick-order__cell;

            width: 140px; 
        }

        &_art-number {
            @extend .quick-order__cell;

            width: 140px;
        }

        &_quantity {
            @extend .quick-order__cell;

            width: 75px;
        }
    }
}