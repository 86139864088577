.media-container {
    position: relative;
    width: 100%;
    @include when(mobile) {
        display:block;
        float: none;
        margin-bottom: 0 !important;
    }
    &__scroller-container {
      position: relative;
      display: none;
      width: 100%;
      background-color: $White;
      z-index: 4;
      margin-top: -4px;
    }
    &__scroller-ul{
        cursor: default;
        z-index: 1000;
        background-color: $White;
        position: absolute;
        width: 100%;
        border: solid 1px $color-container-bg;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        overflow-x: hidden;
    }
    &__org-li{
      padding: 5px 10px 0px;
      height: 30px;
    	border-bottom: solid 1px $color-container-bg;
    	box-sizing: border-box;
      width: 100%;
      overflow: hidden;
    }
    &__org-li:hover {
      background-color: $color-container-bg;
    }
    &__org-li-footer{
      padding: 5px 10px 0px;
      height: 50px;
    	border-bottom: solid 1px #e0e0e0;
    	box-sizing: border-box;
      width: 100%;
      overflow: hidden;
      text-align: center;
    }
    &__org-li-footer:after{
      content:'---------------------------------------------------------------------------------------------------------------------';
    max-height:0;
    overflow:hidden;
    display:block;
    height: 50px;
    word-break:break-all;
    }
    &__attrList {
        font-size: 13px;
        line-height: 26px;
        &:nth-child(2n) {
            background-color: $PropertiesZebra;
        }
        &_name {
            text-align: left;
            width: 70%;
            padding: 0px 5px 7px 5px;

            font-size: 11px;
            text-transform: uppercase;
            font-family: $header-font;
            line-height: 13px;
        }
        &_value {
            text-align: left;
            line-height: 13px;
            padding: 5px;
        }

        @include when(mobile) {
            &_name {
                width: 50%;
            }
        }
    }
    &__articleInfoContainer {
        position: relative;
        background-color: $color-container-bg;
        border-left: 1px solid $color-border-primary;
        border-right: 1px solid $color-border-primary;
        border-bottom: 1px solid $color-border-secondary;
    }
    &__articleGroup {
        overflow: hidden;
        width: 50%;
        border-left: 2px solid $White;
        padding: 12px;
        &:first-child {
            border-left: none;
        }
        &_half {
            @extend .media-container__articleGroup;
            width: 50%;
        }
    }
    &__weightBox {
        display:inline-block;
        padding-left: 15px;
        @include when(mobile) {
            padding-left: 0px;
            width:100%;
            margin: 15px 0 30px 0;
        }    
    }    
    &__toggle{
        background: $color-border-secondary;
        color: $White;
        width: 100%;
    }
    &__close{
        height: 0 !important;
    }
    &__articleInfoWrapper {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: stretch;
        align-content: stretch;
        &_col {
            @extend .media-container__articleInfoWrapper;
            flex-direction: column;
            display: flex;
            height: 100%;
            @include when(mobile) {
                height:auto;
            }
        }
        &_row {
            @extend .media-container__articleInfoWrapper;
            flex-direction: row;
            flex: auto;
            overflow: hidden;
        }
    }
    &__articleCellNumber {
        flex: 1;
        &:hover a {
            text-decoration: underline;
        }
    }
    &__articleCellName {
        flex: 1;
        padding-left: 12px;
        &:hover a {
            text-decoration: underline;
        }
    }
    &__articleColWrapper {
        width: 50%;
        @include when(mobile) {
            width: 100%;
            height: auto;
        }

        &:first-child{
            border-left: 0;
            border-right: 2px solid $White;
            @include when(mobile) {
                border-bottom: 2px solid $White;
                border-right: 0;
            }
        }
        &:last-child{
            @include when(mobile) {
                border-bottom: 0;
            }
        }
    }
    &__articleGroupBottom {
        padding: 12px;
        border-top: 2px solid $White;
    }
    &__articleGroupTop {
        padding: 12px;
        border-bottom: 2px solid $White;
    }
    &__articleInfoLabel {
        display: block;
        margin-bottom: 12px;
        font-weight: bold;
    }
    &__articleInfoData {
        display: block;
        margin-bottom: 12px;
        /*&:last-child {
            margin-bottom: 0px;
        }*/
    }
    &__articleGroupHide {
        height: 0;
        overflow: hidden;
    }
    &__half {
        width: 50%;
        float: left;
        @include when(mobile) {
            width: 100%;
        }
        &_left{
            @extend .media-container__half;
            padding-right: 20px;
            @include when(mobile) {
                padding-right: 0;
                margin-bottom: 40px;
            }
        }
        &_right{
            @extend .media-container__half;
            padding-left: 20px;
            @include when(mobile) {
                padding-left: 0;
            }
        }
    }
    &__title {
        font-size: 15px;
        line-height: 150%;
        width: 100%;
        background: $color-title-bg;
        padding: 8px 12px;
        color: $White;
        min-height: 38px;
        text-transform: uppercase;
        font-family: $header-font;
    }
    &__sub-title {
        font-size: 12px;
        margin-top: 5px;
        margin-bottom: 20px;
    }
    &__singel-box {
        padding: 30px 20px;
        width: 100%;
        overflow:hidden;
        background-color: $LightGrey;
        position: relative;
    }
    &__left-box {
        display: flex;
        flex-flow: column;
        width: 50%;
        padding: 5px 20px;
        @include when(tablet) {
            width: 100%;
            border-right: 1px solid $color-border-primary;
            padding: 5px 20px 0 20px;
        }
        @include when(mobile) {
            width: 100%;
            // border-right: 1px solid $color-border-primary;
            padding: 5px 20px 0 20px;
        }
        &_border {
            @extend .media-container__left-box;

            border-right: 1px solid $Black;
            padding: 5px 20px 5px 20px;

            @include when(mobile) {
                border-right: 0;
            }
            @include when(tablet) {
                border-right: 0;
            }
        }
        &_info {
            @extend .media-container__left-box;
            width: 65%;
            padding: 5px 20px;
            /*border-right: none;*/
            line-height: 1.6;
            @include when(mobile) {
                width: 100%;
                display: block;
            }
            @include when(tablet) {
                width: 100%;
                display: block;
            }
        }
        &_user-list {
            @extend .media-container__left-box;
            /*padding: 12px 6px 12px 12px;*/
            @include when(mobile, tablet) {
                padding: 0 12px 12px 12px;
            }
        }
    }
    &__center-box{
        padding: 5px 20px;
    }
    &__right-box {
        display: flex;
        flex-flow: column;
        width: 50%;
        padding: 5px 20px;
        @include when(tablet) {
            width: 100%;
            border-left: 1px solid $color-border-primary;
            padding: 0 20px 5px 20px;
        }
        @include when(mobile) {
            width: 100%;
            border-left: 1px solid $color-border-primary;
            padding: 0 20px 5px 20px;
        }
        &_border {
            @extend .media-container__right-box;
            padding: 5px 20px 5px 20px;
        }
        &_info {
            @extend .media-container__right-box;

            border-left: 1px solid $Black;
            width: 35%;
            padding: 5px 20px;
            line-height: 1.6;

            @include when(mobile) {
                width: 100%;
                display: block;
                border-left: 0 !important;
            }
            @include when(tablet) {
                width: 100%;
                display: block;
                border-left: 0 !important;
            }
        }
        
        &_nobb {
            @extend .media-container__right-box;
            @include when(mobile) {
                padding: 5px 20px 0;
            }
            @include when(tablet) {
                padding: 5px 20px 0;
            }
        } 
               
        &_user-list {
            @extend .media-container__right-box;
            padding: 12px 12px 12px 6px;
            @include when(mobile, tablet) {
                padding: 0 12px;
            }
        }
    }
    &__flex-box{
        display: flex;
        flex-flow: row wrap;
    }
    &__quadruple{
        width: 25%;
        padding: 12px;
        border-right: 3px solid $White;
        vertical-align: top;
        &:first-child{border-left: 1px solid $color-border-primary;}
        &:last-child{border-right: 1px solid $color-border-primary;}
        @include when(mobile) {
            width: 100%;
            border-left: 1px solid $color-border-primary;
            border-right: 1px solid $color-border-primary;
            &:nth-child(n + 2){
                margin-top: -24px;
            }
        }
        @include when(tablet) {
            width: 50%;
            &:nth-child(even){
                border-right: 1px solid $color-border-primary;
            }
            &:nth-child(n+3){
                border-top: 3px solid $White;
            }
        }
    }
    &__right-quadruple{
        display: table-cell;
        width: 25%;
        padding: 12px;
        border-right: 1px solid $color-border-primary;
        border-left: 1.5px solid $White;
        vertical-align: top;
    }
    &__left-quadruple{
        display: table-cell;
        width: 25%;
        padding: 12px;
        border-left: 1px solid $color-border-primary;
        border-right: 1.5px solid $White;
        vertical-align: top;
    }
    &__mid-quadruple{
        display: table-cell;
        width: 25%;
        padding: 12px;
        border-right: 1.5px solid $White;
        border-left: 1.5px solid $White;
        vertical-align: top;
    }

    /*Used to calculate the number of text lines in product information*/
    &__static-width {
        position: absolute;
        width: 672px !important;
    }
    &__long-desc {
        overflow: hidden;
        max-height: 206px;
        transition: all 0.2s ease;
        position: relative;
        font-size: 14px;
        @include when(mobile) {
            font-size: 13px;
        }
        &.active {
            max-height: none;
        }
        & table {
            @include when(mobile) {
                width: 100%;
            }
        }
    }
    &__box-title {
        font-size: 15px;
        font-family: $header-font;
        text-transform: uppercase;
    }
    &__summery {
        padding: 20px 1% 20px 0;
    }
    &__button-box {
        overflow: hidden;
        width: 100%;
    }
    &__button {
        padding: 12px 0;
        text-align: center;
        text-transform: uppercase;
        display: block;
        background: $color-button-secondary;
        color: $White;
        border-radius: 5px;
    }
    &__align-right {
        text-align: right;
    }
    &__functions {
        padding: 20px 1% 20px 0;
    }
    &__box-border {
        display: flex;
        flex-wrap: wrap;
        background-color: $LightGrey;
        padding: 20px 0;
        width: 100%;
        &_user-list {
            @extend .media-container__box-border;
            background: $White;
            border-bottom: 1px solid $color-border-primary;
            padding: 10px 0;
            &_details {
                @extend .media-container__box-border_user-list;
                background: $LightGrey;
            }
        }
        @include when(mobile) {
            display: block;
            margin-bottom: 15px;
        }
    }
    &__list {
        float: left;
        &_title {
            @extend .media-container__list;
            clear: left;
            font-weight: bold;
            margin-right: 5px;
        }
    }
    &__search {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 280px;
        z-index: 1;
    }
    &__searchRow {
        background: $White;
        border-bottom: 1px solid $color-border-primary;
        &:last-child{
            border-bottom: none;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }
    &__searchLink{
        display: block;
        width: 100%;
        height: 100%;
        padding: 6px 12px;
        line-height: 16px;
        font-size: 15px;
        white-space: normal;
        &:hover {
            background: $color-container-bg;
        }
        &:focus {
            outline: 0;
            background: $color-container-bg;
        }
    }
    &__searchActive{
        background: $color-container-bg;
    }
    &__reportSummary {
        border-bottom: 1px solid #e9e9e9;
        padding: 10px 0;
        position: relative;
        & p {
            padding-right: 20px;
        }
        & .icon {
            position: absolute;
            right: 0;
            top: 10px;
            width: 20px;
        }
    }
    &__dialog{
      background: white;
      border-radius: 5px;
      padding: 10px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    }
    &__label {
        font-size: 13px;
        text-transform: uppercase;
    }
    &__explanation {
    	display: block;
        font-size: 11px;
        line-height: 12pt;
        font-style: italic;
    }
    &__buttons {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        justify-content: flex-end;
        margin-top: 10px;
        font-size: 0;
        &_left{
            @extend .media-container__buttons;

            align-items: flex-start;
            @include when(mobile) {
                margin-bottom: 20px;
            }
            @include when(tablet) {
                margin-bottom: 20px;
            }
        }
        &_right{
            @extend .media-container__buttons;
        }
    }
    &__border {
        border-top: 1px solid $Black;
        margin: 30px 0;

        &_box {
            border-top: 1px solid $Black;
            margin: 20px 0;
        }
        &_info {
            border-top: 1px solid $Black;
            margin: 20px 20px 0 20px;
        }
    }
    &__text{
        font-size: 13px;
    }
    &__articleCode{
        background: $ErrorRed;
        color: $White;
        font-size: 13px;
        font-weight: 900;
        text-align: center;
        padding: 4px 16px;
    }

    @include when(mobile) {
        &__mobile &__box-border {
            padding: 0;
            background-color: transparent;
        }
        &__mobile &__left-box_info,
        &__mobile &__right-box_info,
        &__mobile &__center-box {
            padding: 0;
            margin-bottom: 10px;
        }
        &__mobile &__left-box_border,
        &__mobile &__right-box_border,
        &__mobile &__center-box_border {
            background-color: $LightGrey;
            border: 0;
        }
        &__mobile &__box-title {
            display: block;
            padding: 20px 15px;
            color: white;
            font-size: 14px;
            background-color: $color-primary;

            .fa {
                line-height: inherit;
            }
        }
        &__mobile &__long-desc {
            max-height: none;
            padding: 15px;
            background-color: $LightGrey;
            display: none;

            ul {
                margin-top: 0;
            }
        }
        &__mobile &__articleCode {
            margin-bottom: 15px;
        }
        &__mobile &__center-box {
            background-color: $LightGrey;
            padding: 10px 15px;

            &_mobile {
                padding: 0;
            }
        }
        &__mobile &__left-box_info.show &__long-desc,
        &__mobile &__right-box_info.show &__long-desc,
        &__mobile &__center-box.show &__long-desc {
            display: block;
        }
        &__mobile &__left-box_info.show,
        &__mobile &__right-box_info.show,
        &__mobile &__center-box.show {
            display: block;

            .fa-minus {
                display: block !important;
            }
            .fa-plus {
                display: none;
            }
        }
    }

}
.js-overflow-fade-out {
    display: block;
    height: 100%;
    position: absolute;
    bottom: 0;
    width: 100%;
    max-height: 50px;
    & a {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      bottom: 0;
    }
}
.js-overflow-fade-out.active {
/*    background: -webkit-linear-gradient(top,rgba(245, 242, 239,0),white);
    background: -moz-linear-gradient(top,rgba(245, 242, 239,0),white);
    background: -ms-linear-gradient(top,rgba(245, 242, 239,0),white);
    background: -o-linear-gradient(top,rgba(245, 242, 239,0),white);
    background: linear-gradient(to bottom,rgba(245, 242, 239,0),rgba(245, 242, 239,1));*/
    background: linear-gradient(to top,$LightGrey 20%, rgba(0,0,0,0) 100%);/* IE */
    background-image: -webkit-linear-gradient(top, $LightGreyTransp, $LightGrey);/* Chrome10-25,Safari5.1-6 */
 }
.js-overflow-area.active > .js-overflow-fade-out.active {
    display: none !important;
}
.jconfirm-box {
    width: 100%;
    margin: auto;
    max-width: 512px;
    padding: 20px 20px 5px 20px !important;
    & .btn,
    & .btn-default {
        @extend .button_secondary;
        color: $White !important;
        &:hover{
           background: $color-button-secondary-hover !important;
        }
    }
}


.column {
    margin-bottom: 10px;
}
