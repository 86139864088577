.breadcrumb-list {
    &__item{
        .fa {
            font-family: $standard-font;

            &::before {
                content: '/';
                font-weight: 500;
            }
        }
    }
}
