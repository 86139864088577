.user-list {
    a {
        font-size: 1em;
        text-transform: none;
        color: $color-primary;
        font-weight: 500;

        &:hover {
            text-decoration: underline;
            color: lighten($color-primary, 10%);
        }
    }
    &__value{
        &_long {
        @extend .user-list__value;
        width: 70%;
        }   
    }
}
       