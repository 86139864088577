.clearfix:before, .clearfix:after {
    content: "";
    display: table; }

.clearfix:after,
.clearBoth {
    clear: both; }

.d-flex {
    display: flex;
    @include breakpoint($RWD-A) {
        display: block;
    }
}
.d-block { display: block; }
.d-block-mob {
    @include breakpoint($RWD-A) {
        display: block;
    }
}
.clearfix { zoom: 1; }
.fl-r { float: right; }
.fl-l { float: left; }
.ta-r { text-align: right; }
.mb-5 { margin-bottom: 5px; }
.mb-7 { margin-bottom: 7px; }
.mb-10 { margin-bottom: 10px; }
.mb-10i { margin-bottom: 10px !important; }
.mob-mb-10 {
    @include breakpoint($RWD-A) {
        margin-bottom: 10px;
    }
}
.mb-15 { margin-bottom: 15px; }
.mb-20 { margin-bottom: 20px; }
.mb-25 { margin-bottom: 25px; }
.mb-30 { margin-bottom: 30px; }
.mb-30i { margin-bottom: 30px !important; }
.mb-35 { margin-bottom: 35px; }
.mb-40 { margin-bottom: 40px; }
.mb-40i { margin-bottom: 40px !important; }
.mb-50 { margin-bottom: 50px; }
.mb-50i { margin-bottom: 50px !important; }
.mb-60 { margin-bottom: 60px; }
.mb-70 { margin-bottom: 70px; }
.mb-90 { margin-bottom: 90px !important; }
.mb-100 { margin-bottom: 100px; }
.mt-5 { margin-top: 5px; }
.mt-10 { margin-top: 10px; }
.mt-10i { margin-top: 10px !important;}
.mt-12 { margin-top: 12px; }
.mt-15 { margin-top: 15px; }
.mt-20 { margin-top: 20px; }
.mt-30 { margin-top: 30px; }
.mt-40 { margin-top: 40px; }
.mt-48 { margin-top: 48px; }
.mt-50 { margin-top: 50px; }
.mt-60 { margin-top: 60px; }
.mt-200 { margin-top: 200px; }
.mt-minus9 { margin-top: -9px; }
@media (max-width: 600px) {
    .mb-10mob { margin-bottom: 10px; }
}
@media (max-width: 600px) {
    .mb-50mob { margin-bottom: 50px; }
}
.mr-1em { margin-right: 1em; }
.mr-2 { margin-right:2px; }
.mr-5 { margin-right:5px; }
.mr-12 { margin-right: 12px; }
.mr-20 { margin-right: 20px; }
.mr-25 { margin-right: 25px; }
.mr-50 { margin-right: 50px; }
.ml-5em { margin-left: 5em; }
.ml-18pr { margin-left: 18%; }
.ml-2 { margin-left: 2px; }
.ml-6 { margin-left: 6px; }
.ml-2x { margin-left: -2px; }
.ml-10 { margin-left: 10px; }
.mob-ml-10 {
    @include breakpoint($RWD-A) {
        margin-left: 10px;
    }
}
.ml-20 { margin-left: 20px; }
.ml-30 { margin-left: 30px; }
.mr-2 { margin-right: 2px; }
.mr-10 { margin-right: 10px; }
.mr-30 { margin-right: 30px; }
.mt-25 { margin-top: 25px; }
.mt-75 { margin-top: 75px; }
.p-10{ padding: 10px !important; }
.pr-20 { padding-right: 20px; }
.pl-20 { padding-left: 20px; }
.pr-50i { padding-right: 50px !important; }
.pt-50i { padding-top: 50px !important; }
.pt-8i { padding-top: 8px !important; }
.pb-10i { padding-bottom: 10px !important; }
.pb-20 { padding-bottom: 20px; }
.pb-20i { padding-bottom: 20px !important; }
.pb-30 { padding-bottom: 30px; }
.pb-30i { padding-bottom: 30px !important; }
.pb-50 { padding-bottom: 50px; }
.pb-60 { padding-bottom: 60px; }
.pb-60i { padding-bottom: 60px !important; }
.ptb-20 { padding: 20px 0; }
.p-45 { padding: 45px; }
.pt-2 { padding: 2px; }
.plr-70 { padding: 0 70px; }
.plr-95 { padding: 0 95px; }
.plr-40 { padding: 0 40px; }
.pr-25 { padding-right: 25px !important; }
.lh-20px { line-height: 20px; }
.lh-n { line-height: normal; }
.tt-u { text-transform: uppercase; }
.tt-c { text-transform: capitalize; }
.ta-c { text-align: center; }
.ta-l { text-align: left; }
.ta-r { text-align: right; }
.va-m8 { vertical-align: -8px; }
.fz-14 { font-size: 14px; }
.fz-20 { font-size: 20px; }
.fw-bold{ font-weight:bold; }
.p-rel {position: relative; }
.ta-c {text-align:center;}

.w-100p{width:100%;}
.h-100px{height:100px;}
.w-600px{width:600px;}

.bt-1{border-top: 1px solid $color-border-secondary;}
.br-1{border-right: 1px solid $color-border-secondary;}
.bb-1{border-bottom: 1px solid $color-border-secondary;}
.bl-1{border-left: 1px solid $color-border-secondary;}


// Responsive modifiers

// Max pixel width
$rm-max-pixel-width: 200;

// Max percentage width
$rm-max-percentage-width: 100;

@each $label, $breakpoint in $when-mixin-config {
    @include breakpoint($breakpoint) {
        .#{$label} {
            &_ta-r {
                text-align: right;
            }

            &_of-h {
                overflow: hidden;
            }

            @for $i from 1 through ($rm-max-pixel-width / 5) {
                &_w-#{$i*5}px   { width: #{($i*5)+'px'}; }
                &_mw-#{$i*5}px   { max-width: #{($i*5)+'px'}; }
            }

            @for $i from 1 through ($rm-max-percentage-width / 5) {
                &_w-#{$i*5}perc { width: #{($i*5)+'%'}; }
            }
        }
    }
}

.hideVisually {
    height: 0px;
    width: 0px;
    background-color: transparent;
    position: absolute;
    overflow: hidden;
    content: ' ';
}
.w-100{
    width:100%;
}
.of-h{
    overflow:hidden;
}

.of-h-mobile {
    @include when(mobile) {
        overflow: hidden;
    }
}

.c-red{
    color:#ef0303;
}

.red-error-message {
    color: $ErrorRed;
}

.no-scroll {
    overflow: hidden;

    &.ua-iOS { position: fixed; }
}

.no-scroll_mobile {
    @include when(mobile) {
        overflow: hidden;

        &.ua-iOS { position: fixed; }
    }
}

.no-scroll_tablet {
    @include when(tablet) {
        overflow: hidden;

        &.ua-iOS { position: fixed; }
    }
}

.no-scroll_desktop {
    @include when(desktop) {
        overflow: hidden;

        &.ua-iOS { position: fixed; }
    }
}

.hide{
    display: none !important;
    &_mobile {
        @include breakpoint($RWD-A) {
            display: none !important;
        }
    }
    &_tablet {
        @include breakpoint($RWD-B) {
            display: none !important;
        }
    }
    &_desktop {
        @include breakpoint($RWD-C) {
            display: none !important;
        }
    }
}

.onClick{
    cursor:pointer;
}
.closeOverlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    background-color: rgba(0,0,0,0.5);
    z-index: 1200;
    height: 110%;

    .spinner-clear {
        display: none;
    }

    &.has-spinner .spinner-clear {
        display: block;
    }
}

.spinnerOverlay{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    z-index: 9;
}

.spinner {
    background: $White;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
    font-size: 32px;
    padding: 3px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    line-height: 1;
}

.spinner-clear {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
    font-size: 32px;
    line-height: 1;
}

.spinner-svg {
    @keyframes spinner-svg-animation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    animation: spinner-svg-animation 1s infinite steps(8);

    &_relative {
        height: 100%;
        width: 100%;
        display: block;
    }

    &_header {
        height: 32px;
        display: block;
    }

    &_overlay {
        height: 54px;
        display: block;
    }

    &_load-more {
        height: 21px;
        vertical-align: middle;
        margin-top: -4px;
        margin-left: 5px;
    }

    &_login {
        height: 16px;
        margin-top: -3px;
        margin-left: 5px;
        vertical-align: middle;
    }
}

@keyframes loadingTextDots {
  0%   { content: '.';   }
  33%  { content: '..';  }
  66%  { content: '...'; }
}

.loading-text-dots {
    &::after {
        content: '.';
        animation-name: loadingTextDots;
        animation-duration: 1s;
        animation-iteration-count: infinite;
    }
}

.loading-text-position {
    padding-bottom: 40px;
    display:block;
    @include when(mobile) {
        padding:20px 0 40px 5px;
    }
}

.loading-gradient {
    @keyframes ProgressRowGradient {
        0%   { background-position-x: 300% }
        100% { background-position-x: 0%   }
    }

    &__row {
        display: inline-block;
        width: 80%;
        border-radius: 2px;
        background: linear-gradient(270deg,rgba(0,0,0,.09),rgba(0,0,0,.02) 40%,rgba(0,0,0,.09));
        animation: ProgressRowGradient 4s linear infinite;
        background-size: 300%;

        &::after {
            content: "\00a0\00a0";
        }
    }
}

.icon-orange{
    color: $color-icon-secondary;
        &_left{
            @extend .icon-orange;
            padding-left: 6px;
        }
        &_right{
            @extend .icon-orange;
            padding-right: 6px;
        }
}
.icon {
    &_left{
        padding-left: 6px;
    }
    &_right{
        padding-right: 6px;
    }
}

.line {
    clear: both;
    position: relative;
}
.mob-flex-col{
    @include breakpoint($RWD-A) {
        flex-direction: column !important;
    }

}
.verticalDevider {
    margin-right: 20px;
    margin-bottom: 20px;
    border-right: 2px solid $MiddleGrey;
    @include breakpoint($RWD-A) {
        margin-right: 0px;
        border-right: none;
    }
}
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
     -khtml-user-select: none; /* Konqueror */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  not supported by any browser */
}

//IBM Digital Analytcs
#cm-tag-output {
    display: none;
}


.status-badge {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    vertical-align: middle;

    &_green {
        @extend .status-badge;
        background-color: $ConfirmGreen;
    }

    &_yellow {
        @extend .status-badge;
        background-color: $NoticeYellow;
    }

    &_red {
        @extend .status-badge;
        background-color: $ErrorRed;
    }
}

.wrapped-text {
    &__parentheses {
        &::before { content: '(' }
        &::after  { content: ')' }
    }

    &__double-quotes-sv {
        &::before { content: '”' }
        &::after  { content: '”' }
    }

    &__single-quotes-sv {
        &::before { content: '’' }
        &::after  { content: '’' }
    }

    &__double-quotes-en {
        &::before { content: '“' }
        &::after  { content: '”' }
    }

    &__single-quotes-en {
        &::before { content: '‘' }
        &::after  { content: '’' }
    }
}
.td-u {
    text-decoration: underline;
}

/* ----- IOS TOUCHSCREEN FIX ----- */
@include when(mobile, tablet) {
    @media only screen and (-webkit-min-device-pixel-ratio : 2) {
        html.ua-iOS {
            * { 
                cursor: pointer; 
                -webkit-tap-highlight-color: rgba(0,0,0,0);
                -webkit-tap-highlight-color: transparent;
            }
        }
    }
}