// SCSS CONFIGURATION  - SITEWIDE VARIABLES

// CONTENTS ------------
//  - BASE VARIABLES
//      - SIZES
//      - FONTS
//      - SIZING
//  - MODULE CONFIGURATION
//      - LAYOUT
//      - LISTS
//      - MEDIA
// ---------------------

/**  1. Base Variables */

/** 1.1 @Positioning */

/**
    VARIABLES RELATED TO POSITIONING GO HERE
    example: position, top, z-index
**/

/** 1.2 @Box behavior and properties */

/**
    VARIABLES RELATED TO BOX BEHAVIOR GO HERE
    example: float, display, overflow
**/

/** 1.3 @Sizing */

/**
    VARIABLES RELATED TO SIZING GO HERE
    example: margin, padding
**/

/** 1.4 @Color appearance */

/**
    VARIABLES RELATED TO COLOR APPEARANCE  GO HERE
    example: border, background, color
**/

/** DESCRIPTIVE VARIABLES */

    $SkyddaGreen:             #006f62;
    $SkyddaOrange:            #e87722;
    $SkyddaFontColor:         #333333;
    $MiddleGreen:             #3FA637;
    $LightGreen:              #89B916;
    $Yellow:                  #E1B11D;
    $Orange:                  #DA5B15;
    $DarkOrange:              #E85D0F;
    $LightOrange:             #F5AD6D;
    $LightRed:                #E26861;
    $Pampas:                  #F5F2EF;
    $WhiteSmoke:              #F5F5F5;

    //Dark theme - Skydda
    $MediumGray:              #4D4F50;
    $LightBlack:              #292929;
    $MineShaftGray:           #3A3D3E;
    $TintedShuttleGray:       #5F6265;
    $TintedRavenGray:         #747779;
    //End of Dark theme - Skydda

    $NoticeYellow:            $Yellow;
    $ErrorRed:                $LightRed;
    $ConfirmGreen:            $LightGreen;
/** FUNCTIONAL VARIABLES */

    $color-primary:           $SkyddaGreen;
    $color-secondary:         $SkyddaOrange;

    $color-font:              $SkyddaFontColor;

    $color-separator-border:  darken($LightGrey, 6%);

    $color-footer:            $WhiteSmoke;
    $color-footer-secondary:  #ececec;

    $color-header-bar:        $WhiteSmoke;
    $color-header-nav:        #ececec;
    $color-header-icon:       #C1C1C1;

    $color-dark-bg:                     $LightBlack;
    $color-dark-header-nav:             $White;
    $color-dark-search-field:           $MediumGray;
    $color-dark-mobile-nav-bg:          $MineShaftGray;
    $color-dark-mobile-nav2:            $MediumGray;
    $color-dark-group-header-border:    $TintedShuttleGray;  
    $color-dark-mobile-nav2:            #55585a;  



    $color-link-hover:        $color-secondary;
    $color-underline-hover:   $color-primary;
    $color-link-icon:         $color-secondary;

    $color-title-bg:          $color-primary;

    $color-button-primary:    $color-primary;
    $color-button-primary-hover: lighten($color-button-primary, 2%);
    $color-button-primary-active: darken($color-button-primary, 4%);
    $color-button-primary-selected: $color-button-primary;

    $color-button-secondary:  $color-secondary;
    $color-button-secondary-hover: lighten($color-button-secondary, 4%);
    $color-button-secondary-active: darken($color-button-secondary, 4%);

    $color-icon-primary:      $color-primary;
    $color-icon-secondary:    $color-secondary;

    $color-container-bg:      $Pampas;

    $small-button-orange:     $color-secondary;

    $large-button-green:      $color-primary;

    $color-container-bg:      $WhiteSmoke;

/** 1.5 @Special content types */

/**
    VARIABLES RELATED TO SPECIAL CONTENT TYPES  GO HERE
    example: list-style, table-layout
**/

/** 1.6 @Text */

/**
    VARIABLES RELATED TO TEXT GO HERE
    example: text-align, text-shadow, font
**/

    $base__font-weight-regular: 300;
    $base__font-weight-bold: 600;

    $heading__font-family: "Heebo", Helvetica, Arial, sans-serif;
    $heading__font-color: #333;

    $base__font-family: "Heebo", Helvetica, Arial, sans-serif;
    $base__font-size: 16px;
    $base__font-weight: normal;
    $base__line-height: 1.5;
    $base__font-color: $color-font;

    $header-font: $heading__font-family;
    $standard-font: $base__font-family;




/** 1.7 @Visual properties */

/**
    VARIABLES RELATED TO VISUAL PROPERTIES GO HERE
    example: opacity, cursor
**/

/**  2. MODULE CONFIGURATION */

/** MODULE CONFIGURATION GO HERE */
