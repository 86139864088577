.config-loadoverlay{
	position: fixed;
	display:flex;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgba(0,0,0,0.25);
	z-index: 99999;
	justify-content: center;
	align-items: center;
}
