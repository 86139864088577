.tab {
    overflow: hidden;

    @include when(mobile) {
        overflow: visible;
    }

    &__title {
        @include btn-theme($ConcreteGrey);
        @include trans(0.1s ease-in-out);

        border-radius: 3px;
        font-size: 14px;
        font-weight: 900;

        @include when(mobile) {
            border-radius: 0;
        }
    }
}
