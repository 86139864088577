label {
    display: inline;
}

.regular-checkbox {
    display: none;
}

.regular-checkbox + label {
    background-color: #fafafa;
    border: 1px solid #cacece;
    /* box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05); */
    padding: 9px;
    border-radius: 3px;
    display: inline-block;
    position: relative;
}

.regular-checkbox + label:active, .regular-checkbox:checked + label:active {
    box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
}

.regular-checkbox:checked + label {
    border: 1px solid #adb8c0;
    box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
    color: #99a1a7;
}

.regular-checkbox:checked + label:after {
    content: '\2714';
    font-size: 17px;
    position: absolute;
    top: 1px;
    left: 2px;
    color: $color-icon-primary;
}


.big-checkbox + label {
    padding: 18px;
}

.big-checkbox:checked + label:after {
    font-size: 28px;
    left: 6px;
}

.tag {
    font-family: Arial, sans-serif;
    width: 200px;
    position: relative;
    top: 5px;
    font-weight: bold;
    text-transform: uppercase;
    display: block;
    float: left;
}

.radio-1 {
    width: 193px;
}

.button-holder {
    float: left;
}

/* RADIO */

.regular-radio {
    display: none;
}

.regular-radio + label {
    -webkit-appearance: none;
    background-color: #fafafa;
    border: 1px solid #cacece;
    box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
    padding: 9px;
    border-radius: 50px;
    display: inline-block;
    position: relative;
}

.regular-radio:checked + label:after {
    content: ' ';
    width: 12px;
    height: 12px;
    border-radius: 50px;
    position: absolute;
    top: 3px;
    background: #99a1a7;
    box-shadow: inset 0px 0px 10px rgba(0,0,0,0.3);
    text-shadow: 0px;
    left: 3px;
    font-size: 32px;
}

.regular-radio:checked + label {
    background-color: #e9ecee;
    color: #99a1a7;
    border: 1px solid #adb8c0;
    box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1), inset 0px 0px 10px rgba(0,0,0,0.1);
}

.regular-radio + label:active, .regular-radio:checked + label:active {
    box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
}

.big-radio + label {
    padding: 16px;
}

.big-radio:checked + label:after {
    width: 24px;
    height: 24px;
    left: 4px;
    top: 4px;
}
