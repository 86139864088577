.config-container{
	display:flex;
	justify-content: center;
    align-content: center;
	flex-direction: column;	
    flex-flow: column;
    flex-wrap: wrap;
    &_row{
	 	display:flex;
		justify-content: center;
	    align-content: center;
		flex-direction: row;	
	    flex-flow: row;
	    flex-wrap: wrap;
	    height:100%;
	    margin-bottom: 70px;

    }
    &_button-bottom{
    	height: 40px;
	 	display:flex;
		justify-content: center;
	    align-content: center;
		flex-direction: column;	
	    flex-flow: column;
	    flex-wrap: wrap; 
	    padding: 40px 0;
	    
	    & .bb-button{
	    	
	    }  	
    }
}
#AppLas{
	min-height:900px;

	@include when(mobile) {
		min-height:auto;
		padding-bottom:50px;
	}	

}