.order-list{
    
    &__header{
        overflow:hidden;
        width:100%;
        border-bottom: 1px solid black;
        padding: 10px 0;
        font-weight:bold;         
    }
    &__item{
        overflow:hidden;
        width:100%;
        border-bottom: 1px solid black;
        padding: 5px 0;        
    }
    &__brand{
        width:10%;
        float:left;
        min-height:25px;
        padding-right:1%;
              
    }
    &__name{
        width:24%;
        float:left;
        min-height:25px;
        padding-right:1%;
        &_inner{
            @extend .order-list__name;
            padding-top:9px;         
        }
              
    }
    &__sku{
        width:10%;
        float:left; 
        min-height:25px;
        padding-right:1%;
        &_inner{
            @extend .order-list__sku;
            padding-top:9px;         
        }    
    }
    &__quantity{
        width:8%;
        float:left; 
        min-height:25px;
        padding-right:1%;
    }
    &__total{
        width:10%;
        float:left;
        min-height:25px;
        padding-right:1%;
        &_inner{
            @extend .order-list__total;
            padding-top:9px;         
        } 
    }
    &__flags{
        width:7%;
        float:left;
        min-height:25px;
        padding-right:1%;
        &_inner{
            @extend .order-list__flags;
            padding-top:4px;         
        }    
    }
    &__content{
        width:8%;
        float:left;
        min-height:25px;
        padding-right:1%;
        &_inner{
            @extend .order-list__content;
            padding-top:9px;         
        }     
    }
    &__packaging{
        width:10%;
        float:left;
        min-height:25px;
        padding-right:1%;     
    }
    &__trash{
        width:3%;
        float:left;
        min-height:25px;
        &_inner{
            @extend .order-list__trash;
            padding-top:9px;         
        }     
    }
    &__refracted{
        width:10%;
        float:left;
        min-height:25px;
        padding-right:1%;
        &_inner{
            @extend .order-list__refracted;
            padding-top:9px;         
        }          
    }
    &__error{
        color: $ErrorRed;    
    }
}