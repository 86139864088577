.content{
    margin: 0 auto;
    max-width: 100%;
    position: relative;

    &__space-top {
        margin-top: 40px;
    }

    &__main {
        margin: 0 auto;
        max-width: 1280px;

        @media only screen and (max-width: 1320px) {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
    &__relative {
        position: relative;
        width: 100%;
    }
    &__hero-wrapper {
        position: relative;
        padding-bottom: 25px;
    }
}

.layout{
    /*color: #404040;
    font-family: "Duplicate Sans Web",Helvetica,Arial,sans-serif;
    font-feature-settings: "liga", "kern";
    font-size: 17px;
    font-weight: normal;
    line-height: 1;
    position: relative;
    transition: opacity 0.4s linear 0s;
    &__content{
        background: #fff;
        position: relative;
        width: 100%;
    }*/
}

//Reset layout content css inside header & footer
.header{
    & .content{
        margin: 0;
        max-width: none;
        &__main{
            max-width: none;
            padding: 0;
        }
    }
}
.footer{
    & .content{
        margin: 0;
        max-width: none;
        width: 100%;
        &__main{
            max-width: none;
            padding: 0;
        }
    }
}
