.cookie-bar {
    background: $color-container-bg;
    border-top: 1px solid $color-border-secondary;
    bottom: 0;
    left: 0;
    width: 100%;
    position: fixed;
    font-size: 14px;
    text-align: center;
    will-change: transform;
    padding: 10px;
    z-index: 14000;

    &__text {
    }

    &__read-more {
        font-weight: bold;
    }
}
