.storelocatorlist {
        height: 200px;
        overflow: auto;
        margin-bottom: 1em;
        display: none;
        
        &__list-header {
            display: flex;
            flex-flow: row wrap;
            font-weight: bold;  
            font-size: 18px;
            width: 100%;
      
            &__name {
                width: 40%;
            }
            &__address {
                width: 30%;
            }
            &__zipcode {
                width: 7%;
            }
            &__city {
                width: 13%;
            }
            &__phone {
                width: 10%;
            }                                                
        }
            
        &__list-body {
            display: flex;
            flex-flow: row wrap;
            width: 100%;           
            
            &__name {
                width: 40%;
            }
            &__address {
                width: 30%;
            }
            &__zipcode {
                width: 7%;
            }
            &__city {
                width: 13%;
            }
            &__phone {
                width: 10%;
            }
            &__empty {
                width: 100%;
            }            
        }
}