.config-keyview{
	display:flex;
	max-width:450px;
	width:100%;
	padding:10px;
	background: #f5f5f5;
	margin-bottom:50px;

	@include when(mobile) {
		flex-direction: column;
		flex-flow: column;
	}	

	&__image{
		display:flex;
		align-items: center;
		justify-content: center;
		width:50%;


		@include when(mobile) {
			width: 100%;
			padding-bottom:10px;
		}		

	}
	&__content{
		width:50%;

		@include when(mobile) {
			width: 100%;
		}		

	}
	&__content-top{
		width:100%;
		margin-bottom:15px;
		border-bottom:1px solid grey;

	}
	&__data{
		display:flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom:10px;

		& input{
			max-width: 75px;
			height: 40px;
		    margin-right: 5px;
		    text-align: center;
		}


	}
	&__content-bottom{
		width:100%;

	}
	&__button{
		& div{
			width: 100%;
		}
	}


}