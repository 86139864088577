.flex {
    &__container {
        display: flex;
        align-items: stretch;

        &_grid {
            @extend .flex__container;
            flex-wrap: wrap;

            @include breakpoint($RWD-A) {
                margin: 0 -5px;
            }
        }

        &_row {
            @extend .flex__container;
            flex-wrap: nowrap;
        }
    }
    &__item {
        &_product {
            position: relative;
            transition: border .2s ease;
            flex: 1 1 228px;
            max-width: 100%;
            margin-bottom: 30px;

            &.selected {
                outline: 3px solid #2b8e5a;
                box-sizing: border-box;
            }

            @include when(mobile) {
                flex: 1 1 165px;
            }

            @include breakpoint(max-width 450px) {
                flex: 1 1 auto;
                width: 50%;
            }
        }
        &_product-filler {
            flex: 1 1 228px;
            max-width: 100%;
            padding: 0;
            order: 99;

            @include when(mobile) {
                flex: 1 1 165px;
            }
        }
        &_tab {
            width: 20%;

            @include breakpoint($RWD-A) {
                width: 100%;
            }
            @include breakpoint($RWD-B) {
                width: 25%;
            }
        }

        &_espot {
            display: flex;
            flex: 1 1 228px;
            align-items: center;
            max-width: 100%;
            margin-bottom: 30px;
            position: relative;
            text-align: center;
            min-height: 211px;
            @include when(mobile) {
                flex: 1 1 165px;
            }
        }
        /*&_espot {
            flex-grow: 1;
            width: 40%;
            position: relative;

            div {
                width: 100%;
                height: 359px;
            }

            img {
                height: 362px;
                width: 100%;
                object-fit: cover;
            }

            @include breakpoint($RWD-A) {
                display: none;
            }

            @include breakpoint($RWD-B) {
                width: 25%;
            }
        }*/
    }

    &__table {
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        position: fixed;
        z-index: 23000;
        width: 100%;
    }

    &__table-content {
        margin: 0 auto;
        max-width: 1280px;
        max-height: 90vh;
        overflow-y: auto;
        background: $White;

        @media (max-width: 1300px) {
            margin: 0 10px;
        }

        &.has-shadow {
            box-shadow: 0px 8px 23px 0px rgba(0, 0, 0, 0.4);
        }
    }

    &__table-close {
        display: none;
    }
}

.col8 .flex__item_product{
    // max-width: 25%;
}
