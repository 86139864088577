@import "mixins";

/*** Grid system ***/

/* Row */
.row {
    /*margin-left: -$grid-gutter / 2;
    margin-right: -$grid-gutter / 2;*/
}
.row:after, .row:before {
    content: "";
    display: block;
}
.row:after {
    clear: both;
}

.row.m-initial {
    margin-left: auto;
    margin-right: auto;
    margin-left: initial;
    margin-right: initial;
}

/* Columns */

%column { 
    /*padding: 0 $grid-gutter / 2;*/
}  

@include setup-columns('acol');
@include setup-columns('col');
@include setup-columns('ccol');
 
/*
 * RWD-A - 0-600px
 * RWD-B - 601-1280px
 * RWD-C - 1281px+
 */

/* Grid system - RWD-A */
@include breakpoint($RWD-A) {
    @include make-columns('acol');
}

/* Grid system - RWD-B */
@include breakpoint($RWD-B) {
    @include make-columns('col');
}

/* Grid system - RWD-C */
@include breakpoint($RWD-C) {
    @include make-columns('ccol');
}

.nodisplay {
    display: none;
}