/*
.msg {

    &__wrapper {
        max-width: 1280px;
        margin: 0 auto;
        position: relative;
        padding: 10px;
    }
    &__close {
        position: absolute;
        top: 1px;
        right: 10px;
        font-size: 2em;
    }
}
.yellow {
    background-color: $NoticeYellow;
    color: $Black;
}
.red {
    background-color: $ErrorRed;
    color: $White;
}
.green {
    background-color: $ConfirmGreen;
    color: $White;
}*/

.msg {
    &__wrapper {
        align-items: stretch;
        background: white;
        display: flex;
        flex-flow: row nowrap;
        position: fixed;
        width: 100%;
        z-index: 13000;

        transform: translateY(-100%);
        transition: 0.25s;
    }
    .msg &__wrapper{
          transform: translateY(0);
    }
    &__box{
        padding: 16px;
        text-align: center;
        width: 64px;
        min-height: 64px;
    }
    .yellow &__box{
        background: $NoticeYellow;
    }
    .red &__box{
        background: $ErrorRed;
    }
    .green &__box{
        background: $ConfirmGreen;
    }
    &__icon{
        border: 2px solid white;
        border-radius: 50%;
        color: $White;
        display: table;
        font-size: 20px;
        text-align: center;
        width: 32px;
        height: 32px;
        &_notice{
            @extend .msg__icon;
            padding: 5px 4px 3px;
        }
        &_error{
            color: $White;
        }
        &_confirm{
            @extend .msg__icon;
            padding: 4px 4px 3px;
        }
    }
    &__spinner{
      display: none;
      float: left;
      margin-right: 12px;
      margin-top: 4px;
    }
    &__title{
        display: block;
        font-size: 18px;
        line-height: 21px;
        &_notice{
            @extend .msg__title;
            color: $NoticeYellow;
        }
        &_error{
            @extend .msg__title;
            color: $ErrorRed;
        }
        &_confirm{
            @extend .msg__title;
            color: $ConfirmGreen;
        }
    }
    .yellow &__error, .yellow &__confirm,
    .red &__notice, .red &__confirm,
    .green &__notice, .green &__error {
        display: none;
    }
    &__content{
        flex: 1 1;
        border-bottom: 1px solid $color-border-primary;
        padding: 12px 74px 12px 12px;
    }
    &__text{
        position: relative;
        display: block;
        margin: auto;
        max-width: 1280px;
    }
    &__close {
      position: absolute;
      top: 21px;
      right: 12px;
      font-size: 20px;
    }
}
