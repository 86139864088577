/** MIXINS */

@mixin btn-theme($btn-color,$border-color:"") {
    background-color: $btn-color;
    @if $border-color != "" {
        border: 1px solid $border-color;
    }
    &:hover {
        background-color: darken($btn-color, 6%);
        @if $border-color != "" {
            border: 1px solid darken($border-color, 6%);
        }
    }
    &:active {}
}

@mixin trans($val...) {
    -webkit-transition: $val;
    -moz-transition: $val;
    -o-transition: $val;
    transition: $val;
}

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background-clip: padding-box;
    /* stops bg color from leaking outside the border: */
}

// Single side border-radius
@mixin border-top-radius($radius) {
    -webkit-border-top-right-radius: $radius;
    border-top-right-radius: $radius;
    -webkit-border-top-left-radius: $radius;
    border-top-left-radius: $radius;
    background-clip: padding-box;
}
@mixin border-right-radius($radius) {
    -webkit-border-bottom-right-radius: $radius;
    border-bottom-right-radius: $radius;
    -webkit-border-top-right-radius: $radius;
    border-top-right-radius: $radius;
    background-clip: padding-box;
}
@mixin border-bottom-radius($radius) {
    -webkit-border-bottom-right-radius: $radius;
    border-bottom-right-radius: $radius;
    -webkit-border-bottom-left-radius: $radius;
    border-bottom-left-radius: $radius;
    background-clip: padding-box;
}
@mixin border-left-radius($radius) {
    -webkit-border-bottom-left-radius: $radius;
    border-bottom-left-radius: $radius;
    -webkit-border-top-left-radius: $radius;
    border-top-left-radius: $radius;
    background-clip: padding-box;
}

@mixin text($size, $line) {
    font-size: calc-em($size);
    line-height: ($line/$size);
}

@mixin placeholder-color($color: $color-font) {
    &::-webkit-input-placeholder {
        color: $color;
        @content;
    }
    &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: $color;
        opacity: 1;
        @content;
    }
    &::-moz-placeholder { /* Mozilla Firefox 19+ */
        color: $color;
        opacity: 1;
        @content;
    }
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $color;
        @content;
    }
}

@mixin break-word() {
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    // word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

/* ----- BREAKPOINT WRAPPER ----- */

$when-mixin-config: (
    mobile: $RWD-A,
    tablet: $RWD-B,
    desktop: $RWD-C
);

@mixin when($args...) {
    @each $label, $breakpoint in $when-mixin-config {
        @for $i from 1 through length($args) {
            @if ($label == nth($args, $i)) {
                @include breakpoint($breakpoint) {
                    @content;
                }
            }
        }
    }
}

/* ----- FLEX-GRID ----- */

.flex-grid {
    $flexGrid_size: 12;
    $flexGrid_colSpacing: 20px;

    display: flex;
    flex-wrap: wrap;
    margin-left: -#{$flexGrid_colSpacing / 2};
    margin-right: -#{$flexGrid_colSpacing / 2};

    &_col {
        margin-bottom: #{$flexGrid_colSpacing};
        padding: 0 #{$flexGrid_colSpacing / 2};
        flex: 0 0 auto;

        @for $i from 1 through 12 {
            &-#{$i} {
                width: #{(100 / $i) + '%'};
            }
        }

        @for $i from 1 through 12 {
            @include breakpoint($RWD-B) {
                &-md-#{$i} {
                    width: #{(100 / $i) + '%'};
                }
            }
        }

        @for $i from 1 through 12 {
            @include breakpoint($RWD-A) {
                &-sm-#{$i} {
                    width: #{(100 / $i) + '%'};
                }
            }
        }
    }
}
