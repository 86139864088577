.banner {
    position: relative;
    padding-top: 160px;

    @include when(mobile) {
        padding-top: 0;
    }

    &__content{
        background: rgba(238, 238, 238, 0.6);
        border-radius: 9px;
        margin-bottom: 20px;

        @include when(mobile) {
            background-color: transparent;
        }

        h1 {
            font-size: 32px;
        }

        p {
            font-weight: 300;
            font-size: 14px;
        }
    }

    &__image {
        position: absolute;
        top: 0;
        right: 0;
        height: 160px;
        width: 160px;
        padding: 0;

        @include when(mobile) {
            display: none;
        }

        img {
            max-height: 100%;
        }
    }

    &__body {
        font-weight: 300;
        font-size: 14px;
    }
}