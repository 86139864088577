.standard-table {
    font-size: 14px;

    &_ajaxed {
        @extend .standard-table;

        padding: 0 10px;
        box-shadow: $box-shadow-primary;
    }

    &__ellipse-content {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__main {
        position: relative;
        background: $White;
        font-size: 12px;
        display: table;
        border-collapse: separate;
        border-spacing: 0;
        border-color: gray;
        width: 100%;
        vertical-align: middle;
        //float: left;
        //table-layout: fixed;

        &_descr {
            @extend .standard-table__main;
        }

        &_descr ul:nth-child(2n) {
            background: #eee;
        }

        &_user-list {
            background: transparent;
        }

        &_document {
            border-collapse: collapse;
        }
    }

    &__icon {
        float: right;
        padding-right: 20px;
    }

    &__title {
        font-size: 18px;
        height: 38px;
        width: 100%;
        background: $MiddleGrey;
        padding: 10px 0 0 12px;
        color: $White;
    }

    &__link {
        font-size: 1em;
        text-transform: none;
        color: $color-primary;
        font-weight: 500;

        &:hover {
            text-decoration: underline;
            color: lighten($color-primary, 10%);
        }
    }

    &__textarea {
        display: block;
        margin: 0 !important;
        height: 38px;
        min-height: 38px;
    }

    &__gtin-row {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
    }

    &__gtin-cell {
        display: table-cell;
        vertical-align: inherit;
        padding: 0;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__row-selected {
        @include when(desktop) {
            outline: 2px solid $color-primary;
            outline-offset: -2px;
        }
    }

    &__row {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;

        @include when(tablet, desktop) {
            min-height: 33px;
        }

        @include when(mobile) {
            display: block;
            margin: 0;

            &_mobile{
                display: table-row;
            }
        }

        &_quickOrder-header {
            @extend .standard-table__row;

            padding: 6px 12px;
            font-size: 13px;
            font-weight: 900;
            text-transform: uppercase;

            @include when(mobile) {
                display: none;
            }
        }

        &_article-header {
            @extend .standard-table__row;

            height: 36px;
            vertical-align: middle;

            font-size: 12px;
            font-family: $header-font;
        }

        &_quickOrder {
            display: flex;
        }

        &_article {
            @extend .standard-table__row;

            padding: 3px 12px;
            text-transform: none;
            font-size: 12px;

            &:nth-child(even) {
                background: $LightGrey;
            }

            &:nth-child(odd) {
                background: $White;
            }
        }

        &_gtin {
            @extend .standard-table__row;

            padding: 3px 12px;
            text-transform: none;
            font-size: 12px;

            &_closed {
                display: none;
            }
        }

        &_order-header {
            @extend .standard-table__row;

            padding: 6px 0 6px 0;
            font-weight: bold;
            border-bottom: 1px solid $color-border-primary;

            @include when(mobile) {
                display: none;
            }
        }

        &_order {
            @extend .standard-table__row;

            padding: 6px 0 6px 0;
            border-bottom: 1px solid $color-border-primary;

            &:last-child {
                border-bottom: none;
            }
        }

        &_userarea {
            @extend .standard-table__row;

            padding: 3px 12px;
            color: black;
            text-transform: none;
            font-size: 12px;

            &:nth-child(even){
                background: $LightGrey;
            }

            &:nth-child(odd){
                background: $White;
            }

            @include when(mobile) {
                display: table-row;
                margin: 0;
            }
        }

        &_article-green {
            @extend .standard-table__row;

            padding: 5px 12px;
            outline: 2px solid $color-primary;
            margin: 2px 1px;
            color: black;

            @include when(mobile) {
                outline: 0;
                margin: 0;
                padding: 0;

                .standard-table__mobile-row_closed {
                    font-weight: bold;
                }
            }

            &:nth-child(even) {
                background: $LightGrey;
            }

            &:nth-child(odd) {
                background: $White;
            }
        }

        &_document {
            @extend .standard-table__row;
            border-bottom: 1px solid $LightGrey;

            &.active {
               font-weight: bold;

               & .standard-table__link{
                  font-weight: bold;
               }
            }
        }

        &_even {
            background: $LightGrey !important;
        }

        &_odd {
            background: $White !important;
        }
    }

    &__section {
        position: relative;
        width: 100%;
        align-items: center;

        @include when(mobile) {
            display: block;
            min-height: auto;
            padding: 5px 0;
        }

        &_mob {
            @extend .standard-table__section;

            display: none;
            font-weight: bold;

            @include breakpoint($RWD-A) {
                display: block;
            }

            .standard-table__item {
                &:last-child {
                    border-bottom: 0;
                    height: 36px;
                }
            }
        }
    }

    &__close {
        height: 33px;

        @include when(mobile) {
            height: auto;
        }

        &_mobile {
            @include when(mobile) {
                height: 39px;
            }
        }
    }

    &__item {
        display: table-cell;
        vertical-align: inherit;
        padding: 8px 7px;
        overflow: hidden;
        text-overflow: ellipsis;

        &_no-wrap {
            white-space: nowrap;
        }

        &_thin {
            padding: 0 7px;
            text-overflow: clip;  
        }

        &_flag {
            img {
                max-height: 24px;
                vertical-align: middle;
            }
        }

        &_user-list {
            padding: 6px 20px;
        }
    }

    &__gtin-item {
        padding: 0px 7px;
        line-height: 120%;

        a {
            line-height: 120%;
        }
    }

    &__align-right {
        text-align: right;
    }

    &__disabled-row {
        opacity: 0.5;
        pointer-events: none;
    }

    &__collapsed-row {
        display: none;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__collapsed-row-toggle-icon {
        color: $color-icon-secondary;
        position: absolute;
        right: 12px;
        top: 8px;
        transform: rotate(90deg);
        transition: transform .1s;
    }

    @mixin standard-table-collapsed() {
        .standard-table__item {
            padding: 6px 12px;
            display: block;
            width: 100%;

            &:before {
                content: attr(data-label);
                display: inline-block;
                font-weight: bold;
                margin-right: 10px;
                min-width: 130px;
            }

            &_user-list {
                padding: 6px 20px;
                &:before {
                    width: 50%;
                    margin-right: 0;
                }
            }

            @include breakpoint($RWD-A-small) {
                white-space: normal;

                &:before {
                    display: block;
                }
            }
        }

        .standard-table__row_user-list {
            display: block;
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0px;
            }
        }

        .standard-table__row_gtin,
        .standard-table__row_article {
            height: 36px;
            display: block;
            overflow: hidden;
            padding: 0;
            border-top: 1px solid rgba(0,0,0,0);
            border-bottom: 1px solid rgba(0,0,0,0);

            &.standard-table__expand-row {
                height: auto;
                border-top: 1px solid rgba(0,0,0,.1);
                border-bottom: 1px solid rgba(0,0,0,.1);
            }
        }

        .standard-table__row_article-header {
            display: none;
        }

        .standard-table__row-selected {
            outline: none;
        }

        .standard-table__collapsed-row {
            position: relative;
            padding: 9px 30px 9px 12px;
            display: block;
            min-height: 36px;

            &__item {
                display: inline;
                margin-right: 5px;

                &:before {
                    display: none;
                }
            }

            .standard-table__row-flag {
                img {
                    height: 20px;
                    vertical-align: middle;
                    margin-top: -5px;
                    margin-right: 5px;
                }
            }
        }
       
       .standard-table__text__ellipsis{
            white-space: nowrap; 
            overflow: hidden;
            text-overflow: ellipsis;
       }
       
       .standard-table__expanded {
            display: none!important;
       }
       
       .standard-table__expanded-item:not(.standard-table__expand-row) {
            display: none!important;
       }
       
       .standard-table__collapsed-item:not(.standard-table__expand-row) {
            display: inline!important;
       }

       .standard-table__expand-row {
        
            .standard-table__expanded-item {
                display: block!important;
            }
            
            .standard-table__collapsed-item {
                display: none!important;
            }
            
            .standard-table__collapsed-row {
                padding: 9px 12px 6px 12px;
            }

            .standard-table__collapsed-row__item {
                display: block;
                margin-top: 12px;
                margin-right: 0;

                &:first-child {
                    margin-top: 0;
                }

                &:before {
                    content: attr(data-label);
                    font-weight: bold;
                    margin-right: 10px;
                    display: inline-block;
                    min-width: 130px;
                }

                @include breakpoint($RWD-A-small) {
                    white-space: normal;

                    &:before {
                        display: block;
                    }
                }
            }

            .standard-table__collapsed-row-toggle-icon {
                transform: rotate(270deg);
            }

            .standard-table__hide-when-expanded {
                display: none;
            }

            @include when(tablet, mobile) {

                .standard-table__item_flag,.catentry__flag_img {
                    display: block;
                    //margin-right: 5px;
                }

            }
        }

        @include when(tablet, mobile) {

                .standard-table__item_flag, .catentry__flag_img {
                   display: none;
                }


            }
    }

    &__collapse-mobile {
        @include when(mobile) {
            @include standard-table-collapsed();
        }
    }

    &__collapse-tablet {
        @include when(tablet, mobile) {
            @include standard-table-collapsed();
        }
    }

    &__collapse-desktop {
        @include when(desktop, tablet, mobile) {
            @include standard-table-collapsed();
        }
    }

    &__quickOrder {
        display: flex;
        flex: 0 1 650px;
        background: $LightGrey;
        align-items: center;
        height: 33px;
        padding-right: 12px;
    }

    &__quickOrder-button {
        padding: 1px 0 0 12px;
        flex: 1 1 627px;
    }

    &__errorContainer {
        display: none;
        position: relative;
        margin: 0 auto;
        width: 275px;
    }

    &__error {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
        border-radius: 4px;
        background: $White;
        padding: 12px;
        position: fixed;
        top: 50%;
        z-index: 10;
    }

    &__requisitionContainer {
        position: relative;
        float: right;
        text-align: right;

        @include when(mobile) {
            display: none;
        }
    }

    &__actionList {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
        border-radius: 3px;
        background: $White;
        color: $Black;
        font-size: 12px;
        position: absolute;
        text-align: left;
        width: 225px;
        right: 0;
        z-index: 1210;
        overflow: hidden;

        span {
            cursor: pointer;

            &:hover {
                color:$color-button-secondary;
            }
        }

        @include when(mobile) {
            right: inherit;
        }
        &_userarea{
            @extend .standard-table__actionList;
            @include when(mobile) {
                right: 0;
            }
        }
    }

    &__actionListItem {
        border-bottom: 1px solid #ccc;
        &:last-child {
            border-bottom: none;
        }
        & a {
            display: block;
            padding: 7px 10px;
            &:hover {
                background: darken($White, 3%);
            }
        }
    }

    &__mobile-header-text {
        width: calc(100% - 22px);
        overflow: hidden;
        text-overflow: ellipsis;

        &_flag {
            @extend .standard-table__mobile-header-text;

            width: calc(100% - 85px);
        }
    }

    &__mobile-link {
        display: none;

        @include when(mobile) {
            display: block;
            padding: 6px 12px;
        }
    }

    &__mobile-flag {
        position: absolute;
        top: 4px;
        right: 32px;
        width: 106px;

        @include when(mobile) {
            width: auto;
        }
    }

    &__articleCode {
        color: $Red;
    }

    &__disabled {
        color: darken($color-border-primary, 12%);
    }

    &__detailed {
        padding-right: 10px;
    }

    &__close-icon {
      font-size: 21px;
      line-height: 14px;
      padding-left: 6px;
    }

     &__tooltip {
        cursor: default;
        position: relative;
    }

    &__tooltip &__tooltiptext {

        visibility: hidden;
        width: 71px;
        background-color: $White;
        color: $Black;
        text-align: center;
        border-radius: 4px;
        padding: 1px 0;
        font-size: 11px;

        position: absolute;
        z-index: 1000;
        bottom: 125%;
        left: 50%;
        margin-left: -35px;
        opacity: 0;
        transition: opacity 0.3s;
        box-shadow: 1px 2px 10px rgba(0,0,0,0.4);
    }
    
    &__tooltip &__tooltiptext::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: white transparent transparent transparent;
    }

    &__tooltip:hover &__tooltiptext {
        @include when(desktop) {
            visibility: visible;
            opacity: 1;
        }
    }
    
     &__tooltip &__tooltiptextalt {

        line-height: normal;
        visibility: hidden;
        width: 187px;
        background-color: $White;
        color: $Black;
        text-align: center;
        border-radius: 4px;
        padding: 1px 0;
        font-size: 11px;
        padding: 2px;
        position: absolute;
        z-index: 1000;
        bottom: 125%;
        left: 50%;
        margin-left: -95px;
        opacity: 0;
        transition: opacity 0.3s;
        box-shadow: 1px 2px 10px rgba(0,0,0,0.4);
    }   
    
    &__tooltip &__tooltiptextalt::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: white transparent transparent transparent;
    }

    &__tooltip:hover &__tooltiptextalt {
        @include when(desktop) {
            visibility: visible;
            opacity: 1;
        }
    }
    &__tooltip-cell.standard-table__item {
        overflow: visible;
    }
}

.ua-ie9,
.ua-ie10,
.ua-ie11 {
    .standard-table__row-selected {
        @include when(desktop) {
            outline: none;

            .standard-table__item {
                border-top: 2px solid $color-primary;
                border-bottom: 2px solid $color-primary;
            }

            .standard-table__item:first-child,
            .standard-table__collapsed-row + .standard-table__item {
                border-left: 2px solid $color-primary;
            }

            .standard-table__item:last-child {
                border-right: 2px solid $color-primary;
            }
        }
    }
}

.js-table-explodedview.active .js-tab-sparepart[data-type="hide"] {
    display: inline !important;
}
.js-tab-sparepart[data-type="hide"],
.js-table-explodedview.active .js-tab-sparepart[data-type="show"] {
    display: none;
}
