.store-locator {
        margin: auto;
        margin-top: 30px;
        margin-bottom: 30px;        
        text-align: center;
        position: relative;
        
        &__map-canvas {
            margin: auto;
            margin-bottom: 1em;
            height: 500px;
            overflow: hidden;   
        }
       
        &__map-input-container {
            position: absolute;
            z-index: 1;
            left: 35%;
            top: 2%;
        }
        
        &__map-input {
            @include border-left-radius(5px);
            border: 1px solid $header-button-black;
            border-right: none;
            width: 400px;
            height: 42px;
            padding: 0 10px;
            display: inline-block;
            font-size: 14px;
            margin-right: -5px;
            padding-top: 2px;
            @include breakpoint($RWD-A) {
                width: calc(100% - 42px);
            }
            @include breakpoint($RWD-B) {
                width: calc(100% - 42px);
            }
            &::-webkit-input-placeholder {
                /* WebKit, Blink, Edge */
                color: $header-button-black;
            }
            &:-moz-placeholder {
                /* Mozilla Firefox 4 to 18 */
                color: $header-button-black;
                opacity: 1;
            }
            &::-moz-placeholder {
                /* Mozilla Firefox 19+ */
                color: $header-button-black;
                opacity: 1;
            }
            &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: $header-button-black;
            }
        }
        
        &__map-submit {
            @include border-right-radius(5px);
            border: 1px solid $header-button-black;
            height: 42px;
            width: 42px;
            text-align: center;
            background: $White;
            font-size: 14px;
            display: inline-block;
            line-height: 42px;
            cursor: pointer;
            &:hover {
              background: $header-button-black;
              color: $White;
            }
            @include breakpoint($RWD-A) {
                position: absolute;
                top: 0;
                right: 0;
            }
        }
       
}

/* Styling for googles stuff */

/*Infowindow*/
.gm-style-iw {
       text-align: left;
}

.gm-style-iw a:hover {
       color: red;
}