.css-default{
    b, strong { font-weight: bold; }
    li { display: list-item; }
    ol {
        display: block;
        list-style-type: decimal;
        margin-top: 1em;
        margin-bottom: 1em;
        margin-left: 0;
        margin-right: 0;
        padding-left: 40px;
    }
    ul {
        display: block;
        list-style-type: disc;
        margin-top: 1em;
        margin-bottom: 1em;
        margin-left: 0;
        margin-right: 0;
        padding-left: 40px;
    }
    em{font-style:italic;}
    &_desc{
        @extend .css-default;
        b, zstrong { font-weight: $base__font-weight-bold; }
        p { font-weight: $base__font-weight-regular; }
    }
}
