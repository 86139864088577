.cmssalesperson {
        width: 235px;
        word-wrap: break-word;
        padding: 0 5px 0;
         
    div, a {
        float:left;
        clear: both;
    }
    
    .fa {
        color: $color-primary;
        width: 20px;
        text-align: left;
        left: 0;
        font-size: 17px;
        top: 4px;
    }
    
    a:hover {
        color: $color-primary;
    }
    
    &__thumbnail {
        position: relative;
        height: 200px;
        width: 210px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    
    &__name {
        //color: $MiddleGrey;
        word-wrap:break-word;
        padding-top: 14px;
        font-size: 16px;
        font-weight: bold;
        line-height: 1;
    }
    
    &__role {
        color: $MiddleGrey;
        font-size: 14px;
        font-weight: 600;
        padding: 0 0 7px 0;
    }
    
    &__phone, &__email, &__name, &__role {
        //color: $MiddleGrey;
        -ms-word-break: break-all;
        word-break: break-all;
        white-space: break-all; 
    }
        
    &__phone, &__email {
         font-size: 13px;
    }
    
}