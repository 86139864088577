.catentry {

    &__name {
        text-align: center;
        font-weight: 600;
    }

    &_product {
        border: 1px solid transparent;
        border-radius: 10px;
        background: $White;

        &:hover {
            border: 1px solid rgba(0, 0, 0, .25);
        }

        &_recommendation {
            @extend .catentry_product;
            margin: 0 5px;

            border: 1px solid rgba(0, 0, 0, .25);

            &:hover {
                border: 1px solid rgba(0, 0, 0, .45);
            }
        }
    }

    &__artiklar {
        background: $White;
        border: 1px solid $Orange;
        border-radius: 5px;
        color: $Orange;
        font-weight: 600;
        text-transform: uppercase;

        &__icon {
            @extend .fa-chevron-right;
        }
    }

    &__functions {
        @include border-dotts(6px 0 0 0);
    }

    &__paging {
        border-radius: 3px;
    }

    &__image-wrapper {
        text-align: center;
    }
    
    &__image {
        @include when(mobile) {
            max-width: 100%;
        }
    }
}