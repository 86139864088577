.nav-list{
    &__item{
        margin-bottom:40px;
        /*&_subMenu{
            @extend .nav-list__item;
            margin-bottom: 0px;
        }*/
        @include breakpoint($RWD-A) {
            border-bottom: 1px solid #e6e7e7;
            &:last-child {
                border-bottom: 0;
            }
        }
        @include breakpoint($RWD-B) {
            border-bottom: 1px solid #e6e7e7;
            &:last-child {
                border-bottom: 0;
            }
        }
    }
    .header__maNav &__item,
    .header__nav &__item{
        margin-bottom: 0px;
    }

    &__inner-item{
        margin-bottom: 2px;
        position: relative;
        /*&_subMenu{
            @extend .nav-list__inner-item;
              font-size: 16px;
              font-family: $header-font;
              background: $header-button-white;
              color: $DarkGrey;
              display: block;
              padding: 12px 15px;
              margin: 3px 0 0;
        }*/
    }
    .header__maNav &__inner-item,
    .header__nav &__inner-item{
        position: relative;
        font-size: 16px;
        font-family: $header-font;
        background: $header-button-white;
        color: $DarkGrey;
        display: block;
        padding: 12px 15px;
        margin: 3px 0 0;
        @include breakpoint($RWD-A) {
            margin: 0;
            font-size: 14px;
            border-bottom: 1px solid #b2b2b2;
            &:last-child {
                border-bottom: 0;
            }
         }
         @include breakpoint($RWD-B) {
            margin: 0;
            font-size: 14px;
            border-bottom: 1px solid #b2b2b2;
            &:last-child {
                border-bottom: 0;
            }
         }
    }
    &__inner-list{
        display: block;
        /*&_subMenu{
            @extend .nav-list__inner-list;
            display: none;
        }*/
    }
    .header__maNav &__inner-list,
    .header__nav &__inner-list{
        display: none;
    }
    &__title{
        font-family: $header-font;
        font-weight: 900;
        margin-bottom: 2px;
        font-size: 17px;
        text-transform: uppercase;
        
        /*&_subMenu{
            @extend .nav-list__title;
            font-size: 16px;
            font-family: $header-font;
            background: $header-button-grey;
            color: $DarkGrey;
            display: block;
            padding: 12px 15px;
            margin: 0 0 3px;
        }*/
    }
    .header__maNav &__title,
    .header__nav &__title{
        font-size: 16px;
        font-family: $header-font;
        background: $header-button-grey;
        color: $DarkGrey;
        display: block;
        padding: 12px 15px;
        margin: 0 0 3px;
        @include breakpoint($RWD-A) {
            margin: 0;
            font-size: 14px;
            text-transform: uppercase;
        }
        @include breakpoint($RWD-B) {
            margin: 0;
            font-size: 14px;
            text-transform: uppercase;
        }
    }
    &__mobile-title{
        @include breakpoint($RWD-A) {
            font-size: 16px;
            font-family: $header-font;
            background: $header-button-grey;
            color: $DarkGrey;
            display: block;
            padding: 12px 15px;
            margin-bottom: 3px;
        }
        @include breakpoint($RWD-B) {
            font-size: 16px;
            font-family: $header-font;
            background: $header-button-grey;
            color: $DarkGrey;
            display: block;
            padding: 12px 15px;
            margin-top: 3px;
        }
    }
    &__drop-link{
        display:block;
        @include breakpoint($RWD-A) {
            margin-top: -4px;
        }
    }
    &__text{
        display:inline-block;
        font-size: 17px;
        @include breakpoint($RWD-A) {
            font-size: 16px;
        }
    }
    &__text_active{
        display:inline-block;
        padding-bottom: 3px;
        font-weight: bold;
        border-bottom: 3px solid #008a57;
        font-size: 16px;
    }
    &__navItemText{
        vertical-align: -4px;
        & i {
            float: right;
            line-height: 1.6;
            font-size: 18px;
        }
    }
}
