/* ----- HEADINGS ----- */

.h1, .h2, .h3, .h4, .h5, .h6 {
    font-weight: 600;
}

.h1 {
    font-size: 32px;
    font-weight: 600;
    line-height: 1.3em;
    font-family: $heading__font-family;

    // @include breakpoint($RWD-A) {
    //     font-size: 22px;
    // }
}

.h2 {
    font-size: 22px;
    font-family: $heading__font-family;
}

.h3 {
    font-size: 18px;
}

p {
    font-weight: $base__font-weight-regular;
}

.ua-ie11 {
    img {
        -ms-interpolation-mode: bicubic; 
    }
}


input[type=text],
input[type=password],
input[type=number],
input[type=email],
input[type=search],
input[type=tel],
input[type=url],
textarea {
    &:disabled {
        background-color: darken($White, 8%);
        @include placeholder-color(lighten($color-font, 30%));
    }
}

.ua-ie11,
.ua-ie10 {
    select {
        padding-right: 0 !important;
    }
}

a.checkBoxer {
    &[aria-checked=true] {
        p::before {
            background-color: lighten($color-secondary, 25%);
        }
    }
}