.tab {
    overflow: hidden;

    @include when(mobile) {
        overflow: visible;
    }

    &__list {
        margin: 20px -10px 0 -10px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        position: relative;

        @include when(mobile) {
            margin-top: -10px;
        }
    }

    &__button {
        flex: 0 0 25%;
        max-width: 25%;
        display: block;
        padding: 10px;
        box-sizing: border-box;
        order: 1;

        @include when(tablet) {
            flex: 0 0 33.3333%;
            max-width: 33.3333%;
        }

        @include when(mobile) {
            flex: 0 0 100%;
            max-width: 100%;
            padding: 5px 10px;
            margin-top: 0px;
        }
    }

    &__content {
        flex: 0 1 100%;
        max-width: 100%;
        order: 2;
        display: none;
        padding: 10px 26px;
        background: $LightGrey;
        line-height: 25px;
        font-size: 14px;
        margin: 10px 0 20px 0;

        &::after {
            content: '';
            clear: both;
            display: block;
        }

        @include when(mobile) {
            order: 1;
            text-align: left;
            margin: -5px 0 20px 0;
            padding: 10px 15px;

            &_mobile-margin {
                margin: -5px 10px 5px 10px;
            }
        }

        &_table{
            padding: 0 10px;
            background: white;
        }

        &_tech-table{
            padding: 0 10px;
        }

        &.descr {
            padding: 0 0;
            background: white;
        }
    }

    &__button.active + &__content {
        display: block;
    }

    &__title {
        color: $White;
        display: block;
        padding: 8px 15px;
        text-transform: uppercase;
        font-family: $header-font;
        text-align: center;

        & .fa-spin {
            display: none;
            line-height: 25px;
        }

        & .fa-plus {
            display: inline-block;
            line-height: 25px;
        }

        & .fa-minus {
            display: none;
            line-height: 25px;
        }

        @include when(mobile) {
            border-radius: 0;
            text-align: left;
            font-size: 14px;
        }
    }

    &__button.active &__title {
        .fa-plus {
            display: none;
        }

        .fa-minus {
            display: inline-block;
        }
    }

    &__button.fetching-content &__title {
        .fa-plus {
            display: none;
        }

        .fa-minus {
            display: none;
        }

        .fa-spin {
            display: inline-block;
        }
    }

    &__container-video {
        margin: 0 -6px;
    }

    &__item-video {
        float: left;
        width: 33.33%;
        padding: 0 6px 12px 6px;
    }
    &__aspect-ratio {
        border: 6px solid white;
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 51%;
    }

    &__aspect-ratio iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0; top: 0;
    }

    &__container-document {
        margin: 0 -6px 0 0;
        display: flex;
        flex-wrap: wrap;
        align-content: stretch;
    }

    &__item-document {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        width: calc(25% - 6px);
        background: $White;
        border: 1px solid $LightGrey;
        border-radius: 2px;
        margin: 0 6px 2px 0;

        &:hover {
            background: darken($White, 3%);
        }

        @include when(mobile) {
            width: calc(100% - 6px);
        }

        @include breakpoint($RWD-B) {
            width: calc(33.33% - 6px);
        }
    }

    &__document-icon {
        color: $MiddleGrey;
        border-right: 1px solid $LightGrey;
        padding: 14px 12px 6px 12px;
        margin: 4px 0;

        @include when(mobile) {
            float:none;
            text-align: center;
        }
    }

    &__document-content {
        padding: 16px 12px;
        word-break: break-all;
        line-height: 14px;
    }

    &__assembly-instructions {
        &__items {
            position: relative;
            display: flex;
            flex-flow: row wrap;
        }

        &__item {
            float: left;
            width: 25%;
            padding: 10px;
            display: flex;
            flex-flow: column;
            align-items: center;

            @include when(mobile) {
                width: 100%;
            }

            &__image {
                position: relative;

                img {
                    position: relative;
                    display: block;
                    max-width: 100%;
                    margin: 0 auto;
                }
            }

            &__text {
                font-size: 16px;
                padding: 10px 0;
                max-width: 100%;
                text-align: center;
            }
        }
        &__header {
            margin-top: 10px;
            padding: 10px;
        }
        &__footer {
            font-size: 13px;
            font-style: italic;
            margin-top: 10px;
            padding: 10px;
        }
    }

    &__table {
        display: table;
        border-spacing: 15px;
        table-layout: fixed;
        width: 100%;

        @include when(mobile) {
            border-spacing: 0;
            margin-bottom: 10px;
        }

        & table {
            border: 1px solid $MiddleGrey;
            background: white;
            font-size: 12px;

            & tr {
                border-bottom: 1px solid $MiddleGrey;

                & td {
                    border-right: 1px solid $MiddleGrey;
                    padding: 0 10px 0 3px;
                    white-space: nowrap;
                }
            }
        }
    }

    &__table-cell {
        display: table-cell;
        border-spacing: 10px;
        border: 1px solid $MiddleGrey;

        &_left {
            width: 20%;
            background: $White;
        }
        &_right {
            background: $PropertiesZebra;
            padding: 5px;
        }
    }

    &__table-item {
        position: relative;
        border-bottom: 1px solid $MiddleGrey;

        &.selected {
            font-weight: bold;
        }
    }

    &__table-list {
        height: 310px;
        overflow: auto;
        @include when(mobile) {
            height: 250px;
        }
    }

    &__table-click {
        display: block;
        padding: 5px 25px 5px 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:hover {
            background: darken($White,3%);
        }
    }

    &__table-select {
        width: 100%;
        margin: 10px 0;
        border: 1px solid $MiddleGrey;
        color: $color-font;
        padding: 8px 6px;
        background-color: $White;
    }

    &__table-icon {
        position: absolute;
        right: 10px;
        top: 5px;
    }

    &__table-body {
        display: none;

        &.selected {
            display: block;
        }
    }

    &__table-content {
        height: 300px;
        overflow: auto;

        @include when(mobile) {
            height: 240px;
        }

        th, strong, b {
            font-weight: bold;
        }

        em, i {
            font-style: italic;
        }
    }
}
