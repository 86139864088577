.configpopup{
	background: #f5f5f5;
	max-width:300px;
	width:100%;
	padding:20px;
	position : fixed;
    top      : 50%;
    left     : 50%;
   	margin   : 0% 0 0 -8%;
    z-index  : 1002;
    overflow : hidden;
    border: 5px solid #d8d8d8;

	@include when(mobile) {
   		margin   : 0% 0 0 -40%; 
	}

	&__warning{
		margin-bottom: 20px;
	}

	&__buttons{
		display:flex;

		& .bb-button{
			max-width: 130px;
			margin:0 2px;
		}
	}

	&__backdrop{
	    position:fixed;
	    z-index  : 1001;
	    padding:0;
	    margin:0;

	    top:0;
	    left:0;

	    width: 100%;
	    height: 100%;
	    background:rgba(255,255,255,0.5);	
	}





}