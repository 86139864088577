.tab-table{
    &__main{
        position: relative;
        display: flex;
        background: $White;
        flex-flow: column nowrap;
        justify-content:space-between;
        border: 1px solid $color-border-primary;
        margin: -10px -16px;
    }
    &__row {
        display: flex;
        align-items: center;
        background-color: $MiddleGrey;

        &_article{
            @extend .catentry-table__row;

            padding: 3px 12px 3px 0;
            border-bottom: 1px solid $color-border-primary;
            background-color: $LightGrey;

            &:last-child{
                border-bottom: none;
            }

            @include when(mobile) {
                flex-wrap: wrap;
            }
        }
    }
    &__item{
        padding-left: 12px;
        flex: 1;
        align-self: flex-start;

        &_art-nr{
            @extend .catentry-table__item;
            flex: 0 1 95px;
        }

        > table {
            width: 100%;
        }

        @include when(mobile) {
            flex: 1 1 100%;
            margin: 10px 0;
        }
    }
}
