/**
 * Fiwe Framework settings variables
 */

// 1. Universal
$fw-class-prefix: 'bb';

// 2. Breakpoints
$fw-breakpoint-md: max-width 1149px;
$fw-breakpoint-sm: max-width 767px;
$fw-breakpoint-xs: max-width 480px;

// 3. Grid
$fw-grid-column-count: 12;
$fw-grid-gutter-lg: 15px;
$fw-grid-gutter-md: 15px;
$fw-grid-gutter-sm: 10px;
$fw-grid-gutter-xs: 7px;