.configproductlist{
    max-width: 930px;
    width:100%;
    display: flex;
	 flex-direction: row;
	 flex-flow: row;
	 flex-wrap: wrap;
	 margin-left:3px;
	 margin-bottom: 50px;
}
.configproductlist-Item{
	max-width: 225px;
    display: flex;
	flex-direction: column;
	background:#f5f5f5;
	border: 1px solid #e6e7e7;
	margin-right:5px;
	margin-left: -3px;
	margin-bottom:2px;

	@include when(mobile) {
		width:50%;
		margin-left: 0;
		margin-right:0;	  
	}


	&__image{
		padding:10px 0;
		height:155px;
		text-align: center;

		& img{
			max-height:130px;
		}
	}

	&__name{
		padding: 0 10px;
		height:42px;
		font-weight: bold;
		color: grey;

	}

	&__info{
		display: flex;
		justify-content: space-between;
		padding: 3px 10px;
	}

	&__picker{
		padding: 0 10px;
		height: 61px;

		& label{
			font-weight: bold;	
		}

		& select{
			width: 100%;
			height: 40px;
		}
		& p{
			margin-top: 9px;
			margin-left: 3px;	
		}
	}

	&__inputs{
		display: flex;
		justify-content: space-between;	
		padding: 10px 10px;

		& input{
			width: 35%;
			height: 40px;
			margin-right: 5px;
			text-align: center;
		}

		& .bb-button{

				@include when(mobile) {
		  			min-width: auto !important;
				}
		}
	}
}
.configproductlist__title{
		margin-bottom:50px;
		text-align: center;;

}