.user-list {
    text-align: left;

    @include when(mobile) {
        width: 100%;
    }

    &__group {
        display: flex;

        @include when(mobile) {
            width: 100%;
            flex-flow: column;
        }
    }

    &__label {
        font-family: $header-font;
        font-size: 11px;
        font-weight: bold;
        line-height: 26px;
        text-transform: uppercase;
        text-align: left;

        @include when(mobile, tablet) {
            width: 50%;
        }

        &_small {
            @extend .user-list__label;
            width: 40%;
        }

        &_total {
            @extend .user-list__label;
            font-size: 100%;
        }
    }


    &__value {
        flex: 1 1 auto;
        min-height: 30px;
        line-height: 26px;
        font-size: 13px;
        padding-left: 24px;

        @include when(mobile, tablet) {
            padding: 0;
        }

        &_small {
            @extend .user-list__value;
            width: 60%;

            &_details {
                @extend .user-list__value_small;
                width: 100%;
                padding-left: 0px;
            }
        }

        &_total {
            @extend .user-list__value;
            font-size: 200%;
        }
        
        &_campaign-price {
            @extend .user-list__value;
            font-size: 130%;
            color: red;
            font-weight: bold;
        }
    }

    &__singel {
        width: 100%;
        height: 70px;
        float: left;
        border-right: 1px solid $color-border-primary;
        border-left: 1px solid $color-border-primary;
        border-bottom: 1px solid $color-border-primary;
        padding: 25px 0 0 10px;
    }

    &__buttons {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        justify-content: flex-end;
        margin-top: 10px;
    }

    &__description {
        height: 70px;
        width: 100%;
        float: left;
        padding: 45px 0 0 10px;
        border-bottom: 1px solid #ebebeb;
        font-weight: bold;
    }

    &__item {
        line-height: 18px;

        &:last-child {
            margin-bottom: 7px;
        }
        &_delivery {
            @extend .user-list__item;

			font-size: 14px;
			white-space: nowrap;

            @include when(mobile) {
                font-size: initial;
            }
            @include when(tablet) {
                font-size: initial;
            }
        }    
    }

    &__flex-box {
        margin-top: 24px;
    }

    &__group-row {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
    }

    &__group-item {
        flex: 1;

        &:first-child {
            padding-right: 12px;
        }
    }

    &__address {
        margin-top: 24px;
    }
    
    &__checkbox li {
        margin-bottom: 6px;
        &:last-child {
            margin-bottom: 0;
        }
        & label::after {
            content: attr(data-description);
        }
    }
    
    &__role li {
        font-size: 13px;
        &:nth-child(even){
            margin-bottom: 6px;
        }
    }
    
    &__role b {
        font-size: 11px;
        font-weight: bold;
        line-height: 26px;
        text-transform: uppercase;
    }

    &__flex-group {
        display: flex;
        align-items: center;

        &_address {
            @extend .user-list__flex-group;
            margin-bottom: 24px;
        }
    }

    &__flex-item {
        flex: 1;

        &:first-child {
            padding-right: 12px;
        }

        &_address {
            @extend .user-list__flex-item;
            align-self: center;
            margin-bottom: 5px;
        }
    }
}