.config-summary{
	max-width:465px;
	width:100%;
	
	&__title{
		color:grey;
		border-bottom:1px solid grey;
		width:100%;
		margin-bottom: 50px;
		margin-left: 20px;
		@include when(mobile) {
			margin-left: 0;
		}			
	}
	&__content{
		max-width: 225px;
		width:100%;
		float:right;
		margin-left: 20px;

		@include when(mobile) {
			max-width: unset;
			float: unset;
			margin-left: 0;
			padding-left:20px;
		}		

		& div{
			margin-bottom: 15px;

			& span:first-child{
				margin-right:10px;
			}

			& span:last-child{
				font-size: 18px;
				font-weight: bold;	
			}
		}
	}
	&__mini-basket{
		padding: 8px;
	    position: fixed;
	    align-items: center;
	    display: flex;
	    right: 103px;
	    height: 38px;
	    bottom: 34px;
	    background: #006c94;
    	margin-bottom: 0;
    	color: #fff;
    	border: 2px solid #fff;
    	z-index: 1;
		
		@include when(mobile) {
			right: 30%;
			bottom: 11px;
		}	    	

	    & span{
	    	margin-right: 3px
	    }

    	& div{
    		position: relative;
		    width: 40px;
		    display:flex;
		    justify-content: center;
		    & span{
		    	transition: all .2s;
		    	font-size: 15px;
		    	font-weight: 900;
		    	color: #fff;		    	
		   	}

    	}

	    &_active{
			padding: 8px;
		    position: fixed;
		    display: flex;
		    align-items: center;
		   	height: 38px;
		    right: 103px;
		    bottom: 34px;
		    background: #006c94;
		    color: #fff;
	    	margin-bottom: 0;
	    	border: 2px solid #fff;
	    	z-index: 1;
	    	
			@include when(mobile) {
				right: 30%;
				bottom: 11px;
			}	

	    	& div{
	    		position: relative;
    		    min-width: 40px;
    		    

			    & span{
			    	transition: all .2s;
			    	position: absolute;
			    	-webkit-text-stroke: 2px #006c94;
			    	color:#c5c5c5;
			    	font-size: 35px;
			    	font-weight: 900;
					top: -31px;
					right: -5px;

			   	}

	    	}

	    }	    
	}
}
