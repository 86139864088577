.standard-table {

    &__table-fixed {
        table-layout: fixed;
    }
    
    &__row_article-header{
        font-size: 12px;
        font-weight: $base__font-weight-bold;

        @include when(mobile, tablet) {
            display: none !important;
        }
        &.sticky{
            position: fixed;
            width:100%;
            max-width: 1280px;
            background:white;
            z-index: 1;
        } 
        &.sticky-overlay{
            position: fixed;
            width:100%;
            max-width: 1265px;
            background:white;
            z-index: 1;
        }               
    }

    &__row_article{
        font-size: 12px;
        // font-weight: $base__font-weight-regular;
        min-height: 33px;
        
        @include when(tablet, desktop) {
            height: auto;
        }
    }

    &__item {

        &_small {
            @extend .standard-table__item;
            // flex: 0 1 35px;
            width: 35px;
        }
        &_shrink {
            @extend .standard-table__item;
            // flex: 1 1 120px;
        }

        @include when(mobile) {
            // padding: 2px 10px;
        }
    }
}