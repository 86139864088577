.order-total{
    width:40%;
    background:$color-container-bg;
    padding: 20px 10px 10px 10px;
    &__list{

    }
    &__list-item{
        margin-bottom:5px;
    }

    &__noorder-info {
    	margin-top: 18px; 
    	font-size: 12px; 
    	text-align: right;
    }
    &__noorder-info-text{
    	margin-top: 20px; 
    	display: inline-block; 
    	cursor: pointer;

    }
    &__noorder-info-icon{
    	position: relative; 
    	cursor: pointer; 
    	display: inline-block; 
    	background-color: $White; 
    	width: 20px; 
    	text-align: center; 
    	border-radius: 5px; 
    	padding: 3px 8px; 
    	font-size: 12px; 
    	line-height: 1; 
    	border: 1px solid #999999; 
    	margin-left: 4px;
    }
    &__noorder-info-tooltip{
    	width: 270px; 
    	background-color: $White; 
    	color: ¤black; 
    	text-align: left; 
    	border-radius: 4px; 
    	padding: 10px 10px;  
    	font-size: 13px; 
    	position: absolute; 
    	z-index: 1000; 
    	bottom: 125%; 
    	left: 50%; 
    	margin-left: -244px; 
    	display: none; 
    	transition: opacity 0.3s; 
    	box-shadow: 1px 2px 10px rgba(0,0,0,0.4);
    }
    &__noorder-info-tooltip::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 90%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: white transparent transparent transparent;
    }
}
