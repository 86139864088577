.config-complementry{
	max-width:465px;
	width:100%;

	&__title{
		color:grey;
		border-bottom:1px solid grey;
		width:100%;
		margin-bottom: 25px;
		margin-right: 20px;
		@include when(mobile) {
			margin-right: 0;
		}			
	}
	&__content{
		width:100%;
		margin-right: 20px;

		@include when(mobile) {
			float: unset;
			margin-right: 0;
			padding:0 20px;
		}			
		
		& input{
			width:100%;
			margin-bottom:10px;
			height: 40px;
		}

	}
	&__required{
		text-align: right;
		
	}
}