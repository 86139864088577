.banner {
    margin: 0px auto;
    padding: 40px 0 20px 0;

    @include when(mobile) {
        padding: 20px 0 0 0;
        height: auto;
    }

    &__content{
        width: 50%;
        padding: 10px 20px 20px;
        float: right;

        @include when(mobile) {
            float: none;
            width: 100%;
        }

        @include when(tablet) {
            width: 75%;
        }

        &_brand {
            text-align: right;

            h1 {
                display: inline-block;
                vertical-align: top;
                line-height: 0.65cm;
            }
        }
    }

    &__body {
        font-size: 15px;
    }

    &__contentImage {
        float: left;
        width: 50%;
        padding: 10px 20px 20px 0;

        img {
            max-width: 250px;
        }
    }

    &__image {
        width: 160px;
        min-height: 145px;
        margin-left: auto;
        padding-bottom: 40px;
        text-align: right;

        @include when(mobile) {
            width: 75%;
            margin: auto;
            text-align: center;
        }

        img {
            @include when(mobile) {
                max-height: 175px;
            }
        }

        @include when(mobile) {
            width: 75%;
        }

        &_brand {
            display: inline-block;
            width: 160px;
            margin-left: 40px;

            &:after {
                content: " ";
            }
        }
    }
}
