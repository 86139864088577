.configlist{
	display: block;
    min-width: 270px;
    display: flex;
	 flex-direction: column;
	 flex-flow: column;
	opacity: 1;
    box-shadow: 3px 6px 8px -4px #00000082;
    cursor: pointer;

    	@include when(mobile) {
		    z-index: 1;	
	}	

	 &_hidden-list{
	 	display:none;
	 	margin: 0 3px;
		opacity: 0;
    	transition: all .2s ease-in-out;
	 }
}

.configlist-item{
	width: 100%;
	min-height: 125px;
	min-width: 270px;
	background:#f5f5f5;
	border: 4px solid #e6e7e7;

	&:hover {
	  background-color: yellow;
	}

	&_top{
	    width: 100%;
	    min-height: 125px;
	    min-width: 270px;
	    display: flex;
	    justify-content: center;
	    flex-direction: column;
	    text-align: center;
		background: #fff;	    
    	border: 4px solid #e6e7e7;
    	cursor: pointer;

		& span{
			font-size: 18px;
			font-weight: bold;
		}	

		&__img-box-top{
			text-align: center;
			padding-top: 7px;
	    	min-height: 115px;

	    	& img{
	    		height:100%;
       			max-width: 200px;
    			max-height: 100px;		
	    	}
		}


	}

	&_top-nobord{
	    width: 100%;
	    min-height: 125px;
	    min-width: 270px;
		background: #fff;	    
    	border: 0;		
	}

	&_active{
		width: 100%;
		min-height: 115px;
		border: 4px solid #1488b3;
		background: #fff;

		&:hover {
	 		background-color: #e6e7e7;
		}		
	}

	&_disabled{
		width: 100%;
		min-height: 115px;
    	border-top: 4px solid #e6e7e7;
    	border-left: 4px solid #e6e7e7; 
    	border-right: 4px solid #e6e7e7;
    	border-bottom: 0px;  
		background: #fff;

		&:hover {
	 		background-color: #e6e7e7;
		}	
		&:first-child{
			border-top: 0px;
		}  	  
		&:last-child{
			border-bottom: 4px solid #e6e7e7;
		}  		 		
	}

	&__img-box{
		text-align: center;
		padding: 10px 0 0 0;
    	height: 110px;

		& img{
    		height:100%;
       		max-width: 200px;
    		max-height: 100px; 
    	}
	}


	&__info-box{
		display:flex;
		justify-content: space-between;
		height:25px;
		color: grey;
		padding:0 5px;

	}
	&__long-info{
		display:none;

	}
		
}
.configlist-item-chosen{
	text-align: center;

	@include when(mobile) {
		margin-bottom: 15px;
	}	

}
.configlist-container{
	position:relative;
	margin: 0 4px;

	&_changed{
		position:relative;
		margin: 0 4px;

		& .configlist{
			 position:absolute;
			 top: 125px;
		}
	}
}
.configlist-tooltip{
	position: relative;

	@include when(mobile) {
		display:none;
	}	
    @include when(tablet) {
		display:none;
    }

	& i{
		color: #1488b3;
	}
	
	&:hover .configlist-tooltip__container{
		visibility: visible;
	}


	&__container{
		position: absolute;
		display:flex;
		min-width: 220px;
	    top: -4px;
	    left: 13px;
	    z-index: 1;
	    visibility: hidden;


	}
	&__arrow{
	  width: 0; 
	  height: 0;
	  margin-top:5px; 
	  border-top: 10px solid transparent;
	  border-bottom: 10px solid transparent;   
	  border-right:15px solid #1488b3; 

	}
	&__text-box{
		background: #fff;
		padding:5px;
		padding: 8px;
		border-left: 4px solid #1488b3;
		border-right: 2px solid #e6e7e7;
		border-top: 2px solid #e6e7e7;
		border-bottom: 2px solid #e6e7e7;
		box-shadow: 3px 6px 8px -4px #00000082;
		border-radius: 5px;

	}		
}