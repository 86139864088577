.ceckout-box{
    width:75%;
    margin:0 auto;
    @include breakpoint($RWD-A) {
        width:85%;
    }       
      
    &__done{
        border:4px solid #009b68;
        text-align:center;
        padding:50px 20px;
        width:100%;
        max-width: 630px;
        margin: 0 auto 50px;
        //margin-bottom:50px;    
    }
    
}