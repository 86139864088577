.espot {
    background-color: #008f5a;
    border-radius: 5px;
    min-height: 170px;
    padding: 20px;

    &__image {
        position: relative;
    }

    &__image img{
        width: 100%;
        height: auto;
    }

    &__background {
        position: absolute;
        background-repeat: no-repeat;
        background-size: cover;
        top: 0;
        left: calc(50% - 50vw);
        width: 100vw;
        z-index: -1;

        &_absolute {
            @extend .espot__background;
            background-position: top center;
            height: 600px;

            @include when(mobile) {
                display: none;
            }
        }

        &_relative {
            @extend .espot__background;
            background-position: top 20% center;
            height: 100%;
        }
    }

    &__slider{
        padding: 18px;
        background-color: rgba(0, 0, 0, 0.6);
    }

    &__slider-wrapper{
        position: relative;
        border-top: 6px solid rgba(255, 255, 255, 0.4);
        border-bottom: 6px solid rgba(255, 255, 255, 0.4);
    }

    &__slider-img{
        border: solid 1px rgba(255, 255, 255, 0.4);
    }    
    
    &__list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    
        &_main {
            line-height: 1.4;
            font-size: 13px;
        }
    
        &__title {
            font-size: 15px;
            text-transform: uppercase;
            font-family: $header-font;
            font-weight: 900;
            margin-bottom: 5px;
    
            .fa {
                display: none;
                float: right;
                line-height: 19px;
            }
        }
    }
}

.footer__content_main .espot__list__title { 
    @include when(mobile) {
        margin-bottom: 0px;
    
        .fa-angle-down {
            display: block;
        }
    
        .fa-angle-up {
            display: none;
        }
    
        + ul {
            display: none;
        }
    
        &.show {
            margin-bottom: 5px;
    
            .fa-angle-down {
                display: none;
            }
    
            .fa-angle-up {
                display: block;
            }
    
            + ul {
                display: block;
            }
        }
    }
}

.footer__content_main .has-content.espot__list-box { 
    @include when(mobile) {
        padding: 10px;
        width: 100%;
        border-bottom: 1px solid $White;

        &:first-child {
            border-top: 1px solid $White;
        }
    }
}

